import React, { Suspense } from "react"
import ReactDOM from "react-dom"

import { I18nextProvider } from "react-i18next"
import i18n from "./i18n"

import { ApolloProvider } from "@apollo/client"
import clientConfig from "./apollo/clientConfig"

import "./styles/index.scss"
import "./styles/helpers/margins.scss"

import App from "./app"
import NcrAuthProvider from "./providers/NcrAuthProvider"

import * as serviceWorker from "./serviceWorker"

ReactDOM.render(
    <React.Fragment>
        <NcrAuthProvider>
            <I18nextProvider i18n={i18n}>
                <ApolloProvider client={clientConfig}>
                    <Suspense fallback='loading...'>
                        <App />
                    </Suspense>
                </ApolloProvider>
            </I18nextProvider>
        </NcrAuthProvider>
    </React.Fragment>,
    document.getElementById("root"),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
