import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-xhr-backend"
import config from "./config"

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en", //default language
        whitelist: ["pl", "en"], //allowed languages
        keySeparator: ":",
        interpolation: {
            escapeValue: false,
        },

        ns: ["buttons", "common", "ncr"], //file names, can be splitted by domains of translation
        backend: {
            loadPath: `${config.publicUrl}/locales/{{lng}}/{{ns}}.json`,
            //add: `${config.publicUrl}/locales/add/{{lng}}/{{ns}}`,
        },

        //    detection: {
        //      //detects browser language and saves it in localStorage if matches whitelist
        //      order: ["localStorage"],
        //      lookupLocalStorage: "lng",
        //      checkWhitelist: true,
        //    },
        saveMissing: true,
        saveMissingTo: "all",
    })

export default i18n
