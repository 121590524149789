import { gql } from "@apollo/client"

export default gql`
  mutation UpdateDefectById(
    $id: uuid!
    $batchNo: String = ""
    $manufacturingNo: String = ""
    $defectDescription: String = ""
    $model: String = ""
    $otherInfo: String = ""
    $productName: String = ""
    $qtyDelivered: Int! = 1
    $qtyRejected: Int! = 1
  ) {
    update_ncr_Defects_by_pk(
      pk_columns: { Id: $id }
      _set: {
        BatchNumber: $batchNo
        DefectDescription: $defectDescription
        ManufacturingOrderNo: $manufacturingNo
        OtherInfo: $otherInfo
        ProductName: $productName
        ProductModel: $model
        QuantityDelivered: $qtyDelivered
        QuantityRejected: $qtyRejected
      }
    ) {
      Id
      BatchNumber
      DefectDescription
      ManufacturingOrderNo
      QuantityDelivered
      QuantityRejected
      ProductName
      ProductModel
      OtherInfo
    }
  }
`
