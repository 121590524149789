import { useTranslation } from "react-i18next"
import { useReactOidc } from "@axa-fr/react-oidc-context"
import { Form, Button, Message } from "semantic-ui-react"

import { ContentWrapper } from "components/containers/contentWrapper/contentWrapper"
//import NcrFileUpload from "components/controls/ncrFileUpload";
import useUpdateDefectForm from "./updateHook"
import { GraphQLError } from "graphql"
import { useParams } from "react-router"

const UpdateNcrForm = (): JSX.Element => {
    const { id } = useParams()
    if (!id) return <></>

    const { t } = useTranslation(["buttons", "common", "ncr"])
    const { oidcUser } = useReactOidc()
    const { formData, isSubmitted, backendErrors, validationErrors, handleChange, handleSubmit } =
    useUpdateDefectForm(id)

    return (
        <ContentWrapper header='Reklamacja'>
            <div className='flex flex-row'>
                <Form>
                    <Form.Input
                        label='Klient'
                        disabled
                        value='test'
                        input={<span>{oidcUser?.profile.company || "Not provided"}</span>}
                    />
                    <img alt='Client Logo' width={128} height={128} />
                </Form>
                <div>
                    <div className='mb-8'>Prosimy o wypełnienie informacji na temat reklamacji</div>
                    <Form
                        error={isSubmitted && validationErrors && Object.keys(validationErrors).length > 0}
                        onSubmit={handleSubmit}
                        widths={"equal"}
                        className='grid sm:grid-cols-1 md:grid-cols-2 w-full'
                        noValidate>
                        <Form.Input
                            className='w-full sm:w-1/2'
                            label={t("Nazwa wyrobu")}
                            name='ProductName'
                            value={formData.productName}
                            onChange={handleChange}
                            error={validationErrors && validationErrors.productName}
                            required
                        />
                        <Form.Input
                            className='w-full sm:w-1/2'
                            label={t("Model wyrobu")}
                            name='ProductModel'
                            value={formData.model}
                            onChange={handleChange}
                            error={validationErrors && validationErrors.model}
                        />
                        <Form.Input
                            className='w-full sm:w-1/2'
                            label={t("Nr zlecenia produkcyjnego")}
                            name='ManufacturingOrderNo'
                            value={formData.manufacturingNo}
                            error={validationErrors && validationErrors.manufacturingNo}
                            onChange={handleChange}
                        />
                        <Form.Input
                            className='w-full sm:w-1/2'
                            label={t("Nr serii produkcyjnej")}
                            name='BatchNumber'
                            value={formData.batchNo}
                            error={validationErrors && validationErrors.batchNo}
                            onChange={handleChange}
                        />
                        <Form.Input
                            className='w-full sm:w-1/2'
                            label={t("Ilość w dostawie")}
                            name='QuantityDelivered'
                            value={formData.qtyDelivered}
                            error={validationErrors && validationErrors.qtyDelivered}
                            onChange={handleChange}
                            required
                        />
                        <Form.Input
                            className='w-full sm:w-1/2'
                            label={t("Ilość wadliwa")}
                            type={"number"}
                            name='QuantityRejected'
                            value={formData.qtyRejected}
                            error={validationErrors && validationErrors.qtyRejected}
                            onChange={handleChange}
                            required
                        />
                        {/*
                                TODO: add defect type
                                <Form.Input name='defectType' label={t("Rodzaj wady")} onChange={handleChange} value={formData.DefectType} />
                            */}
                        <Form.TextArea
                            className='w-full'
                            name='DefectDescription'
                            label='Opis wady (300 znaków)'
                            onChange={handleChange}
                            value={formData.defectDescription || ""}></Form.TextArea>
                        <Form.TextArea
                            className='w-full'
                            name='OtherInfo'
                            value={formData.otherInfo || ""}
                            label='Inne informacje / Oczekiwania (300 znaków)'
                            onChange={handleChange}></Form.TextArea>
                        {/* <NcrFileUpload files={formData.attachments} onChange={handleAttachmentsChange} /> */}
                        {validationErrors &&
              Object.keys(validationErrors).map((key, index) => (
                  <Message key={index} error>
                      {validationErrors[key]}
                  </Message>
              ))}

                        {backendErrors &&
              backendErrors.map((error: GraphQLError, i: number) => (
                  <Message key={i} error>
                      {error.message}
                  </Message>
              ))}
                        <Button type='submit' className='ui success'>
                            {t("save")}
                        </Button>
                    </Form>
                </div>
            </div>
        </ContentWrapper>
    )
}

export default UpdateNcrForm
