import { gql } from "@apollo/client"
import * as Apollo from "@apollo/client"
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  money: any;
  timestamptz: any;
  timetz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: Maybe<Scalars["Float"]>;
  _gt?: Maybe<Scalars["Float"]>;
  _gte?: Maybe<Scalars["Float"]>;
  _in?: Maybe<Array<Scalars["Float"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Float"]>;
  _lte?: Maybe<Scalars["Float"]>;
  _neq?: Maybe<Scalars["Float"]>;
  _nin?: Maybe<Array<Scalars["Float"]>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars["String"]>;
};

/** Boolean expression to compare columns of type "money". All fields are combined with logical 'AND'. */
export type Money_Comparison_Exp = {
  _eq?: Maybe<Scalars["money"]>;
  _gt?: Maybe<Scalars["money"]>;
  _gte?: Maybe<Scalars["money"]>;
  _in?: Maybe<Array<Scalars["money"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["money"]>;
  _lte?: Maybe<Scalars["money"]>;
  _neq?: Maybe<Scalars["money"]>;
  _nin?: Maybe<Array<Scalars["money"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "ncr.ActionStatuses" */
  delete_ncr_ActionStatuses?: Maybe<Ncr_ActionStatuses_Mutation_Response>;
  /** delete single row from the table: "ncr.ActionStatuses" */
  delete_ncr_ActionStatuses_by_pk?: Maybe<Ncr_ActionStatuses>;
  /** delete data from the table: "ncr.Addresses" */
  delete_ncr_Addresses?: Maybe<Ncr_Addresses_Mutation_Response>;
  /** delete single row from the table: "ncr.Addresses" */
  delete_ncr_Addresses_by_pk?: Maybe<Ncr_Addresses>;
  /** delete data from the table: "ncr.Attachment_CorrectiveAction" */
  delete_ncr_Attachment_CorrectiveAction?: Maybe<Ncr_Attachment_CorrectiveAction_Mutation_Response>;
  /** delete single row from the table: "ncr.Attachment_CorrectiveAction" */
  delete_ncr_Attachment_CorrectiveAction_by_pk?: Maybe<Ncr_Attachment_CorrectiveAction>;
  /** delete data from the table: "ncr.Attachment_Defect" */
  delete_ncr_Attachment_Defect?: Maybe<Ncr_Attachment_Defect_Mutation_Response>;
  /** delete single row from the table: "ncr.Attachment_Defect" */
  delete_ncr_Attachment_Defect_by_pk?: Maybe<Ncr_Attachment_Defect>;
  /** delete data from the table: "ncr.Attachment_Product" */
  delete_ncr_Attachment_Product?: Maybe<Ncr_Attachment_Product_Mutation_Response>;
  /** delete single row from the table: "ncr.Attachment_Product" */
  delete_ncr_Attachment_Product_by_pk?: Maybe<Ncr_Attachment_Product>;
  /** delete data from the table: "ncr.Attachments" */
  delete_ncr_Attachments?: Maybe<Ncr_Attachments_Mutation_Response>;
  /** delete single row from the table: "ncr.Attachments" */
  delete_ncr_Attachments_by_pk?: Maybe<Ncr_Attachments>;
  /** delete data from the table: "ncr.CompanyAffected" */
  delete_ncr_CompanyAffected?: Maybe<Ncr_CompanyAffected_Mutation_Response>;
  /** delete single row from the table: "ncr.CompanyAffected" */
  delete_ncr_CompanyAffected_by_pk?: Maybe<Ncr_CompanyAffected>;
  /** delete data from the table: "ncr.CorrectiveActions" */
  delete_ncr_CorrectiveActions?: Maybe<Ncr_CorrectiveActions_Mutation_Response>;
  /** delete single row from the table: "ncr.CorrectiveActions" */
  delete_ncr_CorrectiveActions_by_pk?: Maybe<Ncr_CorrectiveActions>;
  /** delete data from the table: "ncr.CustomerToInform" */
  delete_ncr_CustomerToInform?: Maybe<Ncr_CustomerToInform_Mutation_Response>;
  /** delete single row from the table: "ncr.CustomerToInform" */
  delete_ncr_CustomerToInform_by_pk?: Maybe<Ncr_CustomerToInform>;
  /** delete data from the table: "ncr.Defect_TeamMember" */
  delete_ncr_Defect_TeamMember?: Maybe<Ncr_Defect_TeamMember_Mutation_Response>;
  /** delete single row from the table: "ncr.Defect_TeamMember" */
  delete_ncr_Defect_TeamMember_by_pk?: Maybe<Ncr_Defect_TeamMember>;
  /** delete data from the table: "ncr.Defects" */
  delete_ncr_Defects?: Maybe<Ncr_Defects_Mutation_Response>;
  /** delete single row from the table: "ncr.Defects" */
  delete_ncr_Defects_by_pk?: Maybe<Ncr_Defects>;
  /** delete data from the table: "ncr.Feature_Defect" */
  delete_ncr_Feature_Defect?: Maybe<Ncr_Feature_Defect_Mutation_Response>;
  /** delete data from the table: "ncr.Features" */
  delete_ncr_Features?: Maybe<Ncr_Features_Mutation_Response>;
  /** delete single row from the table: "ncr.Features" */
  delete_ncr_Features_by_pk?: Maybe<Ncr_Features>;
  /** delete data from the table: "ncr.PartAffected" */
  delete_ncr_PartAffected?: Maybe<Ncr_PartAffected_Mutation_Response>;
  /** delete single row from the table: "ncr.PartAffected" */
  delete_ncr_PartAffected_by_pk?: Maybe<Ncr_PartAffected>;
  /** delete data from the table: "ncr.RootCauses" */
  delete_ncr_RootCauses?: Maybe<Ncr_RootCauses_Mutation_Response>;
  /** delete single row from the table: "ncr.RootCauses" */
  delete_ncr_RootCauses_by_pk?: Maybe<Ncr_RootCauses>;
  /** delete data from the table: "ncr.Statuses" */
  delete_ncr_Statuses?: Maybe<Ncr_Statuses_Mutation_Response>;
  /** delete single row from the table: "ncr.Statuses" */
  delete_ncr_Statuses_by_pk?: Maybe<Ncr_Statuses>;
  /** delete data from the table: "ncr.ThirdParties" */
  delete_ncr_ThirdParties?: Maybe<Ncr_ThirdParties_Mutation_Response>;
  /** delete single row from the table: "ncr.ThirdParties" */
  delete_ncr_ThirdParties_by_pk?: Maybe<Ncr_ThirdParties>;
  /** delete data from the table: "ncr.Units" */
  delete_ncr_Units?: Maybe<Ncr_Units_Mutation_Response>;
  /** delete single row from the table: "ncr.Units" */
  delete_ncr_Units_by_pk?: Maybe<Ncr_Units>;
  /** delete data from the table: "ncr.Users" */
  delete_ncr_Users?: Maybe<Ncr_Users_Mutation_Response>;
  /** delete single row from the table: "ncr.Users" */
  delete_ncr_Users_by_pk?: Maybe<Ncr_Users>;
  /** insert data into the table: "ncr.ActionStatuses" */
  insert_ncr_ActionStatuses?: Maybe<Ncr_ActionStatuses_Mutation_Response>;
  /** insert a single row into the table: "ncr.ActionStatuses" */
  insert_ncr_ActionStatuses_one?: Maybe<Ncr_ActionStatuses>;
  /** insert data into the table: "ncr.Addresses" */
  insert_ncr_Addresses?: Maybe<Ncr_Addresses_Mutation_Response>;
  /** insert a single row into the table: "ncr.Addresses" */
  insert_ncr_Addresses_one?: Maybe<Ncr_Addresses>;
  /** insert data into the table: "ncr.Attachment_CorrectiveAction" */
  insert_ncr_Attachment_CorrectiveAction?: Maybe<Ncr_Attachment_CorrectiveAction_Mutation_Response>;
  /** insert a single row into the table: "ncr.Attachment_CorrectiveAction" */
  insert_ncr_Attachment_CorrectiveAction_one?: Maybe<Ncr_Attachment_CorrectiveAction>;
  /** insert data into the table: "ncr.Attachment_Defect" */
  insert_ncr_Attachment_Defect?: Maybe<Ncr_Attachment_Defect_Mutation_Response>;
  /** insert a single row into the table: "ncr.Attachment_Defect" */
  insert_ncr_Attachment_Defect_one?: Maybe<Ncr_Attachment_Defect>;
  /** insert data into the table: "ncr.Attachment_Product" */
  insert_ncr_Attachment_Product?: Maybe<Ncr_Attachment_Product_Mutation_Response>;
  /** insert a single row into the table: "ncr.Attachment_Product" */
  insert_ncr_Attachment_Product_one?: Maybe<Ncr_Attachment_Product>;
  /** insert data into the table: "ncr.Attachments" */
  insert_ncr_Attachments?: Maybe<Ncr_Attachments_Mutation_Response>;
  /** insert a single row into the table: "ncr.Attachments" */
  insert_ncr_Attachments_one?: Maybe<Ncr_Attachments>;
  /** insert data into the table: "ncr.CompanyAffected" */
  insert_ncr_CompanyAffected?: Maybe<Ncr_CompanyAffected_Mutation_Response>;
  /** insert a single row into the table: "ncr.CompanyAffected" */
  insert_ncr_CompanyAffected_one?: Maybe<Ncr_CompanyAffected>;
  /** insert data into the table: "ncr.CorrectiveActions" */
  insert_ncr_CorrectiveActions?: Maybe<Ncr_CorrectiveActions_Mutation_Response>;
  /** insert a single row into the table: "ncr.CorrectiveActions" */
  insert_ncr_CorrectiveActions_one?: Maybe<Ncr_CorrectiveActions>;
  /** insert data into the table: "ncr.CustomerToInform" */
  insert_ncr_CustomerToInform?: Maybe<Ncr_CustomerToInform_Mutation_Response>;
  /** insert a single row into the table: "ncr.CustomerToInform" */
  insert_ncr_CustomerToInform_one?: Maybe<Ncr_CustomerToInform>;
  /** insert data into the table: "ncr.Defect_TeamMember" */
  insert_ncr_Defect_TeamMember?: Maybe<Ncr_Defect_TeamMember_Mutation_Response>;
  /** insert a single row into the table: "ncr.Defect_TeamMember" */
  insert_ncr_Defect_TeamMember_one?: Maybe<Ncr_Defect_TeamMember>;
  /** insert data into the table: "ncr.Defects" */
  insert_ncr_Defects?: Maybe<Ncr_Defects_Mutation_Response>;
  /** insert a single row into the table: "ncr.Defects" */
  insert_ncr_Defects_one?: Maybe<Ncr_Defects>;
  /** insert data into the table: "ncr.Feature_Defect" */
  insert_ncr_Feature_Defect?: Maybe<Ncr_Feature_Defect_Mutation_Response>;
  /** insert a single row into the table: "ncr.Feature_Defect" */
  insert_ncr_Feature_Defect_one?: Maybe<Ncr_Feature_Defect>;
  /** insert data into the table: "ncr.Features" */
  insert_ncr_Features?: Maybe<Ncr_Features_Mutation_Response>;
  /** insert a single row into the table: "ncr.Features" */
  insert_ncr_Features_one?: Maybe<Ncr_Features>;
  /** insert data into the table: "ncr.PartAffected" */
  insert_ncr_PartAffected?: Maybe<Ncr_PartAffected_Mutation_Response>;
  /** insert a single row into the table: "ncr.PartAffected" */
  insert_ncr_PartAffected_one?: Maybe<Ncr_PartAffected>;
  /** insert data into the table: "ncr.RootCauses" */
  insert_ncr_RootCauses?: Maybe<Ncr_RootCauses_Mutation_Response>;
  /** insert a single row into the table: "ncr.RootCauses" */
  insert_ncr_RootCauses_one?: Maybe<Ncr_RootCauses>;
  /** insert data into the table: "ncr.Statuses" */
  insert_ncr_Statuses?: Maybe<Ncr_Statuses_Mutation_Response>;
  /** insert a single row into the table: "ncr.Statuses" */
  insert_ncr_Statuses_one?: Maybe<Ncr_Statuses>;
  /** insert data into the table: "ncr.ThirdParties" */
  insert_ncr_ThirdParties?: Maybe<Ncr_ThirdParties_Mutation_Response>;
  /** insert a single row into the table: "ncr.ThirdParties" */
  insert_ncr_ThirdParties_one?: Maybe<Ncr_ThirdParties>;
  /** insert data into the table: "ncr.Units" */
  insert_ncr_Units?: Maybe<Ncr_Units_Mutation_Response>;
  /** insert a single row into the table: "ncr.Units" */
  insert_ncr_Units_one?: Maybe<Ncr_Units>;
  /** insert data into the table: "ncr.Users" */
  insert_ncr_Users?: Maybe<Ncr_Users_Mutation_Response>;
  /** insert a single row into the table: "ncr.Users" */
  insert_ncr_Users_one?: Maybe<Ncr_Users>;
  /** update data of the table: "ncr.ActionStatuses" */
  update_ncr_ActionStatuses?: Maybe<Ncr_ActionStatuses_Mutation_Response>;
  /** update single row of the table: "ncr.ActionStatuses" */
  update_ncr_ActionStatuses_by_pk?: Maybe<Ncr_ActionStatuses>;
  /** update data of the table: "ncr.Addresses" */
  update_ncr_Addresses?: Maybe<Ncr_Addresses_Mutation_Response>;
  /** update single row of the table: "ncr.Addresses" */
  update_ncr_Addresses_by_pk?: Maybe<Ncr_Addresses>;
  /** update data of the table: "ncr.Attachment_CorrectiveAction" */
  update_ncr_Attachment_CorrectiveAction?: Maybe<Ncr_Attachment_CorrectiveAction_Mutation_Response>;
  /** update single row of the table: "ncr.Attachment_CorrectiveAction" */
  update_ncr_Attachment_CorrectiveAction_by_pk?: Maybe<Ncr_Attachment_CorrectiveAction>;
  /** update data of the table: "ncr.Attachment_Defect" */
  update_ncr_Attachment_Defect?: Maybe<Ncr_Attachment_Defect_Mutation_Response>;
  /** update single row of the table: "ncr.Attachment_Defect" */
  update_ncr_Attachment_Defect_by_pk?: Maybe<Ncr_Attachment_Defect>;
  /** update data of the table: "ncr.Attachment_Product" */
  update_ncr_Attachment_Product?: Maybe<Ncr_Attachment_Product_Mutation_Response>;
  /** update single row of the table: "ncr.Attachment_Product" */
  update_ncr_Attachment_Product_by_pk?: Maybe<Ncr_Attachment_Product>;
  /** update data of the table: "ncr.Attachments" */
  update_ncr_Attachments?: Maybe<Ncr_Attachments_Mutation_Response>;
  /** update single row of the table: "ncr.Attachments" */
  update_ncr_Attachments_by_pk?: Maybe<Ncr_Attachments>;
  /** update data of the table: "ncr.CompanyAffected" */
  update_ncr_CompanyAffected?: Maybe<Ncr_CompanyAffected_Mutation_Response>;
  /** update single row of the table: "ncr.CompanyAffected" */
  update_ncr_CompanyAffected_by_pk?: Maybe<Ncr_CompanyAffected>;
  /** update data of the table: "ncr.CorrectiveActions" */
  update_ncr_CorrectiveActions?: Maybe<Ncr_CorrectiveActions_Mutation_Response>;
  /** update single row of the table: "ncr.CorrectiveActions" */
  update_ncr_CorrectiveActions_by_pk?: Maybe<Ncr_CorrectiveActions>;
  /** update data of the table: "ncr.CustomerToInform" */
  update_ncr_CustomerToInform?: Maybe<Ncr_CustomerToInform_Mutation_Response>;
  /** update single row of the table: "ncr.CustomerToInform" */
  update_ncr_CustomerToInform_by_pk?: Maybe<Ncr_CustomerToInform>;
  /** update data of the table: "ncr.Defect_TeamMember" */
  update_ncr_Defect_TeamMember?: Maybe<Ncr_Defect_TeamMember_Mutation_Response>;
  /** update single row of the table: "ncr.Defect_TeamMember" */
  update_ncr_Defect_TeamMember_by_pk?: Maybe<Ncr_Defect_TeamMember>;
  /** update data of the table: "ncr.Defects" */
  update_ncr_Defects?: Maybe<Ncr_Defects_Mutation_Response>;
  /** update single row of the table: "ncr.Defects" */
  update_ncr_Defects_by_pk?: Maybe<Ncr_Defects>;
  /** update data of the table: "ncr.Feature_Defect" */
  update_ncr_Feature_Defect?: Maybe<Ncr_Feature_Defect_Mutation_Response>;
  /** update data of the table: "ncr.Features" */
  update_ncr_Features?: Maybe<Ncr_Features_Mutation_Response>;
  /** update single row of the table: "ncr.Features" */
  update_ncr_Features_by_pk?: Maybe<Ncr_Features>;
  /** update data of the table: "ncr.PartAffected" */
  update_ncr_PartAffected?: Maybe<Ncr_PartAffected_Mutation_Response>;
  /** update single row of the table: "ncr.PartAffected" */
  update_ncr_PartAffected_by_pk?: Maybe<Ncr_PartAffected>;
  /** update data of the table: "ncr.RootCauses" */
  update_ncr_RootCauses?: Maybe<Ncr_RootCauses_Mutation_Response>;
  /** update single row of the table: "ncr.RootCauses" */
  update_ncr_RootCauses_by_pk?: Maybe<Ncr_RootCauses>;
  /** update data of the table: "ncr.Statuses" */
  update_ncr_Statuses?: Maybe<Ncr_Statuses_Mutation_Response>;
  /** update single row of the table: "ncr.Statuses" */
  update_ncr_Statuses_by_pk?: Maybe<Ncr_Statuses>;
  /** update data of the table: "ncr.ThirdParties" */
  update_ncr_ThirdParties?: Maybe<Ncr_ThirdParties_Mutation_Response>;
  /** update single row of the table: "ncr.ThirdParties" */
  update_ncr_ThirdParties_by_pk?: Maybe<Ncr_ThirdParties>;
  /** update data of the table: "ncr.Units" */
  update_ncr_Units?: Maybe<Ncr_Units_Mutation_Response>;
  /** update single row of the table: "ncr.Units" */
  update_ncr_Units_by_pk?: Maybe<Ncr_Units>;
  /** update data of the table: "ncr.Users" */
  update_ncr_Users?: Maybe<Ncr_Users_Mutation_Response>;
  /** update single row of the table: "ncr.Users" */
  update_ncr_Users_by_pk?: Maybe<Ncr_Users>;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_ActionStatusesArgs = {
  where: Ncr_ActionStatuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_ActionStatuses_By_PkArgs = {
  Id: Scalars["Int"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_AddressesArgs = {
  where: Ncr_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Addresses_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Attachment_CorrectiveActionArgs = {
  where: Ncr_Attachment_CorrectiveAction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Attachment_CorrectiveAction_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  CorrectiveActionId: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Attachment_DefectArgs = {
  where: Ncr_Attachment_Defect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Attachment_Defect_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Attachment_ProductArgs = {
  where: Ncr_Attachment_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Attachment_Product_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_AttachmentsArgs = {
  where: Ncr_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Attachments_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_CompanyAffectedArgs = {
  where: Ncr_CompanyAffected_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_CompanyAffected_By_PkArgs = {
  CompanyId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_CorrectiveActionsArgs = {
  where: Ncr_CorrectiveActions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_CorrectiveActions_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_CustomerToInformArgs = {
  where: Ncr_CustomerToInform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_CustomerToInform_By_PkArgs = {
  CustomerId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Defect_TeamMemberArgs = {
  where: Ncr_Defect_TeamMember_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Defect_TeamMember_By_PkArgs = {
  DefectId: Scalars["uuid"];
  UserId: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_DefectsArgs = {
  where: Ncr_Defects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Defects_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Feature_DefectArgs = {
  where: Ncr_Feature_Defect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_FeaturesArgs = {
  where: Ncr_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Features_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_PartAffectedArgs = {
  where: Ncr_PartAffected_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_PartAffected_By_PkArgs = {
  DefectId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_RootCausesArgs = {
  where: Ncr_RootCauses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_RootCauses_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_StatusesArgs = {
  where: Ncr_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Statuses_By_PkArgs = {
  Status: Scalars["String"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_ThirdPartiesArgs = {
  where: Ncr_ThirdParties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_ThirdParties_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_UnitsArgs = {
  where: Ncr_Units_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Units_By_PkArgs = {
  Id: Scalars["Int"];
};


/** mutation root */
export type Mutation_RootDelete_Ncr_UsersArgs = {
  where: Ncr_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Ncr_Users_By_PkArgs = {
  Id: Scalars["uuid"];
};


/** mutation root */
export type Mutation_RootInsert_Ncr_ActionStatusesArgs = {
  objects: Array<Ncr_ActionStatuses_Insert_Input>;
  on_conflict?: Maybe<Ncr_ActionStatuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_ActionStatuses_OneArgs = {
  object: Ncr_ActionStatuses_Insert_Input;
  on_conflict?: Maybe<Ncr_ActionStatuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_AddressesArgs = {
  objects: Array<Ncr_Addresses_Insert_Input>;
  on_conflict?: Maybe<Ncr_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Addresses_OneArgs = {
  object: Ncr_Addresses_Insert_Input;
  on_conflict?: Maybe<Ncr_Addresses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Attachment_CorrectiveActionArgs = {
  objects: Array<Ncr_Attachment_CorrectiveAction_Insert_Input>;
  on_conflict?: Maybe<Ncr_Attachment_CorrectiveAction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Attachment_CorrectiveAction_OneArgs = {
  object: Ncr_Attachment_CorrectiveAction_Insert_Input;
  on_conflict?: Maybe<Ncr_Attachment_CorrectiveAction_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Attachment_DefectArgs = {
  objects: Array<Ncr_Attachment_Defect_Insert_Input>;
  on_conflict?: Maybe<Ncr_Attachment_Defect_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Attachment_Defect_OneArgs = {
  object: Ncr_Attachment_Defect_Insert_Input;
  on_conflict?: Maybe<Ncr_Attachment_Defect_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Attachment_ProductArgs = {
  objects: Array<Ncr_Attachment_Product_Insert_Input>;
  on_conflict?: Maybe<Ncr_Attachment_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Attachment_Product_OneArgs = {
  object: Ncr_Attachment_Product_Insert_Input;
  on_conflict?: Maybe<Ncr_Attachment_Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_AttachmentsArgs = {
  objects: Array<Ncr_Attachments_Insert_Input>;
  on_conflict?: Maybe<Ncr_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Attachments_OneArgs = {
  object: Ncr_Attachments_Insert_Input;
  on_conflict?: Maybe<Ncr_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_CompanyAffectedArgs = {
  objects: Array<Ncr_CompanyAffected_Insert_Input>;
  on_conflict?: Maybe<Ncr_CompanyAffected_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_CompanyAffected_OneArgs = {
  object: Ncr_CompanyAffected_Insert_Input;
  on_conflict?: Maybe<Ncr_CompanyAffected_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_CorrectiveActionsArgs = {
  objects: Array<Ncr_CorrectiveActions_Insert_Input>;
  on_conflict?: Maybe<Ncr_CorrectiveActions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_CorrectiveActions_OneArgs = {
  object: Ncr_CorrectiveActions_Insert_Input;
  on_conflict?: Maybe<Ncr_CorrectiveActions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_CustomerToInformArgs = {
  objects: Array<Ncr_CustomerToInform_Insert_Input>;
  on_conflict?: Maybe<Ncr_CustomerToInform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_CustomerToInform_OneArgs = {
  object: Ncr_CustomerToInform_Insert_Input;
  on_conflict?: Maybe<Ncr_CustomerToInform_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Defect_TeamMemberArgs = {
  objects: Array<Ncr_Defect_TeamMember_Insert_Input>;
  on_conflict?: Maybe<Ncr_Defect_TeamMember_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Defect_TeamMember_OneArgs = {
  object: Ncr_Defect_TeamMember_Insert_Input;
  on_conflict?: Maybe<Ncr_Defect_TeamMember_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_DefectsArgs = {
  objects: Array<Ncr_Defects_Insert_Input>;
  on_conflict?: Maybe<Ncr_Defects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Defects_OneArgs = {
  object: Ncr_Defects_Insert_Input;
  on_conflict?: Maybe<Ncr_Defects_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Feature_DefectArgs = {
  objects: Array<Ncr_Feature_Defect_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Feature_Defect_OneArgs = {
  object: Ncr_Feature_Defect_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_FeaturesArgs = {
  objects: Array<Ncr_Features_Insert_Input>;
  on_conflict?: Maybe<Ncr_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Features_OneArgs = {
  object: Ncr_Features_Insert_Input;
  on_conflict?: Maybe<Ncr_Features_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_PartAffectedArgs = {
  objects: Array<Ncr_PartAffected_Insert_Input>;
  on_conflict?: Maybe<Ncr_PartAffected_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_PartAffected_OneArgs = {
  object: Ncr_PartAffected_Insert_Input;
  on_conflict?: Maybe<Ncr_PartAffected_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_RootCausesArgs = {
  objects: Array<Ncr_RootCauses_Insert_Input>;
  on_conflict?: Maybe<Ncr_RootCauses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_RootCauses_OneArgs = {
  object: Ncr_RootCauses_Insert_Input;
  on_conflict?: Maybe<Ncr_RootCauses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_StatusesArgs = {
  objects: Array<Ncr_Statuses_Insert_Input>;
  on_conflict?: Maybe<Ncr_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Statuses_OneArgs = {
  object: Ncr_Statuses_Insert_Input;
  on_conflict?: Maybe<Ncr_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_ThirdPartiesArgs = {
  objects: Array<Ncr_ThirdParties_Insert_Input>;
  on_conflict?: Maybe<Ncr_ThirdParties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_ThirdParties_OneArgs = {
  object: Ncr_ThirdParties_Insert_Input;
  on_conflict?: Maybe<Ncr_ThirdParties_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_UnitsArgs = {
  objects: Array<Ncr_Units_Insert_Input>;
  on_conflict?: Maybe<Ncr_Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Units_OneArgs = {
  object: Ncr_Units_Insert_Input;
  on_conflict?: Maybe<Ncr_Units_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_UsersArgs = {
  objects: Array<Ncr_Users_Insert_Input>;
  on_conflict?: Maybe<Ncr_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Ncr_Users_OneArgs = {
  object: Ncr_Users_Insert_Input;
  on_conflict?: Maybe<Ncr_Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_ActionStatusesArgs = {
  _inc?: Maybe<Ncr_ActionStatuses_Inc_Input>;
  _set?: Maybe<Ncr_ActionStatuses_Set_Input>;
  where: Ncr_ActionStatuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_ActionStatuses_By_PkArgs = {
  _inc?: Maybe<Ncr_ActionStatuses_Inc_Input>;
  _set?: Maybe<Ncr_ActionStatuses_Set_Input>;
  pk_columns: Ncr_ActionStatuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_AddressesArgs = {
  _set?: Maybe<Ncr_Addresses_Set_Input>;
  where: Ncr_Addresses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Addresses_By_PkArgs = {
  _set?: Maybe<Ncr_Addresses_Set_Input>;
  pk_columns: Ncr_Addresses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Attachment_CorrectiveActionArgs = {
  _set?: Maybe<Ncr_Attachment_CorrectiveAction_Set_Input>;
  where: Ncr_Attachment_CorrectiveAction_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Attachment_CorrectiveAction_By_PkArgs = {
  _set?: Maybe<Ncr_Attachment_CorrectiveAction_Set_Input>;
  pk_columns: Ncr_Attachment_CorrectiveAction_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Attachment_DefectArgs = {
  _set?: Maybe<Ncr_Attachment_Defect_Set_Input>;
  where: Ncr_Attachment_Defect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Attachment_Defect_By_PkArgs = {
  _set?: Maybe<Ncr_Attachment_Defect_Set_Input>;
  pk_columns: Ncr_Attachment_Defect_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Attachment_ProductArgs = {
  _set?: Maybe<Ncr_Attachment_Product_Set_Input>;
  where: Ncr_Attachment_Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Attachment_Product_By_PkArgs = {
  _set?: Maybe<Ncr_Attachment_Product_Set_Input>;
  pk_columns: Ncr_Attachment_Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_AttachmentsArgs = {
  _set?: Maybe<Ncr_Attachments_Set_Input>;
  where: Ncr_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Attachments_By_PkArgs = {
  _set?: Maybe<Ncr_Attachments_Set_Input>;
  pk_columns: Ncr_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_CompanyAffectedArgs = {
  _set?: Maybe<Ncr_CompanyAffected_Set_Input>;
  where: Ncr_CompanyAffected_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_CompanyAffected_By_PkArgs = {
  _set?: Maybe<Ncr_CompanyAffected_Set_Input>;
  pk_columns: Ncr_CompanyAffected_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_CorrectiveActionsArgs = {
  _inc?: Maybe<Ncr_CorrectiveActions_Inc_Input>;
  _set?: Maybe<Ncr_CorrectiveActions_Set_Input>;
  where: Ncr_CorrectiveActions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_CorrectiveActions_By_PkArgs = {
  _inc?: Maybe<Ncr_CorrectiveActions_Inc_Input>;
  _set?: Maybe<Ncr_CorrectiveActions_Set_Input>;
  pk_columns: Ncr_CorrectiveActions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_CustomerToInformArgs = {
  _set?: Maybe<Ncr_CustomerToInform_Set_Input>;
  where: Ncr_CustomerToInform_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_CustomerToInform_By_PkArgs = {
  _set?: Maybe<Ncr_CustomerToInform_Set_Input>;
  pk_columns: Ncr_CustomerToInform_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Defect_TeamMemberArgs = {
  _set?: Maybe<Ncr_Defect_TeamMember_Set_Input>;
  where: Ncr_Defect_TeamMember_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Defect_TeamMember_By_PkArgs = {
  _set?: Maybe<Ncr_Defect_TeamMember_Set_Input>;
  pk_columns: Ncr_Defect_TeamMember_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_DefectsArgs = {
  _inc?: Maybe<Ncr_Defects_Inc_Input>;
  _set?: Maybe<Ncr_Defects_Set_Input>;
  where: Ncr_Defects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Defects_By_PkArgs = {
  _inc?: Maybe<Ncr_Defects_Inc_Input>;
  _set?: Maybe<Ncr_Defects_Set_Input>;
  pk_columns: Ncr_Defects_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Feature_DefectArgs = {
  _set?: Maybe<Ncr_Feature_Defect_Set_Input>;
  where: Ncr_Feature_Defect_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_FeaturesArgs = {
  _inc?: Maybe<Ncr_Features_Inc_Input>;
  _set?: Maybe<Ncr_Features_Set_Input>;
  where: Ncr_Features_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Features_By_PkArgs = {
  _inc?: Maybe<Ncr_Features_Inc_Input>;
  _set?: Maybe<Ncr_Features_Set_Input>;
  pk_columns: Ncr_Features_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_PartAffectedArgs = {
  _set?: Maybe<Ncr_PartAffected_Set_Input>;
  where: Ncr_PartAffected_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_PartAffected_By_PkArgs = {
  _set?: Maybe<Ncr_PartAffected_Set_Input>;
  pk_columns: Ncr_PartAffected_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_RootCausesArgs = {
  _set?: Maybe<Ncr_RootCauses_Set_Input>;
  where: Ncr_RootCauses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_RootCauses_By_PkArgs = {
  _set?: Maybe<Ncr_RootCauses_Set_Input>;
  pk_columns: Ncr_RootCauses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_StatusesArgs = {
  _set?: Maybe<Ncr_Statuses_Set_Input>;
  where: Ncr_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Statuses_By_PkArgs = {
  _set?: Maybe<Ncr_Statuses_Set_Input>;
  pk_columns: Ncr_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_ThirdPartiesArgs = {
  _set?: Maybe<Ncr_ThirdParties_Set_Input>;
  where: Ncr_ThirdParties_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_ThirdParties_By_PkArgs = {
  _set?: Maybe<Ncr_ThirdParties_Set_Input>;
  pk_columns: Ncr_ThirdParties_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_UnitsArgs = {
  _inc?: Maybe<Ncr_Units_Inc_Input>;
  _set?: Maybe<Ncr_Units_Set_Input>;
  where: Ncr_Units_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Units_By_PkArgs = {
  _inc?: Maybe<Ncr_Units_Inc_Input>;
  _set?: Maybe<Ncr_Units_Set_Input>;
  pk_columns: Ncr_Units_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_UsersArgs = {
  _set?: Maybe<Ncr_Users_Set_Input>;
  where: Ncr_Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Ncr_Users_By_PkArgs = {
  _set?: Maybe<Ncr_Users_Set_Input>;
  pk_columns: Ncr_Users_Pk_Columns_Input;
};

/** columns and relationships of "ncr.ActionStatuses" */
export type Ncr_ActionStatuses = {
  __typename?: "ncr_ActionStatuses";
  Id: Scalars["Int"];
  Status: Scalars["String"];
};

/** aggregated selection of "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_Aggregate = {
  __typename?: "ncr_ActionStatuses_aggregate";
  aggregate?: Maybe<Ncr_ActionStatuses_Aggregate_Fields>;
  nodes: Array<Ncr_ActionStatuses>;
};

/** aggregate fields of "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_Aggregate_Fields = {
  __typename?: "ncr_ActionStatuses_aggregate_fields";
  avg?: Maybe<Ncr_ActionStatuses_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Ncr_ActionStatuses_Max_Fields>;
  min?: Maybe<Ncr_ActionStatuses_Min_Fields>;
  stddev?: Maybe<Ncr_ActionStatuses_Stddev_Fields>;
  stddev_pop?: Maybe<Ncr_ActionStatuses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ncr_ActionStatuses_Stddev_Samp_Fields>;
  sum?: Maybe<Ncr_ActionStatuses_Sum_Fields>;
  var_pop?: Maybe<Ncr_ActionStatuses_Var_Pop_Fields>;
  var_samp?: Maybe<Ncr_ActionStatuses_Var_Samp_Fields>;
  variance?: Maybe<Ncr_ActionStatuses_Variance_Fields>;
};


/** aggregate fields of "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_ActionStatuses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Ncr_ActionStatuses_Avg_Fields = {
  __typename?: "ncr_ActionStatuses_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "ncr.ActionStatuses". All fields are combined with a logical 'AND'. */
export type Ncr_ActionStatuses_Bool_Exp = {
  Id?: Maybe<Int_Comparison_Exp>;
  Status?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_ActionStatuses_Bool_Exp>>;
  _not?: Maybe<Ncr_ActionStatuses_Bool_Exp>;
  _or?: Maybe<Array<Ncr_ActionStatuses_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.ActionStatuses" */
export enum Ncr_ActionStatuses_Constraint {
  /** unique or primary key constraint */
  ActionStatusStatusKey = "ActionStatus_Status_key",
  /** unique or primary key constraint */
  ActionStatusPkey = "ActionStatus_pkey"
}

/** input type for incrementing numeric columns in table "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_Inc_Input = {
  Id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_Insert_Input = {
  Id?: Maybe<Scalars["Int"]>;
  Status?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ncr_ActionStatuses_Max_Fields = {
  __typename?: "ncr_ActionStatuses_max_fields";
  Id?: Maybe<Scalars["Int"]>;
  Status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Ncr_ActionStatuses_Min_Fields = {
  __typename?: "ncr_ActionStatuses_min_fields";
  Id?: Maybe<Scalars["Int"]>;
  Status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_Mutation_Response = {
  __typename?: "ncr_ActionStatuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_ActionStatuses>;
};

/** on conflict condition type for table "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_On_Conflict = {
  constraint: Ncr_ActionStatuses_Constraint;
  update_columns?: Array<Ncr_ActionStatuses_Update_Column>;
  where?: Maybe<Ncr_ActionStatuses_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.ActionStatuses". */
export type Ncr_ActionStatuses_Order_By = {
  Id?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_ActionStatuses */
export type Ncr_ActionStatuses_Pk_Columns_Input = {
  Id: Scalars["Int"];
};

/** select columns of table "ncr.ActionStatuses" */
export enum Ncr_ActionStatuses_Select_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Status = "Status"
}

/** input type for updating data in table "ncr.ActionStatuses" */
export type Ncr_ActionStatuses_Set_Input = {
  Id?: Maybe<Scalars["Int"]>;
  Status?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ncr_ActionStatuses_Stddev_Fields = {
  __typename?: "ncr_ActionStatuses_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Ncr_ActionStatuses_Stddev_Pop_Fields = {
  __typename?: "ncr_ActionStatuses_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Ncr_ActionStatuses_Stddev_Samp_Fields = {
  __typename?: "ncr_ActionStatuses_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Ncr_ActionStatuses_Sum_Fields = {
  __typename?: "ncr_ActionStatuses_sum_fields";
  Id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "ncr.ActionStatuses" */
export enum Ncr_ActionStatuses_Update_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Status = "Status"
}

/** aggregate var_pop on columns */
export type Ncr_ActionStatuses_Var_Pop_Fields = {
  __typename?: "ncr_ActionStatuses_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Ncr_ActionStatuses_Var_Samp_Fields = {
  __typename?: "ncr_ActionStatuses_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Ncr_ActionStatuses_Variance_Fields = {
  __typename?: "ncr_ActionStatuses_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "ncr.Addresses" */
export type Ncr_Addresses = {
  __typename?: "ncr_Addresses";
  City: Scalars["String"];
  Country: Scalars["String"];
  Id: Scalars["uuid"];
  PostalCode: Scalars["String"];
  State: Scalars["String"];
  Street: Scalars["String"];
  /** An array relationship */
  ThirdParties: Array<Ncr_ThirdParties>;
  /** An aggregate relationship */
  ThirdParties_aggregate: Ncr_ThirdParties_Aggregate;
};


/** columns and relationships of "ncr.Addresses" */
export type Ncr_AddressesThirdPartiesArgs = {
  distinct_on?: Maybe<Array<Ncr_ThirdParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ThirdParties_Order_By>>;
  where?: Maybe<Ncr_ThirdParties_Bool_Exp>;
};


/** columns and relationships of "ncr.Addresses" */
export type Ncr_AddressesThirdParties_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_ThirdParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ThirdParties_Order_By>>;
  where?: Maybe<Ncr_ThirdParties_Bool_Exp>;
};

/** aggregated selection of "ncr.Addresses" */
export type Ncr_Addresses_Aggregate = {
  __typename?: "ncr_Addresses_aggregate";
  aggregate?: Maybe<Ncr_Addresses_Aggregate_Fields>;
  nodes: Array<Ncr_Addresses>;
};

/** aggregate fields of "ncr.Addresses" */
export type Ncr_Addresses_Aggregate_Fields = {
  __typename?: "ncr_Addresses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Addresses_Max_Fields>;
  min?: Maybe<Ncr_Addresses_Min_Fields>;
};


/** aggregate fields of "ncr.Addresses" */
export type Ncr_Addresses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Addresses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "ncr.Addresses". All fields are combined with a logical 'AND'. */
export type Ncr_Addresses_Bool_Exp = {
  City?: Maybe<String_Comparison_Exp>;
  Country?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  PostalCode?: Maybe<String_Comparison_Exp>;
  State?: Maybe<String_Comparison_Exp>;
  Street?: Maybe<String_Comparison_Exp>;
  ThirdParties?: Maybe<Ncr_ThirdParties_Bool_Exp>;
  _and?: Maybe<Array<Ncr_Addresses_Bool_Exp>>;
  _not?: Maybe<Ncr_Addresses_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Addresses_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Addresses" */
export enum Ncr_Addresses_Constraint {
  /** unique or primary key constraint */
  AddressPkey = "Address_pkey"
}

/** input type for inserting data into table "ncr.Addresses" */
export type Ncr_Addresses_Insert_Input = {
  City?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PostalCode?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
  ThirdParties?: Maybe<Ncr_ThirdParties_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_Addresses_Max_Fields = {
  __typename?: "ncr_Addresses_max_fields";
  City?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PostalCode?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Ncr_Addresses_Min_Fields = {
  __typename?: "ncr_Addresses_min_fields";
  City?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PostalCode?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ncr.Addresses" */
export type Ncr_Addresses_Mutation_Response = {
  __typename?: "ncr_Addresses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Addresses>;
};

/** input type for inserting object relation for remote table "ncr.Addresses" */
export type Ncr_Addresses_Obj_Rel_Insert_Input = {
  data: Ncr_Addresses_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Addresses_On_Conflict>;
};

/** on conflict condition type for table "ncr.Addresses" */
export type Ncr_Addresses_On_Conflict = {
  constraint: Ncr_Addresses_Constraint;
  update_columns?: Array<Ncr_Addresses_Update_Column>;
  where?: Maybe<Ncr_Addresses_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Addresses". */
export type Ncr_Addresses_Order_By = {
  City?: Maybe<Order_By>;
  Country?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  PostalCode?: Maybe<Order_By>;
  State?: Maybe<Order_By>;
  Street?: Maybe<Order_By>;
  ThirdParties_aggregate?: Maybe<Ncr_ThirdParties_Aggregate_Order_By>;
};

/** primary key columns input for table: ncr_Addresses */
export type Ncr_Addresses_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.Addresses" */
export enum Ncr_Addresses_Select_Column {
  /** column name */
  City = "City",
  /** column name */
  Country = "Country",
  /** column name */
  Id = "Id",
  /** column name */
  PostalCode = "PostalCode",
  /** column name */
  State = "State",
  /** column name */
  Street = "Street"
}

/** input type for updating data in table "ncr.Addresses" */
export type Ncr_Addresses_Set_Input = {
  City?: Maybe<Scalars["String"]>;
  Country?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PostalCode?: Maybe<Scalars["String"]>;
  State?: Maybe<Scalars["String"]>;
  Street?: Maybe<Scalars["String"]>;
};

/** update columns of table "ncr.Addresses" */
export enum Ncr_Addresses_Update_Column {
  /** column name */
  City = "City",
  /** column name */
  Country = "Country",
  /** column name */
  Id = "Id",
  /** column name */
  PostalCode = "PostalCode",
  /** column name */
  State = "State",
  /** column name */
  Street = "Street"
}

/** columns and relationships of "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction = {
  __typename?: "ncr_Attachment_CorrectiveAction";
  /** An object relationship */
  Attachment: Ncr_Attachments;
  AttachmentId: Scalars["uuid"];
  /** An object relationship */
  CorrectiveAction: Ncr_CorrectiveActions;
  CorrectiveActionId: Scalars["uuid"];
};

/** aggregated selection of "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Aggregate = {
  __typename?: "ncr_Attachment_CorrectiveAction_aggregate";
  aggregate?: Maybe<Ncr_Attachment_CorrectiveAction_Aggregate_Fields>;
  nodes: Array<Ncr_Attachment_CorrectiveAction>;
};

/** aggregate fields of "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Aggregate_Fields = {
  __typename?: "ncr_Attachment_CorrectiveAction_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Attachment_CorrectiveAction_Max_Fields>;
  min?: Maybe<Ncr_Attachment_CorrectiveAction_Min_Fields>;
};


/** aggregate fields of "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_Attachment_CorrectiveAction_Max_Order_By>;
  min?: Maybe<Ncr_Attachment_CorrectiveAction_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Arr_Rel_Insert_Input = {
  data: Array<Ncr_Attachment_CorrectiveAction_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Attachment_CorrectiveAction_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.Attachment_CorrectiveAction". All fields are combined with a logical 'AND'. */
export type Ncr_Attachment_CorrectiveAction_Bool_Exp = {
  Attachment?: Maybe<Ncr_Attachments_Bool_Exp>;
  AttachmentId?: Maybe<Uuid_Comparison_Exp>;
  CorrectiveAction?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
  CorrectiveActionId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Bool_Exp>>;
  _not?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Attachment_CorrectiveAction" */
export enum Ncr_Attachment_CorrectiveAction_Constraint {
  /** unique or primary key constraint */
  CorrectiveActionAttachmentPkey = "CorrectiveAction_Attachment_pkey"
}

/** input type for inserting data into table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Insert_Input = {
  Attachment?: Maybe<Ncr_Attachments_Obj_Rel_Insert_Input>;
  AttachmentId?: Maybe<Scalars["uuid"]>;
  CorrectiveAction?: Maybe<Ncr_CorrectiveActions_Obj_Rel_Insert_Input>;
  CorrectiveActionId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ncr_Attachment_CorrectiveAction_Max_Fields = {
  __typename?: "ncr_Attachment_CorrectiveAction_max_fields";
  AttachmentId?: Maybe<Scalars["uuid"]>;
  CorrectiveActionId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Max_Order_By = {
  AttachmentId?: Maybe<Order_By>;
  CorrectiveActionId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_Attachment_CorrectiveAction_Min_Fields = {
  __typename?: "ncr_Attachment_CorrectiveAction_min_fields";
  AttachmentId?: Maybe<Scalars["uuid"]>;
  CorrectiveActionId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Min_Order_By = {
  AttachmentId?: Maybe<Order_By>;
  CorrectiveActionId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Mutation_Response = {
  __typename?: "ncr_Attachment_CorrectiveAction_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Attachment_CorrectiveAction>;
};

/** on conflict condition type for table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_On_Conflict = {
  constraint: Ncr_Attachment_CorrectiveAction_Constraint;
  update_columns?: Array<Ncr_Attachment_CorrectiveAction_Update_Column>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Attachment_CorrectiveAction". */
export type Ncr_Attachment_CorrectiveAction_Order_By = {
  Attachment?: Maybe<Ncr_Attachments_Order_By>;
  AttachmentId?: Maybe<Order_By>;
  CorrectiveAction?: Maybe<Ncr_CorrectiveActions_Order_By>;
  CorrectiveActionId?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_Attachment_CorrectiveAction */
export type Ncr_Attachment_CorrectiveAction_Pk_Columns_Input = {
  AttachmentId: Scalars["uuid"];
  CorrectiveActionId: Scalars["uuid"];
};

/** select columns of table "ncr.Attachment_CorrectiveAction" */
export enum Ncr_Attachment_CorrectiveAction_Select_Column {
  /** column name */
  AttachmentId = "AttachmentId",
  /** column name */
  CorrectiveActionId = "CorrectiveActionId"
}

/** input type for updating data in table "ncr.Attachment_CorrectiveAction" */
export type Ncr_Attachment_CorrectiveAction_Set_Input = {
  AttachmentId?: Maybe<Scalars["uuid"]>;
  CorrectiveActionId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.Attachment_CorrectiveAction" */
export enum Ncr_Attachment_CorrectiveAction_Update_Column {
  /** column name */
  AttachmentId = "AttachmentId",
  /** column name */
  CorrectiveActionId = "CorrectiveActionId"
}

/** columns and relationships of "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect = {
  __typename?: "ncr_Attachment_Defect";
  /** An object relationship */
  Attachment: Ncr_Attachments;
  AttachmentId: Scalars["uuid"];
  /** An object relationship */
  Defect: Ncr_Defects;
  DefectId: Scalars["uuid"];
};

/** aggregated selection of "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Aggregate = {
  __typename?: "ncr_Attachment_Defect_aggregate";
  aggregate?: Maybe<Ncr_Attachment_Defect_Aggregate_Fields>;
  nodes: Array<Ncr_Attachment_Defect>;
};

/** aggregate fields of "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Aggregate_Fields = {
  __typename?: "ncr_Attachment_Defect_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Attachment_Defect_Max_Fields>;
  min?: Maybe<Ncr_Attachment_Defect_Min_Fields>;
};


/** aggregate fields of "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_Attachment_Defect_Max_Order_By>;
  min?: Maybe<Ncr_Attachment_Defect_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Arr_Rel_Insert_Input = {
  data: Array<Ncr_Attachment_Defect_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Attachment_Defect_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.Attachment_Defect". All fields are combined with a logical 'AND'. */
export type Ncr_Attachment_Defect_Bool_Exp = {
  Attachment?: Maybe<Ncr_Attachments_Bool_Exp>;
  AttachmentId?: Maybe<Uuid_Comparison_Exp>;
  Defect?: Maybe<Ncr_Defects_Bool_Exp>;
  DefectId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Attachment_Defect_Bool_Exp>>;
  _not?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Attachment_Defect_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Attachment_Defect" */
export enum Ncr_Attachment_Defect_Constraint {
  /** unique or primary key constraint */
  AttachmentDefectPkey = "Attachment_Defect_pkey"
}

/** input type for inserting data into table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Insert_Input = {
  Attachment?: Maybe<Ncr_Attachments_Obj_Rel_Insert_Input>;
  AttachmentId?: Maybe<Scalars["uuid"]>;
  Defect?: Maybe<Ncr_Defects_Obj_Rel_Insert_Input>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ncr_Attachment_Defect_Max_Fields = {
  __typename?: "ncr_Attachment_Defect_max_fields";
  AttachmentId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Max_Order_By = {
  AttachmentId?: Maybe<Order_By>;
  DefectId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_Attachment_Defect_Min_Fields = {
  __typename?: "ncr_Attachment_Defect_min_fields";
  AttachmentId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Min_Order_By = {
  AttachmentId?: Maybe<Order_By>;
  DefectId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Mutation_Response = {
  __typename?: "ncr_Attachment_Defect_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Attachment_Defect>;
};

/** on conflict condition type for table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_On_Conflict = {
  constraint: Ncr_Attachment_Defect_Constraint;
  update_columns?: Array<Ncr_Attachment_Defect_Update_Column>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Attachment_Defect". */
export type Ncr_Attachment_Defect_Order_By = {
  Attachment?: Maybe<Ncr_Attachments_Order_By>;
  AttachmentId?: Maybe<Order_By>;
  Defect?: Maybe<Ncr_Defects_Order_By>;
  DefectId?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_Attachment_Defect */
export type Ncr_Attachment_Defect_Pk_Columns_Input = {
  AttachmentId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};

/** select columns of table "ncr.Attachment_Defect" */
export enum Ncr_Attachment_Defect_Select_Column {
  /** column name */
  AttachmentId = "AttachmentId",
  /** column name */
  DefectId = "DefectId"
}

/** input type for updating data in table "ncr.Attachment_Defect" */
export type Ncr_Attachment_Defect_Set_Input = {
  AttachmentId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.Attachment_Defect" */
export enum Ncr_Attachment_Defect_Update_Column {
  /** column name */
  AttachmentId = "AttachmentId",
  /** column name */
  DefectId = "DefectId"
}

/** columns and relationships of "ncr.Attachment_Product" */
export type Ncr_Attachment_Product = {
  __typename?: "ncr_Attachment_Product";
  /** An object relationship */
  Attachment: Ncr_Attachments;
  AttachmentId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};

/** aggregated selection of "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Aggregate = {
  __typename?: "ncr_Attachment_Product_aggregate";
  aggregate?: Maybe<Ncr_Attachment_Product_Aggregate_Fields>;
  nodes: Array<Ncr_Attachment_Product>;
};

/** aggregate fields of "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Aggregate_Fields = {
  __typename?: "ncr_Attachment_Product_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Attachment_Product_Max_Fields>;
  min?: Maybe<Ncr_Attachment_Product_Min_Fields>;
};


/** aggregate fields of "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Attachment_Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_Attachment_Product_Max_Order_By>;
  min?: Maybe<Ncr_Attachment_Product_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Arr_Rel_Insert_Input = {
  data: Array<Ncr_Attachment_Product_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Attachment_Product_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.Attachment_Product". All fields are combined with a logical 'AND'. */
export type Ncr_Attachment_Product_Bool_Exp = {
  Attachment?: Maybe<Ncr_Attachments_Bool_Exp>;
  AttachmentId?: Maybe<Uuid_Comparison_Exp>;
  ProductId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Attachment_Product_Bool_Exp>>;
  _not?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Attachment_Product_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Attachment_Product" */
export enum Ncr_Attachment_Product_Constraint {
  /** unique or primary key constraint */
  ProductAttachmentPkey = "Product_Attachment_pkey"
}

/** input type for inserting data into table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Insert_Input = {
  Attachment?: Maybe<Ncr_Attachments_Obj_Rel_Insert_Input>;
  AttachmentId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ncr_Attachment_Product_Max_Fields = {
  __typename?: "ncr_Attachment_Product_max_fields";
  AttachmentId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Max_Order_By = {
  AttachmentId?: Maybe<Order_By>;
  ProductId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_Attachment_Product_Min_Fields = {
  __typename?: "ncr_Attachment_Product_min_fields";
  AttachmentId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Min_Order_By = {
  AttachmentId?: Maybe<Order_By>;
  ProductId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Mutation_Response = {
  __typename?: "ncr_Attachment_Product_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Attachment_Product>;
};

/** on conflict condition type for table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_On_Conflict = {
  constraint: Ncr_Attachment_Product_Constraint;
  update_columns?: Array<Ncr_Attachment_Product_Update_Column>;
  where?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Attachment_Product". */
export type Ncr_Attachment_Product_Order_By = {
  Attachment?: Maybe<Ncr_Attachments_Order_By>;
  AttachmentId?: Maybe<Order_By>;
  ProductId?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_Attachment_Product */
export type Ncr_Attachment_Product_Pk_Columns_Input = {
  AttachmentId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};

/** select columns of table "ncr.Attachment_Product" */
export enum Ncr_Attachment_Product_Select_Column {
  /** column name */
  AttachmentId = "AttachmentId",
  /** column name */
  ProductId = "ProductId"
}

/** input type for updating data in table "ncr.Attachment_Product" */
export type Ncr_Attachment_Product_Set_Input = {
  AttachmentId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.Attachment_Product" */
export enum Ncr_Attachment_Product_Update_Column {
  /** column name */
  AttachmentId = "AttachmentId",
  /** column name */
  ProductId = "ProductId"
}

/** columns and relationships of "ncr.Attachments" */
export type Ncr_Attachments = {
  __typename?: "ncr_Attachments";
  /** An array relationship */
  Attachment_Defects: Array<Ncr_Attachment_Defect>;
  /** An aggregate relationship */
  Attachment_Defects_aggregate: Ncr_Attachment_Defect_Aggregate;
  /** An array relationship */
  CorrectiveAction_Attachments: Array<Ncr_Attachment_CorrectiveAction>;
  /** An aggregate relationship */
  CorrectiveAction_Attachments_aggregate: Ncr_Attachment_CorrectiveAction_Aggregate;
  Filename: Scalars["String"];
  Id: Scalars["uuid"];
  Path: Scalars["String"];
  /** An array relationship */
  Product_Attachments: Array<Ncr_Attachment_Product>;
  /** An aggregate relationship */
  Product_Attachments_aggregate: Ncr_Attachment_Product_Aggregate;
};


/** columns and relationships of "ncr.Attachments" */
export type Ncr_AttachmentsAttachment_DefectsArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


/** columns and relationships of "ncr.Attachments" */
export type Ncr_AttachmentsAttachment_Defects_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


/** columns and relationships of "ncr.Attachments" */
export type Ncr_AttachmentsCorrectiveAction_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};


/** columns and relationships of "ncr.Attachments" */
export type Ncr_AttachmentsCorrectiveAction_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};


/** columns and relationships of "ncr.Attachments" */
export type Ncr_AttachmentsProduct_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Product_Order_By>>;
  where?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
};


/** columns and relationships of "ncr.Attachments" */
export type Ncr_AttachmentsProduct_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Product_Order_By>>;
  where?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
};

/** aggregated selection of "ncr.Attachments" */
export type Ncr_Attachments_Aggregate = {
  __typename?: "ncr_Attachments_aggregate";
  aggregate?: Maybe<Ncr_Attachments_Aggregate_Fields>;
  nodes: Array<Ncr_Attachments>;
};

/** aggregate fields of "ncr.Attachments" */
export type Ncr_Attachments_Aggregate_Fields = {
  __typename?: "ncr_Attachments_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Attachments_Max_Fields>;
  min?: Maybe<Ncr_Attachments_Min_Fields>;
};


/** aggregate fields of "ncr.Attachments" */
export type Ncr_Attachments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Attachments_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "ncr.Attachments". All fields are combined with a logical 'AND'. */
export type Ncr_Attachments_Bool_Exp = {
  Attachment_Defects?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
  CorrectiveAction_Attachments?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
  Filename?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Path?: Maybe<String_Comparison_Exp>;
  Product_Attachments?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
  _and?: Maybe<Array<Ncr_Attachments_Bool_Exp>>;
  _not?: Maybe<Ncr_Attachments_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Attachments_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Attachments" */
export enum Ncr_Attachments_Constraint {
  /** unique or primary key constraint */
  AttachmentsFilenamePathKey = "Attachments_Filename_Path_key",
  /** unique or primary key constraint */
  DrawingPkey = "Drawing_pkey"
}

/** input type for inserting data into table "ncr.Attachments" */
export type Ncr_Attachments_Insert_Input = {
  Attachment_Defects?: Maybe<Ncr_Attachment_Defect_Arr_Rel_Insert_Input>;
  CorrectiveAction_Attachments?: Maybe<Ncr_Attachment_CorrectiveAction_Arr_Rel_Insert_Input>;
  Filename?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Path?: Maybe<Scalars["String"]>;
  Product_Attachments?: Maybe<Ncr_Attachment_Product_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_Attachments_Max_Fields = {
  __typename?: "ncr_Attachments_max_fields";
  Filename?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Path?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Ncr_Attachments_Min_Fields = {
  __typename?: "ncr_Attachments_min_fields";
  Filename?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Path?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ncr.Attachments" */
export type Ncr_Attachments_Mutation_Response = {
  __typename?: "ncr_Attachments_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Attachments>;
};

/** input type for inserting object relation for remote table "ncr.Attachments" */
export type Ncr_Attachments_Obj_Rel_Insert_Input = {
  data: Ncr_Attachments_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Attachments_On_Conflict>;
};

/** on conflict condition type for table "ncr.Attachments" */
export type Ncr_Attachments_On_Conflict = {
  constraint: Ncr_Attachments_Constraint;
  update_columns?: Array<Ncr_Attachments_Update_Column>;
  where?: Maybe<Ncr_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Attachments". */
export type Ncr_Attachments_Order_By = {
  Attachment_Defects_aggregate?: Maybe<Ncr_Attachment_Defect_Aggregate_Order_By>;
  CorrectiveAction_Attachments_aggregate?: Maybe<Ncr_Attachment_CorrectiveAction_Aggregate_Order_By>;
  Filename?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Path?: Maybe<Order_By>;
  Product_Attachments_aggregate?: Maybe<Ncr_Attachment_Product_Aggregate_Order_By>;
};

/** primary key columns input for table: ncr_Attachments */
export type Ncr_Attachments_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.Attachments" */
export enum Ncr_Attachments_Select_Column {
  /** column name */
  Filename = "Filename",
  /** column name */
  Id = "Id",
  /** column name */
  Path = "Path"
}

/** input type for updating data in table "ncr.Attachments" */
export type Ncr_Attachments_Set_Input = {
  Filename?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Path?: Maybe<Scalars["String"]>;
};

/** update columns of table "ncr.Attachments" */
export enum Ncr_Attachments_Update_Column {
  /** column name */
  Filename = "Filename",
  /** column name */
  Id = "Id",
  /** column name */
  Path = "Path"
}

/** columns and relationships of "ncr.CompanyAffected" */
export type Ncr_CompanyAffected = {
  __typename?: "ncr_CompanyAffected";
  CompanyId: Scalars["uuid"];
  /** An object relationship */
  Defect: Ncr_Defects;
  DefectId: Scalars["uuid"];
  /** An object relationship */
  ThirdParty: Ncr_ThirdParties;
};

/** aggregated selection of "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Aggregate = {
  __typename?: "ncr_CompanyAffected_aggregate";
  aggregate?: Maybe<Ncr_CompanyAffected_Aggregate_Fields>;
  nodes: Array<Ncr_CompanyAffected>;
};

/** aggregate fields of "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Aggregate_Fields = {
  __typename?: "ncr_CompanyAffected_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_CompanyAffected_Max_Fields>;
  min?: Maybe<Ncr_CompanyAffected_Min_Fields>;
};


/** aggregate fields of "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_CompanyAffected_Max_Order_By>;
  min?: Maybe<Ncr_CompanyAffected_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Arr_Rel_Insert_Input = {
  data: Array<Ncr_CompanyAffected_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_CompanyAffected_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.CompanyAffected". All fields are combined with a logical 'AND'. */
export type Ncr_CompanyAffected_Bool_Exp = {
  CompanyId?: Maybe<Uuid_Comparison_Exp>;
  Defect?: Maybe<Ncr_Defects_Bool_Exp>;
  DefectId?: Maybe<Uuid_Comparison_Exp>;
  ThirdParty?: Maybe<Ncr_ThirdParties_Bool_Exp>;
  _and?: Maybe<Array<Ncr_CompanyAffected_Bool_Exp>>;
  _not?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
  _or?: Maybe<Array<Ncr_CompanyAffected_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.CompanyAffected" */
export enum Ncr_CompanyAffected_Constraint {
  /** unique or primary key constraint */
  CompanyAffectedPkey = "CompanyAffected_pkey"
}

/** input type for inserting data into table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Insert_Input = {
  CompanyId?: Maybe<Scalars["uuid"]>;
  Defect?: Maybe<Ncr_Defects_Obj_Rel_Insert_Input>;
  DefectId?: Maybe<Scalars["uuid"]>;
  ThirdParty?: Maybe<Ncr_ThirdParties_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_CompanyAffected_Max_Fields = {
  __typename?: "ncr_CompanyAffected_max_fields";
  CompanyId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Max_Order_By = {
  CompanyId?: Maybe<Order_By>;
  DefectId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_CompanyAffected_Min_Fields = {
  __typename?: "ncr_CompanyAffected_min_fields";
  CompanyId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Min_Order_By = {
  CompanyId?: Maybe<Order_By>;
  DefectId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Mutation_Response = {
  __typename?: "ncr_CompanyAffected_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_CompanyAffected>;
};

/** on conflict condition type for table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_On_Conflict = {
  constraint: Ncr_CompanyAffected_Constraint;
  update_columns?: Array<Ncr_CompanyAffected_Update_Column>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.CompanyAffected". */
export type Ncr_CompanyAffected_Order_By = {
  CompanyId?: Maybe<Order_By>;
  Defect?: Maybe<Ncr_Defects_Order_By>;
  DefectId?: Maybe<Order_By>;
  ThirdParty?: Maybe<Ncr_ThirdParties_Order_By>;
};

/** primary key columns input for table: ncr_CompanyAffected */
export type Ncr_CompanyAffected_Pk_Columns_Input = {
  CompanyId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};

/** select columns of table "ncr.CompanyAffected" */
export enum Ncr_CompanyAffected_Select_Column {
  /** column name */
  CompanyId = "CompanyId",
  /** column name */
  DefectId = "DefectId"
}

/** input type for updating data in table "ncr.CompanyAffected" */
export type Ncr_CompanyAffected_Set_Input = {
  CompanyId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.CompanyAffected" */
export enum Ncr_CompanyAffected_Update_Column {
  /** column name */
  CompanyId = "CompanyId",
  /** column name */
  DefectId = "DefectId"
}

/** columns and relationships of "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions = {
  __typename?: "ncr_CorrectiveActions";
  ActionStatus: Scalars["Int"];
  /** An array relationship */
  CorrectiveAction_Attachments: Array<Ncr_Attachment_CorrectiveAction>;
  /** An aggregate relationship */
  CorrectiveAction_Attachments_aggregate: Ncr_Attachment_CorrectiveAction_Aggregate;
  Cost: Scalars["money"];
  Defect: Scalars["uuid"];
  Description: Scalars["String"];
  DueDate: Scalars["timetz"];
  Id: Scalars["uuid"];
  Responsible: Scalars["uuid"];
  /** An object relationship */
  User: Ncr_Users;
  /** An object relationship */
  defectByDefect: Ncr_Defects;
};


/** columns and relationships of "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActionsCorrectiveAction_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};


/** columns and relationships of "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActionsCorrectiveAction_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};

/** aggregated selection of "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Aggregate = {
  __typename?: "ncr_CorrectiveActions_aggregate";
  aggregate?: Maybe<Ncr_CorrectiveActions_Aggregate_Fields>;
  nodes: Array<Ncr_CorrectiveActions>;
};

/** aggregate fields of "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Aggregate_Fields = {
  __typename?: "ncr_CorrectiveActions_aggregate_fields";
  avg?: Maybe<Ncr_CorrectiveActions_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Ncr_CorrectiveActions_Max_Fields>;
  min?: Maybe<Ncr_CorrectiveActions_Min_Fields>;
  stddev?: Maybe<Ncr_CorrectiveActions_Stddev_Fields>;
  stddev_pop?: Maybe<Ncr_CorrectiveActions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ncr_CorrectiveActions_Stddev_Samp_Fields>;
  sum?: Maybe<Ncr_CorrectiveActions_Sum_Fields>;
  var_pop?: Maybe<Ncr_CorrectiveActions_Var_Pop_Fields>;
  var_samp?: Maybe<Ncr_CorrectiveActions_Var_Samp_Fields>;
  variance?: Maybe<Ncr_CorrectiveActions_Variance_Fields>;
};


/** aggregate fields of "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Aggregate_Order_By = {
  avg?: Maybe<Ncr_CorrectiveActions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_CorrectiveActions_Max_Order_By>;
  min?: Maybe<Ncr_CorrectiveActions_Min_Order_By>;
  stddev?: Maybe<Ncr_CorrectiveActions_Stddev_Order_By>;
  stddev_pop?: Maybe<Ncr_CorrectiveActions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ncr_CorrectiveActions_Stddev_Samp_Order_By>;
  sum?: Maybe<Ncr_CorrectiveActions_Sum_Order_By>;
  var_pop?: Maybe<Ncr_CorrectiveActions_Var_Pop_Order_By>;
  var_samp?: Maybe<Ncr_CorrectiveActions_Var_Samp_Order_By>;
  variance?: Maybe<Ncr_CorrectiveActions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Arr_Rel_Insert_Input = {
  data: Array<Ncr_CorrectiveActions_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_CorrectiveActions_On_Conflict>;
};

/** aggregate avg on columns */
export type Ncr_CorrectiveActions_Avg_Fields = {
  __typename?: "ncr_CorrectiveActions_avg_fields";
  ActionStatus?: Maybe<Scalars["Float"]>;
  Cost?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Avg_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ncr.CorrectiveActions". All fields are combined with a logical 'AND'. */
export type Ncr_CorrectiveActions_Bool_Exp = {
  ActionStatus?: Maybe<Int_Comparison_Exp>;
  CorrectiveAction_Attachments?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
  Cost?: Maybe<Money_Comparison_Exp>;
  Defect?: Maybe<Uuid_Comparison_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  DueDate?: Maybe<Timetz_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Responsible?: Maybe<Uuid_Comparison_Exp>;
  User?: Maybe<Ncr_Users_Bool_Exp>;
  _and?: Maybe<Array<Ncr_CorrectiveActions_Bool_Exp>>;
  _not?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
  _or?: Maybe<Array<Ncr_CorrectiveActions_Bool_Exp>>;
  defectByDefect?: Maybe<Ncr_Defects_Bool_Exp>;
};

/** unique or primary key constraints on table "ncr.CorrectiveActions" */
export enum Ncr_CorrectiveActions_Constraint {
  /** unique or primary key constraint */
  CorrectiveActionsPkey = "CorrectiveActions_pkey"
}

/** input type for incrementing numeric columns in table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Inc_Input = {
  ActionStatus?: Maybe<Scalars["Int"]>;
  Cost?: Maybe<Scalars["money"]>;
};

/** input type for inserting data into table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Insert_Input = {
  ActionStatus?: Maybe<Scalars["Int"]>;
  CorrectiveAction_Attachments?: Maybe<Ncr_Attachment_CorrectiveAction_Arr_Rel_Insert_Input>;
  Cost?: Maybe<Scalars["money"]>;
  Defect?: Maybe<Scalars["uuid"]>;
  Description?: Maybe<Scalars["String"]>;
  DueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Responsible?: Maybe<Scalars["uuid"]>;
  User?: Maybe<Ncr_Users_Obj_Rel_Insert_Input>;
  defectByDefect?: Maybe<Ncr_Defects_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_CorrectiveActions_Max_Fields = {
  __typename?: "ncr_CorrectiveActions_max_fields";
  ActionStatus?: Maybe<Scalars["Int"]>;
  Cost?: Maybe<Scalars["money"]>;
  Defect?: Maybe<Scalars["uuid"]>;
  Description?: Maybe<Scalars["String"]>;
  DueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Responsible?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Max_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  Defect?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Responsible?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_CorrectiveActions_Min_Fields = {
  __typename?: "ncr_CorrectiveActions_min_fields";
  ActionStatus?: Maybe<Scalars["Int"]>;
  Cost?: Maybe<Scalars["money"]>;
  Defect?: Maybe<Scalars["uuid"]>;
  Description?: Maybe<Scalars["String"]>;
  DueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Responsible?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Min_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
  Defect?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Responsible?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Mutation_Response = {
  __typename?: "ncr_CorrectiveActions_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_CorrectiveActions>;
};

/** input type for inserting object relation for remote table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Obj_Rel_Insert_Input = {
  data: Ncr_CorrectiveActions_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_CorrectiveActions_On_Conflict>;
};

/** on conflict condition type for table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_On_Conflict = {
  constraint: Ncr_CorrectiveActions_Constraint;
  update_columns?: Array<Ncr_CorrectiveActions_Update_Column>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.CorrectiveActions". */
export type Ncr_CorrectiveActions_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  CorrectiveAction_Attachments_aggregate?: Maybe<Ncr_Attachment_CorrectiveAction_Aggregate_Order_By>;
  Cost?: Maybe<Order_By>;
  Defect?: Maybe<Order_By>;
  Description?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Responsible?: Maybe<Order_By>;
  User?: Maybe<Ncr_Users_Order_By>;
  defectByDefect?: Maybe<Ncr_Defects_Order_By>;
};

/** primary key columns input for table: ncr_CorrectiveActions */
export type Ncr_CorrectiveActions_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.CorrectiveActions" */
export enum Ncr_CorrectiveActions_Select_Column {
  /** column name */
  ActionStatus = "ActionStatus",
  /** column name */
  Cost = "Cost",
  /** column name */
  Defect = "Defect",
  /** column name */
  Description = "Description",
  /** column name */
  DueDate = "DueDate",
  /** column name */
  Id = "Id",
  /** column name */
  Responsible = "Responsible"
}

/** input type for updating data in table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Set_Input = {
  ActionStatus?: Maybe<Scalars["Int"]>;
  Cost?: Maybe<Scalars["money"]>;
  Defect?: Maybe<Scalars["uuid"]>;
  Description?: Maybe<Scalars["String"]>;
  DueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Responsible?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Ncr_CorrectiveActions_Stddev_Fields = {
  __typename?: "ncr_CorrectiveActions_stddev_fields";
  ActionStatus?: Maybe<Scalars["Float"]>;
  Cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Stddev_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ncr_CorrectiveActions_Stddev_Pop_Fields = {
  __typename?: "ncr_CorrectiveActions_stddev_pop_fields";
  ActionStatus?: Maybe<Scalars["Float"]>;
  Cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Stddev_Pop_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ncr_CorrectiveActions_Stddev_Samp_Fields = {
  __typename?: "ncr_CorrectiveActions_stddev_samp_fields";
  ActionStatus?: Maybe<Scalars["Float"]>;
  Cost?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Stddev_Samp_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ncr_CorrectiveActions_Sum_Fields = {
  __typename?: "ncr_CorrectiveActions_sum_fields";
  ActionStatus?: Maybe<Scalars["Int"]>;
  Cost?: Maybe<Scalars["money"]>;
};

/** order by sum() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Sum_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** update columns of table "ncr.CorrectiveActions" */
export enum Ncr_CorrectiveActions_Update_Column {
  /** column name */
  ActionStatus = "ActionStatus",
  /** column name */
  Cost = "Cost",
  /** column name */
  Defect = "Defect",
  /** column name */
  Description = "Description",
  /** column name */
  DueDate = "DueDate",
  /** column name */
  Id = "Id",
  /** column name */
  Responsible = "Responsible"
}

/** aggregate var_pop on columns */
export type Ncr_CorrectiveActions_Var_Pop_Fields = {
  __typename?: "ncr_CorrectiveActions_var_pop_fields";
  ActionStatus?: Maybe<Scalars["Float"]>;
  Cost?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Var_Pop_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ncr_CorrectiveActions_Var_Samp_Fields = {
  __typename?: "ncr_CorrectiveActions_var_samp_fields";
  ActionStatus?: Maybe<Scalars["Float"]>;
  Cost?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Var_Samp_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ncr_CorrectiveActions_Variance_Fields = {
  __typename?: "ncr_CorrectiveActions_variance_fields";
  ActionStatus?: Maybe<Scalars["Float"]>;
  Cost?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ncr.CorrectiveActions" */
export type Ncr_CorrectiveActions_Variance_Order_By = {
  ActionStatus?: Maybe<Order_By>;
  Cost?: Maybe<Order_By>;
};

/** columns and relationships of "ncr.CustomerToInform" */
export type Ncr_CustomerToInform = {
  __typename?: "ncr_CustomerToInform";
  CustomerId: Scalars["uuid"];
  /** An object relationship */
  Defect: Ncr_Defects;
  DefectId: Scalars["uuid"];
  /** An object relationship */
  ThirdParty: Ncr_ThirdParties;
};

/** aggregated selection of "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Aggregate = {
  __typename?: "ncr_CustomerToInform_aggregate";
  aggregate?: Maybe<Ncr_CustomerToInform_Aggregate_Fields>;
  nodes: Array<Ncr_CustomerToInform>;
};

/** aggregate fields of "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Aggregate_Fields = {
  __typename?: "ncr_CustomerToInform_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_CustomerToInform_Max_Fields>;
  min?: Maybe<Ncr_CustomerToInform_Min_Fields>;
};


/** aggregate fields of "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_CustomerToInform_Max_Order_By>;
  min?: Maybe<Ncr_CustomerToInform_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Arr_Rel_Insert_Input = {
  data: Array<Ncr_CustomerToInform_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_CustomerToInform_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.CustomerToInform". All fields are combined with a logical 'AND'. */
export type Ncr_CustomerToInform_Bool_Exp = {
  CustomerId?: Maybe<Uuid_Comparison_Exp>;
  Defect?: Maybe<Ncr_Defects_Bool_Exp>;
  DefectId?: Maybe<Uuid_Comparison_Exp>;
  ThirdParty?: Maybe<Ncr_ThirdParties_Bool_Exp>;
  _and?: Maybe<Array<Ncr_CustomerToInform_Bool_Exp>>;
  _not?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
  _or?: Maybe<Array<Ncr_CustomerToInform_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.CustomerToInform" */
export enum Ncr_CustomerToInform_Constraint {
  /** unique or primary key constraint */
  CustomersToInformPkey = "CustomersToInform_pkey"
}

/** input type for inserting data into table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Insert_Input = {
  CustomerId?: Maybe<Scalars["uuid"]>;
  Defect?: Maybe<Ncr_Defects_Obj_Rel_Insert_Input>;
  DefectId?: Maybe<Scalars["uuid"]>;
  ThirdParty?: Maybe<Ncr_ThirdParties_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_CustomerToInform_Max_Fields = {
  __typename?: "ncr_CustomerToInform_max_fields";
  CustomerId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Max_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DefectId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_CustomerToInform_Min_Fields = {
  __typename?: "ncr_CustomerToInform_min_fields";
  CustomerId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Min_Order_By = {
  CustomerId?: Maybe<Order_By>;
  DefectId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Mutation_Response = {
  __typename?: "ncr_CustomerToInform_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_CustomerToInform>;
};

/** on conflict condition type for table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_On_Conflict = {
  constraint: Ncr_CustomerToInform_Constraint;
  update_columns?: Array<Ncr_CustomerToInform_Update_Column>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.CustomerToInform". */
export type Ncr_CustomerToInform_Order_By = {
  CustomerId?: Maybe<Order_By>;
  Defect?: Maybe<Ncr_Defects_Order_By>;
  DefectId?: Maybe<Order_By>;
  ThirdParty?: Maybe<Ncr_ThirdParties_Order_By>;
};

/** primary key columns input for table: ncr_CustomerToInform */
export type Ncr_CustomerToInform_Pk_Columns_Input = {
  CustomerId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};

/** select columns of table "ncr.CustomerToInform" */
export enum Ncr_CustomerToInform_Select_Column {
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DefectId = "DefectId"
}

/** input type for updating data in table "ncr.CustomerToInform" */
export type Ncr_CustomerToInform_Set_Input = {
  CustomerId?: Maybe<Scalars["uuid"]>;
  DefectId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.CustomerToInform" */
export enum Ncr_CustomerToInform_Update_Column {
  /** column name */
  CustomerId = "CustomerId",
  /** column name */
  DefectId = "DefectId"
}

/** columns and relationships of "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember = {
  __typename?: "ncr_Defect_TeamMember";
  /** An object relationship */
  Defect: Ncr_Defects;
  DefectId: Scalars["uuid"];
  /** An object relationship */
  User: Ncr_Users;
  UserId: Scalars["uuid"];
};

/** aggregated selection of "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Aggregate = {
  __typename?: "ncr_Defect_TeamMember_aggregate";
  aggregate?: Maybe<Ncr_Defect_TeamMember_Aggregate_Fields>;
  nodes: Array<Ncr_Defect_TeamMember>;
};

/** aggregate fields of "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Aggregate_Fields = {
  __typename?: "ncr_Defect_TeamMember_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Defect_TeamMember_Max_Fields>;
  min?: Maybe<Ncr_Defect_TeamMember_Min_Fields>;
};


/** aggregate fields of "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_Defect_TeamMember_Max_Order_By>;
  min?: Maybe<Ncr_Defect_TeamMember_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Arr_Rel_Insert_Input = {
  data: Array<Ncr_Defect_TeamMember_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Defect_TeamMember_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.Defect_TeamMember". All fields are combined with a logical 'AND'. */
export type Ncr_Defect_TeamMember_Bool_Exp = {
  Defect?: Maybe<Ncr_Defects_Bool_Exp>;
  DefectId?: Maybe<Uuid_Comparison_Exp>;
  User?: Maybe<Ncr_Users_Bool_Exp>;
  UserId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Defect_TeamMember_Bool_Exp>>;
  _not?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Defect_TeamMember_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Defect_TeamMember" */
export enum Ncr_Defect_TeamMember_Constraint {
  /** unique or primary key constraint */
  DefectTeamMemberPkey = "Defect_TeamMember_pkey"
}

/** input type for inserting data into table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Insert_Input = {
  Defect?: Maybe<Ncr_Defects_Obj_Rel_Insert_Input>;
  DefectId?: Maybe<Scalars["uuid"]>;
  User?: Maybe<Ncr_Users_Obj_Rel_Insert_Input>;
  UserId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ncr_Defect_TeamMember_Max_Fields = {
  __typename?: "ncr_Defect_TeamMember_max_fields";
  DefectId?: Maybe<Scalars["uuid"]>;
  UserId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Max_Order_By = {
  DefectId?: Maybe<Order_By>;
  UserId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_Defect_TeamMember_Min_Fields = {
  __typename?: "ncr_Defect_TeamMember_min_fields";
  DefectId?: Maybe<Scalars["uuid"]>;
  UserId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Min_Order_By = {
  DefectId?: Maybe<Order_By>;
  UserId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Mutation_Response = {
  __typename?: "ncr_Defect_TeamMember_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Defect_TeamMember>;
};

/** on conflict condition type for table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_On_Conflict = {
  constraint: Ncr_Defect_TeamMember_Constraint;
  update_columns?: Array<Ncr_Defect_TeamMember_Update_Column>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Defect_TeamMember". */
export type Ncr_Defect_TeamMember_Order_By = {
  Defect?: Maybe<Ncr_Defects_Order_By>;
  DefectId?: Maybe<Order_By>;
  User?: Maybe<Ncr_Users_Order_By>;
  UserId?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_Defect_TeamMember */
export type Ncr_Defect_TeamMember_Pk_Columns_Input = {
  DefectId: Scalars["uuid"];
  UserId: Scalars["uuid"];
};

/** select columns of table "ncr.Defect_TeamMember" */
export enum Ncr_Defect_TeamMember_Select_Column {
  /** column name */
  DefectId = "DefectId",
  /** column name */
  UserId = "UserId"
}

/** input type for updating data in table "ncr.Defect_TeamMember" */
export type Ncr_Defect_TeamMember_Set_Input = {
  DefectId?: Maybe<Scalars["uuid"]>;
  UserId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.Defect_TeamMember" */
export enum Ncr_Defect_TeamMember_Update_Column {
  /** column name */
  DefectId = "DefectId",
  /** column name */
  UserId = "UserId"
}

/** columns and relationships of "ncr.Defects" */
export type Ncr_Defects = {
  __typename?: "ncr_Defects";
  Assignee?: Maybe<Scalars["uuid"]>;
  /** An array relationship */
  Attachment_Defects: Array<Ncr_Attachment_Defect>;
  /** An aggregate relationship */
  Attachment_Defects_aggregate: Ncr_Attachment_Defect_Aggregate;
  /** nr serii produkcyjnej */
  BatchNumber?: Maybe<Scalars["String"]>;
  CloseDate?: Maybe<Scalars["timestamptz"]>;
  /** An array relationship */
  CompanyAffecteds: Array<Ncr_CompanyAffected>;
  /** An aggregate relationship */
  CompanyAffecteds_aggregate: Ncr_CompanyAffected_Aggregate;
  CorrectionDescription?: Maybe<Scalars["String"]>;
  /** An array relationship */
  CorrectiveActions: Array<Ncr_CorrectiveActions>;
  /** An aggregate relationship */
  CorrectiveActions_aggregate: Ncr_CorrectiveActions_Aggregate;
  CreationDate: Scalars["timestamptz"];
  CustomerAcceptance?: Maybe<Scalars["Boolean"]>;
  CustomerCompany?: Maybe<Scalars["uuid"]>;
  CustomerDecisionDescription?: Maybe<Scalars["String"]>;
  /** An array relationship */
  CustomerToInforms: Array<Ncr_CustomerToInform>;
  /** An aggregate relationship */
  CustomerToInforms_aggregate: Ncr_CustomerToInform_Aggregate;
  DefectDescription?: Maybe<Scalars["String"]>;
  /** An array relationship */
  Defect_TeamMembers: Array<Ncr_Defect_TeamMember>;
  /** An aggregate relationship */
  Defect_TeamMembers_aggregate: Ncr_Defect_TeamMember_Aggregate;
  DueDate?: Maybe<Scalars["timetz"]>;
  FirstActivityDueDate?: Maybe<Scalars["timetz"]>;
  Id: Scalars["uuid"];
  IsRepairable: Scalars["Boolean"];
  Leader?: Maybe<Scalars["uuid"]>;
  LotSize?: Maybe<Scalars["Int"]>;
  /** pol. Nr zlecenia produkcyjnego */
  ManufacturingOrderNo?: Maybe<Scalars["String"]>;
  MaterialCost?: Maybe<Scalars["money"]>;
  OtherInfo?: Maybe<Scalars["String"]>;
  /** An array relationship */
  PartAffecteds: Array<Ncr_PartAffected>;
  /** An aggregate relationship */
  PartAffecteds_aggregate: Ncr_PartAffected_Aggregate;
  ProductModel?: Maybe<Scalars["String"]>;
  ProductName?: Maybe<Scalars["String"]>;
  QuantityDelivered?: Maybe<Scalars["Int"]>;
  QuantityRejected: Scalars["Int"];
  Reporter?: Maybe<Scalars["uuid"]>;
  ResponsibleForFirstCorrection?: Maybe<Scalars["uuid"]>;
  Revision: Scalars["Int"];
  ReworkPosibility?: Maybe<Scalars["Boolean"]>;
  RootCause?: Maybe<Scalars["uuid"]>;
  Status: Ncr_Statuses_Enum;
  UpdateDate?: Maybe<Scalars["timestamptz"]>;
  /** An object relationship */
  User?: Maybe<Ncr_Users>;
  WorkHours?: Maybe<Scalars["Float"]>;
  /** An object relationship */
  rootCauseByRootcause?: Maybe<Ncr_RootCauses>;
  /** An object relationship */
  statusByStatus: Ncr_Statuses;
  /** An object relationship */
  userByLeader?: Maybe<Ncr_Users>;
  /** An object relationship */
  userByReporter?: Maybe<Ncr_Users>;
  /** An object relationship */
  userByResponsibleforfirstcorrection?: Maybe<Ncr_Users>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsAttachment_DefectsArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsAttachment_Defects_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsCompanyAffectedsArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsCompanyAffecteds_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsCorrectiveActionsArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsCorrectiveActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsCustomerToInformsArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsCustomerToInforms_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsDefect_TeamMembersArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsDefect_TeamMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsPartAffectedsArgs = {
  distinct_on?: Maybe<Array<Ncr_PartAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_PartAffected_Order_By>>;
  where?: Maybe<Ncr_PartAffected_Bool_Exp>;
};


/** columns and relationships of "ncr.Defects" */
export type Ncr_DefectsPartAffecteds_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_PartAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_PartAffected_Order_By>>;
  where?: Maybe<Ncr_PartAffected_Bool_Exp>;
};

/** aggregated selection of "ncr.Defects" */
export type Ncr_Defects_Aggregate = {
  __typename?: "ncr_Defects_aggregate";
  aggregate?: Maybe<Ncr_Defects_Aggregate_Fields>;
  nodes: Array<Ncr_Defects>;
};

/** aggregate fields of "ncr.Defects" */
export type Ncr_Defects_Aggregate_Fields = {
  __typename?: "ncr_Defects_aggregate_fields";
  avg?: Maybe<Ncr_Defects_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Ncr_Defects_Max_Fields>;
  min?: Maybe<Ncr_Defects_Min_Fields>;
  stddev?: Maybe<Ncr_Defects_Stddev_Fields>;
  stddev_pop?: Maybe<Ncr_Defects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ncr_Defects_Stddev_Samp_Fields>;
  sum?: Maybe<Ncr_Defects_Sum_Fields>;
  var_pop?: Maybe<Ncr_Defects_Var_Pop_Fields>;
  var_samp?: Maybe<Ncr_Defects_Var_Samp_Fields>;
  variance?: Maybe<Ncr_Defects_Variance_Fields>;
};


/** aggregate fields of "ncr.Defects" */
export type Ncr_Defects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Defects_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.Defects" */
export type Ncr_Defects_Aggregate_Order_By = {
  avg?: Maybe<Ncr_Defects_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_Defects_Max_Order_By>;
  min?: Maybe<Ncr_Defects_Min_Order_By>;
  stddev?: Maybe<Ncr_Defects_Stddev_Order_By>;
  stddev_pop?: Maybe<Ncr_Defects_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ncr_Defects_Stddev_Samp_Order_By>;
  sum?: Maybe<Ncr_Defects_Sum_Order_By>;
  var_pop?: Maybe<Ncr_Defects_Var_Pop_Order_By>;
  var_samp?: Maybe<Ncr_Defects_Var_Samp_Order_By>;
  variance?: Maybe<Ncr_Defects_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.Defects" */
export type Ncr_Defects_Arr_Rel_Insert_Input = {
  data: Array<Ncr_Defects_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Defects_On_Conflict>;
};

/** aggregate avg on columns */
export type Ncr_Defects_Avg_Fields = {
  __typename?: "ncr_Defects_avg_fields";
  LotSize?: Maybe<Scalars["Float"]>;
  MaterialCost?: Maybe<Scalars["Float"]>;
  QuantityDelivered?: Maybe<Scalars["Float"]>;
  QuantityRejected?: Maybe<Scalars["Float"]>;
  Revision?: Maybe<Scalars["Float"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ncr.Defects" */
export type Ncr_Defects_Avg_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ncr.Defects". All fields are combined with a logical 'AND'. */
export type Ncr_Defects_Bool_Exp = {
  Assignee?: Maybe<Uuid_Comparison_Exp>;
  Attachment_Defects?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
  BatchNumber?: Maybe<String_Comparison_Exp>;
  CloseDate?: Maybe<Timestamptz_Comparison_Exp>;
  CompanyAffecteds?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
  CorrectionDescription?: Maybe<String_Comparison_Exp>;
  CorrectiveActions?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
  CreationDate?: Maybe<Timestamptz_Comparison_Exp>;
  CustomerAcceptance?: Maybe<Boolean_Comparison_Exp>;
  CustomerCompany?: Maybe<Uuid_Comparison_Exp>;
  CustomerDecisionDescription?: Maybe<String_Comparison_Exp>;
  CustomerToInforms?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
  DefectDescription?: Maybe<String_Comparison_Exp>;
  Defect_TeamMembers?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
  DueDate?: Maybe<Timetz_Comparison_Exp>;
  FirstActivityDueDate?: Maybe<Timetz_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  IsRepairable?: Maybe<Boolean_Comparison_Exp>;
  Leader?: Maybe<Uuid_Comparison_Exp>;
  LotSize?: Maybe<Int_Comparison_Exp>;
  ManufacturingOrderNo?: Maybe<String_Comparison_Exp>;
  MaterialCost?: Maybe<Money_Comparison_Exp>;
  OtherInfo?: Maybe<String_Comparison_Exp>;
  PartAffecteds?: Maybe<Ncr_PartAffected_Bool_Exp>;
  ProductModel?: Maybe<String_Comparison_Exp>;
  ProductName?: Maybe<String_Comparison_Exp>;
  QuantityDelivered?: Maybe<Int_Comparison_Exp>;
  QuantityRejected?: Maybe<Int_Comparison_Exp>;
  Reporter?: Maybe<Uuid_Comparison_Exp>;
  ResponsibleForFirstCorrection?: Maybe<Uuid_Comparison_Exp>;
  Revision?: Maybe<Int_Comparison_Exp>;
  ReworkPosibility?: Maybe<Boolean_Comparison_Exp>;
  RootCause?: Maybe<Uuid_Comparison_Exp>;
  Status?: Maybe<Ncr_Statuses_Enum_Comparison_Exp>;
  UpdateDate?: Maybe<Timestamptz_Comparison_Exp>;
  User?: Maybe<Ncr_Users_Bool_Exp>;
  WorkHours?: Maybe<Float_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Defects_Bool_Exp>>;
  _not?: Maybe<Ncr_Defects_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Defects_Bool_Exp>>;
  rootCauseByRootcause?: Maybe<Ncr_RootCauses_Bool_Exp>;
  statusByStatus?: Maybe<Ncr_Statuses_Bool_Exp>;
  userByLeader?: Maybe<Ncr_Users_Bool_Exp>;
  userByReporter?: Maybe<Ncr_Users_Bool_Exp>;
  userByResponsibleforfirstcorrection?: Maybe<Ncr_Users_Bool_Exp>;
};

/** unique or primary key constraints on table "ncr.Defects" */
export enum Ncr_Defects_Constraint {
  /** unique or primary key constraint */
  DefectPkey = "Defect_pkey"
}

/** input type for incrementing numeric columns in table "ncr.Defects" */
export type Ncr_Defects_Inc_Input = {
  LotSize?: Maybe<Scalars["Int"]>;
  MaterialCost?: Maybe<Scalars["money"]>;
  QuantityDelivered?: Maybe<Scalars["Int"]>;
  QuantityRejected?: Maybe<Scalars["Int"]>;
  Revision?: Maybe<Scalars["Int"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** input type for inserting data into table "ncr.Defects" */
export type Ncr_Defects_Insert_Input = {
  Assignee?: Maybe<Scalars["uuid"]>;
  Attachment_Defects?: Maybe<Ncr_Attachment_Defect_Arr_Rel_Insert_Input>;
  /** nr serii produkcyjnej */
  BatchNumber?: Maybe<Scalars["String"]>;
  CloseDate?: Maybe<Scalars["timestamptz"]>;
  CompanyAffecteds?: Maybe<Ncr_CompanyAffected_Arr_Rel_Insert_Input>;
  CorrectionDescription?: Maybe<Scalars["String"]>;
  CorrectiveActions?: Maybe<Ncr_CorrectiveActions_Arr_Rel_Insert_Input>;
  CreationDate?: Maybe<Scalars["timestamptz"]>;
  CustomerAcceptance?: Maybe<Scalars["Boolean"]>;
  CustomerCompany?: Maybe<Scalars["uuid"]>;
  CustomerDecisionDescription?: Maybe<Scalars["String"]>;
  CustomerToInforms?: Maybe<Ncr_CustomerToInform_Arr_Rel_Insert_Input>;
  DefectDescription?: Maybe<Scalars["String"]>;
  Defect_TeamMembers?: Maybe<Ncr_Defect_TeamMember_Arr_Rel_Insert_Input>;
  DueDate?: Maybe<Scalars["timetz"]>;
  FirstActivityDueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  IsRepairable?: Maybe<Scalars["Boolean"]>;
  Leader?: Maybe<Scalars["uuid"]>;
  LotSize?: Maybe<Scalars["Int"]>;
  /** pol. Nr zlecenia produkcyjnego */
  ManufacturingOrderNo?: Maybe<Scalars["String"]>;
  MaterialCost?: Maybe<Scalars["money"]>;
  OtherInfo?: Maybe<Scalars["String"]>;
  PartAffecteds?: Maybe<Ncr_PartAffected_Arr_Rel_Insert_Input>;
  ProductModel?: Maybe<Scalars["String"]>;
  ProductName?: Maybe<Scalars["String"]>;
  QuantityDelivered?: Maybe<Scalars["Int"]>;
  QuantityRejected?: Maybe<Scalars["Int"]>;
  Reporter?: Maybe<Scalars["uuid"]>;
  ResponsibleForFirstCorrection?: Maybe<Scalars["uuid"]>;
  Revision?: Maybe<Scalars["Int"]>;
  ReworkPosibility?: Maybe<Scalars["Boolean"]>;
  RootCause?: Maybe<Scalars["uuid"]>;
  Status?: Maybe<Ncr_Statuses_Enum>;
  UpdateDate?: Maybe<Scalars["timestamptz"]>;
  User?: Maybe<Ncr_Users_Obj_Rel_Insert_Input>;
  WorkHours?: Maybe<Scalars["Float"]>;
  rootCauseByRootcause?: Maybe<Ncr_RootCauses_Obj_Rel_Insert_Input>;
  statusByStatus?: Maybe<Ncr_Statuses_Obj_Rel_Insert_Input>;
  userByLeader?: Maybe<Ncr_Users_Obj_Rel_Insert_Input>;
  userByReporter?: Maybe<Ncr_Users_Obj_Rel_Insert_Input>;
  userByResponsibleforfirstcorrection?: Maybe<Ncr_Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_Defects_Max_Fields = {
  __typename?: "ncr_Defects_max_fields";
  Assignee?: Maybe<Scalars["uuid"]>;
  /** nr serii produkcyjnej */
  BatchNumber?: Maybe<Scalars["String"]>;
  CloseDate?: Maybe<Scalars["timestamptz"]>;
  CorrectionDescription?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamptz"]>;
  CustomerCompany?: Maybe<Scalars["uuid"]>;
  CustomerDecisionDescription?: Maybe<Scalars["String"]>;
  DefectDescription?: Maybe<Scalars["String"]>;
  DueDate?: Maybe<Scalars["timetz"]>;
  FirstActivityDueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Leader?: Maybe<Scalars["uuid"]>;
  LotSize?: Maybe<Scalars["Int"]>;
  /** pol. Nr zlecenia produkcyjnego */
  ManufacturingOrderNo?: Maybe<Scalars["String"]>;
  MaterialCost?: Maybe<Scalars["money"]>;
  OtherInfo?: Maybe<Scalars["String"]>;
  ProductModel?: Maybe<Scalars["String"]>;
  ProductName?: Maybe<Scalars["String"]>;
  QuantityDelivered?: Maybe<Scalars["Int"]>;
  QuantityRejected?: Maybe<Scalars["Int"]>;
  Reporter?: Maybe<Scalars["uuid"]>;
  ResponsibleForFirstCorrection?: Maybe<Scalars["uuid"]>;
  Revision?: Maybe<Scalars["Int"]>;
  RootCause?: Maybe<Scalars["uuid"]>;
  UpdateDate?: Maybe<Scalars["timestamptz"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by max() on columns of table "ncr.Defects" */
export type Ncr_Defects_Max_Order_By = {
  Assignee?: Maybe<Order_By>;
  /** nr serii produkcyjnej */
  BatchNumber?: Maybe<Order_By>;
  CloseDate?: Maybe<Order_By>;
  CorrectionDescription?: Maybe<Order_By>;
  CreationDate?: Maybe<Order_By>;
  CustomerCompany?: Maybe<Order_By>;
  CustomerDecisionDescription?: Maybe<Order_By>;
  DefectDescription?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  FirstActivityDueDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Leader?: Maybe<Order_By>;
  LotSize?: Maybe<Order_By>;
  /** pol. Nr zlecenia produkcyjnego */
  ManufacturingOrderNo?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  OtherInfo?: Maybe<Order_By>;
  ProductModel?: Maybe<Order_By>;
  ProductName?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Reporter?: Maybe<Order_By>;
  ResponsibleForFirstCorrection?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  UpdateDate?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_Defects_Min_Fields = {
  __typename?: "ncr_Defects_min_fields";
  Assignee?: Maybe<Scalars["uuid"]>;
  /** nr serii produkcyjnej */
  BatchNumber?: Maybe<Scalars["String"]>;
  CloseDate?: Maybe<Scalars["timestamptz"]>;
  CorrectionDescription?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamptz"]>;
  CustomerCompany?: Maybe<Scalars["uuid"]>;
  CustomerDecisionDescription?: Maybe<Scalars["String"]>;
  DefectDescription?: Maybe<Scalars["String"]>;
  DueDate?: Maybe<Scalars["timetz"]>;
  FirstActivityDueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  Leader?: Maybe<Scalars["uuid"]>;
  LotSize?: Maybe<Scalars["Int"]>;
  /** pol. Nr zlecenia produkcyjnego */
  ManufacturingOrderNo?: Maybe<Scalars["String"]>;
  MaterialCost?: Maybe<Scalars["money"]>;
  OtherInfo?: Maybe<Scalars["String"]>;
  ProductModel?: Maybe<Scalars["String"]>;
  ProductName?: Maybe<Scalars["String"]>;
  QuantityDelivered?: Maybe<Scalars["Int"]>;
  QuantityRejected?: Maybe<Scalars["Int"]>;
  Reporter?: Maybe<Scalars["uuid"]>;
  ResponsibleForFirstCorrection?: Maybe<Scalars["uuid"]>;
  Revision?: Maybe<Scalars["Int"]>;
  RootCause?: Maybe<Scalars["uuid"]>;
  UpdateDate?: Maybe<Scalars["timestamptz"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by min() on columns of table "ncr.Defects" */
export type Ncr_Defects_Min_Order_By = {
  Assignee?: Maybe<Order_By>;
  /** nr serii produkcyjnej */
  BatchNumber?: Maybe<Order_By>;
  CloseDate?: Maybe<Order_By>;
  CorrectionDescription?: Maybe<Order_By>;
  CreationDate?: Maybe<Order_By>;
  CustomerCompany?: Maybe<Order_By>;
  CustomerDecisionDescription?: Maybe<Order_By>;
  DefectDescription?: Maybe<Order_By>;
  DueDate?: Maybe<Order_By>;
  FirstActivityDueDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  Leader?: Maybe<Order_By>;
  LotSize?: Maybe<Order_By>;
  /** pol. Nr zlecenia produkcyjnego */
  ManufacturingOrderNo?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  OtherInfo?: Maybe<Order_By>;
  ProductModel?: Maybe<Order_By>;
  ProductName?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Reporter?: Maybe<Order_By>;
  ResponsibleForFirstCorrection?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  UpdateDate?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.Defects" */
export type Ncr_Defects_Mutation_Response = {
  __typename?: "ncr_Defects_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Defects>;
};

/** input type for inserting object relation for remote table "ncr.Defects" */
export type Ncr_Defects_Obj_Rel_Insert_Input = {
  data: Ncr_Defects_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Defects_On_Conflict>;
};

/** on conflict condition type for table "ncr.Defects" */
export type Ncr_Defects_On_Conflict = {
  constraint: Ncr_Defects_Constraint;
  update_columns?: Array<Ncr_Defects_Update_Column>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Defects". */
export type Ncr_Defects_Order_By = {
  Assignee?: Maybe<Order_By>;
  Attachment_Defects_aggregate?: Maybe<Ncr_Attachment_Defect_Aggregate_Order_By>;
  BatchNumber?: Maybe<Order_By>;
  CloseDate?: Maybe<Order_By>;
  CompanyAffecteds_aggregate?: Maybe<Ncr_CompanyAffected_Aggregate_Order_By>;
  CorrectionDescription?: Maybe<Order_By>;
  CorrectiveActions_aggregate?: Maybe<Ncr_CorrectiveActions_Aggregate_Order_By>;
  CreationDate?: Maybe<Order_By>;
  CustomerAcceptance?: Maybe<Order_By>;
  CustomerCompany?: Maybe<Order_By>;
  CustomerDecisionDescription?: Maybe<Order_By>;
  CustomerToInforms_aggregate?: Maybe<Ncr_CustomerToInform_Aggregate_Order_By>;
  DefectDescription?: Maybe<Order_By>;
  Defect_TeamMembers_aggregate?: Maybe<Ncr_Defect_TeamMember_Aggregate_Order_By>;
  DueDate?: Maybe<Order_By>;
  FirstActivityDueDate?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  IsRepairable?: Maybe<Order_By>;
  Leader?: Maybe<Order_By>;
  LotSize?: Maybe<Order_By>;
  ManufacturingOrderNo?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  OtherInfo?: Maybe<Order_By>;
  PartAffecteds_aggregate?: Maybe<Ncr_PartAffected_Aggregate_Order_By>;
  ProductModel?: Maybe<Order_By>;
  ProductName?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Reporter?: Maybe<Order_By>;
  ResponsibleForFirstCorrection?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  ReworkPosibility?: Maybe<Order_By>;
  RootCause?: Maybe<Order_By>;
  Status?: Maybe<Order_By>;
  UpdateDate?: Maybe<Order_By>;
  User?: Maybe<Ncr_Users_Order_By>;
  WorkHours?: Maybe<Order_By>;
  rootCauseByRootcause?: Maybe<Ncr_RootCauses_Order_By>;
  statusByStatus?: Maybe<Ncr_Statuses_Order_By>;
  userByLeader?: Maybe<Ncr_Users_Order_By>;
  userByReporter?: Maybe<Ncr_Users_Order_By>;
  userByResponsibleforfirstcorrection?: Maybe<Ncr_Users_Order_By>;
};

/** primary key columns input for table: ncr_Defects */
export type Ncr_Defects_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.Defects" */
export enum Ncr_Defects_Select_Column {
  /** column name */
  Assignee = "Assignee",
  /** column name */
  BatchNumber = "BatchNumber",
  /** column name */
  CloseDate = "CloseDate",
  /** column name */
  CorrectionDescription = "CorrectionDescription",
  /** column name */
  CreationDate = "CreationDate",
  /** column name */
  CustomerAcceptance = "CustomerAcceptance",
  /** column name */
  CustomerCompany = "CustomerCompany",
  /** column name */
  CustomerDecisionDescription = "CustomerDecisionDescription",
  /** column name */
  DefectDescription = "DefectDescription",
  /** column name */
  DueDate = "DueDate",
  /** column name */
  FirstActivityDueDate = "FirstActivityDueDate",
  /** column name */
  Id = "Id",
  /** column name */
  IsRepairable = "IsRepairable",
  /** column name */
  Leader = "Leader",
  /** column name */
  LotSize = "LotSize",
  /** column name */
  ManufacturingOrderNo = "ManufacturingOrderNo",
  /** column name */
  MaterialCost = "MaterialCost",
  /** column name */
  OtherInfo = "OtherInfo",
  /** column name */
  ProductModel = "ProductModel",
  /** column name */
  ProductName = "ProductName",
  /** column name */
  QuantityDelivered = "QuantityDelivered",
  /** column name */
  QuantityRejected = "QuantityRejected",
  /** column name */
  Reporter = "Reporter",
  /** column name */
  ResponsibleForFirstCorrection = "ResponsibleForFirstCorrection",
  /** column name */
  Revision = "Revision",
  /** column name */
  ReworkPosibility = "ReworkPosibility",
  /** column name */
  RootCause = "RootCause",
  /** column name */
  Status = "Status",
  /** column name */
  UpdateDate = "UpdateDate",
  /** column name */
  WorkHours = "WorkHours"
}

/** input type for updating data in table "ncr.Defects" */
export type Ncr_Defects_Set_Input = {
  Assignee?: Maybe<Scalars["uuid"]>;
  /** nr serii produkcyjnej */
  BatchNumber?: Maybe<Scalars["String"]>;
  CloseDate?: Maybe<Scalars["timestamptz"]>;
  CorrectionDescription?: Maybe<Scalars["String"]>;
  CreationDate?: Maybe<Scalars["timestamptz"]>;
  CustomerAcceptance?: Maybe<Scalars["Boolean"]>;
  CustomerCompany?: Maybe<Scalars["uuid"]>;
  CustomerDecisionDescription?: Maybe<Scalars["String"]>;
  DefectDescription?: Maybe<Scalars["String"]>;
  DueDate?: Maybe<Scalars["timetz"]>;
  FirstActivityDueDate?: Maybe<Scalars["timetz"]>;
  Id?: Maybe<Scalars["uuid"]>;
  IsRepairable?: Maybe<Scalars["Boolean"]>;
  Leader?: Maybe<Scalars["uuid"]>;
  LotSize?: Maybe<Scalars["Int"]>;
  /** pol. Nr zlecenia produkcyjnego */
  ManufacturingOrderNo?: Maybe<Scalars["String"]>;
  MaterialCost?: Maybe<Scalars["money"]>;
  OtherInfo?: Maybe<Scalars["String"]>;
  ProductModel?: Maybe<Scalars["String"]>;
  ProductName?: Maybe<Scalars["String"]>;
  QuantityDelivered?: Maybe<Scalars["Int"]>;
  QuantityRejected?: Maybe<Scalars["Int"]>;
  Reporter?: Maybe<Scalars["uuid"]>;
  ResponsibleForFirstCorrection?: Maybe<Scalars["uuid"]>;
  Revision?: Maybe<Scalars["Int"]>;
  ReworkPosibility?: Maybe<Scalars["Boolean"]>;
  RootCause?: Maybe<Scalars["uuid"]>;
  Status?: Maybe<Ncr_Statuses_Enum>;
  UpdateDate?: Maybe<Scalars["timestamptz"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Ncr_Defects_Stddev_Fields = {
  __typename?: "ncr_Defects_stddev_fields";
  LotSize?: Maybe<Scalars["Float"]>;
  MaterialCost?: Maybe<Scalars["Float"]>;
  QuantityDelivered?: Maybe<Scalars["Float"]>;
  QuantityRejected?: Maybe<Scalars["Float"]>;
  Revision?: Maybe<Scalars["Float"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ncr.Defects" */
export type Ncr_Defects_Stddev_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ncr_Defects_Stddev_Pop_Fields = {
  __typename?: "ncr_Defects_stddev_pop_fields";
  LotSize?: Maybe<Scalars["Float"]>;
  MaterialCost?: Maybe<Scalars["Float"]>;
  QuantityDelivered?: Maybe<Scalars["Float"]>;
  QuantityRejected?: Maybe<Scalars["Float"]>;
  Revision?: Maybe<Scalars["Float"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ncr.Defects" */
export type Ncr_Defects_Stddev_Pop_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ncr_Defects_Stddev_Samp_Fields = {
  __typename?: "ncr_Defects_stddev_samp_fields";
  LotSize?: Maybe<Scalars["Float"]>;
  MaterialCost?: Maybe<Scalars["Float"]>;
  QuantityDelivered?: Maybe<Scalars["Float"]>;
  QuantityRejected?: Maybe<Scalars["Float"]>;
  Revision?: Maybe<Scalars["Float"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ncr.Defects" */
export type Ncr_Defects_Stddev_Samp_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ncr_Defects_Sum_Fields = {
  __typename?: "ncr_Defects_sum_fields";
  LotSize?: Maybe<Scalars["Int"]>;
  MaterialCost?: Maybe<Scalars["money"]>;
  QuantityDelivered?: Maybe<Scalars["Int"]>;
  QuantityRejected?: Maybe<Scalars["Int"]>;
  Revision?: Maybe<Scalars["Int"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by sum() on columns of table "ncr.Defects" */
export type Ncr_Defects_Sum_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** update columns of table "ncr.Defects" */
export enum Ncr_Defects_Update_Column {
  /** column name */
  Assignee = "Assignee",
  /** column name */
  BatchNumber = "BatchNumber",
  /** column name */
  CloseDate = "CloseDate",
  /** column name */
  CorrectionDescription = "CorrectionDescription",
  /** column name */
  CreationDate = "CreationDate",
  /** column name */
  CustomerAcceptance = "CustomerAcceptance",
  /** column name */
  CustomerCompany = "CustomerCompany",
  /** column name */
  CustomerDecisionDescription = "CustomerDecisionDescription",
  /** column name */
  DefectDescription = "DefectDescription",
  /** column name */
  DueDate = "DueDate",
  /** column name */
  FirstActivityDueDate = "FirstActivityDueDate",
  /** column name */
  Id = "Id",
  /** column name */
  IsRepairable = "IsRepairable",
  /** column name */
  Leader = "Leader",
  /** column name */
  LotSize = "LotSize",
  /** column name */
  ManufacturingOrderNo = "ManufacturingOrderNo",
  /** column name */
  MaterialCost = "MaterialCost",
  /** column name */
  OtherInfo = "OtherInfo",
  /** column name */
  ProductModel = "ProductModel",
  /** column name */
  ProductName = "ProductName",
  /** column name */
  QuantityDelivered = "QuantityDelivered",
  /** column name */
  QuantityRejected = "QuantityRejected",
  /** column name */
  Reporter = "Reporter",
  /** column name */
  ResponsibleForFirstCorrection = "ResponsibleForFirstCorrection",
  /** column name */
  Revision = "Revision",
  /** column name */
  ReworkPosibility = "ReworkPosibility",
  /** column name */
  RootCause = "RootCause",
  /** column name */
  Status = "Status",
  /** column name */
  UpdateDate = "UpdateDate",
  /** column name */
  WorkHours = "WorkHours"
}

/** aggregate var_pop on columns */
export type Ncr_Defects_Var_Pop_Fields = {
  __typename?: "ncr_Defects_var_pop_fields";
  LotSize?: Maybe<Scalars["Float"]>;
  MaterialCost?: Maybe<Scalars["Float"]>;
  QuantityDelivered?: Maybe<Scalars["Float"]>;
  QuantityRejected?: Maybe<Scalars["Float"]>;
  Revision?: Maybe<Scalars["Float"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ncr.Defects" */
export type Ncr_Defects_Var_Pop_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ncr_Defects_Var_Samp_Fields = {
  __typename?: "ncr_Defects_var_samp_fields";
  LotSize?: Maybe<Scalars["Float"]>;
  MaterialCost?: Maybe<Scalars["Float"]>;
  QuantityDelivered?: Maybe<Scalars["Float"]>;
  QuantityRejected?: Maybe<Scalars["Float"]>;
  Revision?: Maybe<Scalars["Float"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ncr.Defects" */
export type Ncr_Defects_Var_Samp_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ncr_Defects_Variance_Fields = {
  __typename?: "ncr_Defects_variance_fields";
  LotSize?: Maybe<Scalars["Float"]>;
  MaterialCost?: Maybe<Scalars["Float"]>;
  QuantityDelivered?: Maybe<Scalars["Float"]>;
  QuantityRejected?: Maybe<Scalars["Float"]>;
  Revision?: Maybe<Scalars["Float"]>;
  WorkHours?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ncr.Defects" */
export type Ncr_Defects_Variance_Order_By = {
  LotSize?: Maybe<Order_By>;
  MaterialCost?: Maybe<Order_By>;
  QuantityDelivered?: Maybe<Order_By>;
  QuantityRejected?: Maybe<Order_By>;
  Revision?: Maybe<Order_By>;
  WorkHours?: Maybe<Order_By>;
};

/** columns and relationships of "ncr.Feature_Defect" */
export type Ncr_Feature_Defect = {
  __typename?: "ncr_Feature_Defect";
  DefectId: Scalars["uuid"];
  /** An object relationship */
  Feature: Ncr_Features;
  FeatureId: Scalars["uuid"];
};

/** aggregated selection of "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Aggregate = {
  __typename?: "ncr_Feature_Defect_aggregate";
  aggregate?: Maybe<Ncr_Feature_Defect_Aggregate_Fields>;
  nodes: Array<Ncr_Feature_Defect>;
};

/** aggregate fields of "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Aggregate_Fields = {
  __typename?: "ncr_Feature_Defect_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Feature_Defect_Max_Fields>;
  min?: Maybe<Ncr_Feature_Defect_Min_Fields>;
};


/** aggregate fields of "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Feature_Defect_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_Feature_Defect_Max_Order_By>;
  min?: Maybe<Ncr_Feature_Defect_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Arr_Rel_Insert_Input = {
  data: Array<Ncr_Feature_Defect_Insert_Input>;
};

/** Boolean expression to filter rows from the table "ncr.Feature_Defect". All fields are combined with a logical 'AND'. */
export type Ncr_Feature_Defect_Bool_Exp = {
  DefectId?: Maybe<Uuid_Comparison_Exp>;
  Feature?: Maybe<Ncr_Features_Bool_Exp>;
  FeatureId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Feature_Defect_Bool_Exp>>;
  _not?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Feature_Defect_Bool_Exp>>;
};

/** input type for inserting data into table "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Insert_Input = {
  DefectId?: Maybe<Scalars["uuid"]>;
  Feature?: Maybe<Ncr_Features_Obj_Rel_Insert_Input>;
  FeatureId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ncr_Feature_Defect_Max_Fields = {
  __typename?: "ncr_Feature_Defect_max_fields";
  DefectId?: Maybe<Scalars["uuid"]>;
  FeatureId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Max_Order_By = {
  DefectId?: Maybe<Order_By>;
  FeatureId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_Feature_Defect_Min_Fields = {
  __typename?: "ncr_Feature_Defect_min_fields";
  DefectId?: Maybe<Scalars["uuid"]>;
  FeatureId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Min_Order_By = {
  DefectId?: Maybe<Order_By>;
  FeatureId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Mutation_Response = {
  __typename?: "ncr_Feature_Defect_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Feature_Defect>;
};

/** Ordering options when selecting data from "ncr.Feature_Defect". */
export type Ncr_Feature_Defect_Order_By = {
  DefectId?: Maybe<Order_By>;
  Feature?: Maybe<Ncr_Features_Order_By>;
  FeatureId?: Maybe<Order_By>;
};

/** select columns of table "ncr.Feature_Defect" */
export enum Ncr_Feature_Defect_Select_Column {
  /** column name */
  DefectId = "DefectId",
  /** column name */
  FeatureId = "FeatureId"
}

/** input type for updating data in table "ncr.Feature_Defect" */
export type Ncr_Feature_Defect_Set_Input = {
  DefectId?: Maybe<Scalars["uuid"]>;
  FeatureId?: Maybe<Scalars["uuid"]>;
};

/** columns and relationships of "ncr.Features" */
export type Ncr_Features = {
  __typename?: "ncr_Features";
  /** An array relationship */
  Feature_Defects: Array<Ncr_Feature_Defect>;
  /** An aggregate relationship */
  Feature_Defects_aggregate: Ncr_Feature_Defect_Aggregate;
  Id: Scalars["uuid"];
  Name: Scalars["String"];
  Unit: Scalars["Int"];
  /** An object relationship */
  unitByUnit: Ncr_Units;
};


/** columns and relationships of "ncr.Features" */
export type Ncr_FeaturesFeature_DefectsArgs = {
  distinct_on?: Maybe<Array<Ncr_Feature_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Feature_Defect_Order_By>>;
  where?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
};


/** columns and relationships of "ncr.Features" */
export type Ncr_FeaturesFeature_Defects_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Feature_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Feature_Defect_Order_By>>;
  where?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
};

/** aggregated selection of "ncr.Features" */
export type Ncr_Features_Aggregate = {
  __typename?: "ncr_Features_aggregate";
  aggregate?: Maybe<Ncr_Features_Aggregate_Fields>;
  nodes: Array<Ncr_Features>;
};

/** aggregate fields of "ncr.Features" */
export type Ncr_Features_Aggregate_Fields = {
  __typename?: "ncr_Features_aggregate_fields";
  avg?: Maybe<Ncr_Features_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Ncr_Features_Max_Fields>;
  min?: Maybe<Ncr_Features_Min_Fields>;
  stddev?: Maybe<Ncr_Features_Stddev_Fields>;
  stddev_pop?: Maybe<Ncr_Features_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ncr_Features_Stddev_Samp_Fields>;
  sum?: Maybe<Ncr_Features_Sum_Fields>;
  var_pop?: Maybe<Ncr_Features_Var_Pop_Fields>;
  var_samp?: Maybe<Ncr_Features_Var_Samp_Fields>;
  variance?: Maybe<Ncr_Features_Variance_Fields>;
};


/** aggregate fields of "ncr.Features" */
export type Ncr_Features_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Features_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.Features" */
export type Ncr_Features_Aggregate_Order_By = {
  avg?: Maybe<Ncr_Features_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_Features_Max_Order_By>;
  min?: Maybe<Ncr_Features_Min_Order_By>;
  stddev?: Maybe<Ncr_Features_Stddev_Order_By>;
  stddev_pop?: Maybe<Ncr_Features_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Ncr_Features_Stddev_Samp_Order_By>;
  sum?: Maybe<Ncr_Features_Sum_Order_By>;
  var_pop?: Maybe<Ncr_Features_Var_Pop_Order_By>;
  var_samp?: Maybe<Ncr_Features_Var_Samp_Order_By>;
  variance?: Maybe<Ncr_Features_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.Features" */
export type Ncr_Features_Arr_Rel_Insert_Input = {
  data: Array<Ncr_Features_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Features_On_Conflict>;
};

/** aggregate avg on columns */
export type Ncr_Features_Avg_Fields = {
  __typename?: "ncr_Features_avg_fields";
  Unit?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "ncr.Features" */
export type Ncr_Features_Avg_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ncr.Features". All fields are combined with a logical 'AND'. */
export type Ncr_Features_Bool_Exp = {
  Feature_Defects?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  Unit?: Maybe<Int_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Features_Bool_Exp>>;
  _not?: Maybe<Ncr_Features_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Features_Bool_Exp>>;
  unitByUnit?: Maybe<Ncr_Units_Bool_Exp>;
};

/** unique or primary key constraints on table "ncr.Features" */
export enum Ncr_Features_Constraint {
  /** unique or primary key constraint */
  FeaturePkey = "Feature_pkey"
}

/** input type for incrementing numeric columns in table "ncr.Features" */
export type Ncr_Features_Inc_Input = {
  Unit?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "ncr.Features" */
export type Ncr_Features_Insert_Input = {
  Feature_Defects?: Maybe<Ncr_Feature_Defect_Arr_Rel_Insert_Input>;
  Id?: Maybe<Scalars["uuid"]>;
  Name?: Maybe<Scalars["String"]>;
  Unit?: Maybe<Scalars["Int"]>;
  unitByUnit?: Maybe<Ncr_Units_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_Features_Max_Fields = {
  __typename?: "ncr_Features_max_fields";
  Id?: Maybe<Scalars["uuid"]>;
  Name?: Maybe<Scalars["String"]>;
  Unit?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "ncr.Features" */
export type Ncr_Features_Max_Order_By = {
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Unit?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_Features_Min_Fields = {
  __typename?: "ncr_Features_min_fields";
  Id?: Maybe<Scalars["uuid"]>;
  Name?: Maybe<Scalars["String"]>;
  Unit?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "ncr.Features" */
export type Ncr_Features_Min_Order_By = {
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Unit?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.Features" */
export type Ncr_Features_Mutation_Response = {
  __typename?: "ncr_Features_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Features>;
};

/** input type for inserting object relation for remote table "ncr.Features" */
export type Ncr_Features_Obj_Rel_Insert_Input = {
  data: Ncr_Features_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Features_On_Conflict>;
};

/** on conflict condition type for table "ncr.Features" */
export type Ncr_Features_On_Conflict = {
  constraint: Ncr_Features_Constraint;
  update_columns?: Array<Ncr_Features_Update_Column>;
  where?: Maybe<Ncr_Features_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Features". */
export type Ncr_Features_Order_By = {
  Feature_Defects_aggregate?: Maybe<Ncr_Feature_Defect_Aggregate_Order_By>;
  Id?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Unit?: Maybe<Order_By>;
  unitByUnit?: Maybe<Ncr_Units_Order_By>;
};

/** primary key columns input for table: ncr_Features */
export type Ncr_Features_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.Features" */
export enum Ncr_Features_Select_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Name = "Name",
  /** column name */
  Unit = "Unit"
}

/** input type for updating data in table "ncr.Features" */
export type Ncr_Features_Set_Input = {
  Id?: Maybe<Scalars["uuid"]>;
  Name?: Maybe<Scalars["String"]>;
  Unit?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Ncr_Features_Stddev_Fields = {
  __typename?: "ncr_Features_stddev_fields";
  Unit?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "ncr.Features" */
export type Ncr_Features_Stddev_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ncr_Features_Stddev_Pop_Fields = {
  __typename?: "ncr_Features_stddev_pop_fields";
  Unit?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "ncr.Features" */
export type Ncr_Features_Stddev_Pop_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ncr_Features_Stddev_Samp_Fields = {
  __typename?: "ncr_Features_stddev_samp_fields";
  Unit?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "ncr.Features" */
export type Ncr_Features_Stddev_Samp_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Ncr_Features_Sum_Fields = {
  __typename?: "ncr_Features_sum_fields";
  Unit?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "ncr.Features" */
export type Ncr_Features_Sum_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** update columns of table "ncr.Features" */
export enum Ncr_Features_Update_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Name = "Name",
  /** column name */
  Unit = "Unit"
}

/** aggregate var_pop on columns */
export type Ncr_Features_Var_Pop_Fields = {
  __typename?: "ncr_Features_var_pop_fields";
  Unit?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "ncr.Features" */
export type Ncr_Features_Var_Pop_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ncr_Features_Var_Samp_Fields = {
  __typename?: "ncr_Features_var_samp_fields";
  Unit?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "ncr.Features" */
export type Ncr_Features_Var_Samp_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Ncr_Features_Variance_Fields = {
  __typename?: "ncr_Features_variance_fields";
  Unit?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "ncr.Features" */
export type Ncr_Features_Variance_Order_By = {
  Unit?: Maybe<Order_By>;
};

/** columns and relationships of "ncr.PartAffected" */
export type Ncr_PartAffected = {
  __typename?: "ncr_PartAffected";
  /** An object relationship */
  Defect: Ncr_Defects;
  DefectId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};

/** aggregated selection of "ncr.PartAffected" */
export type Ncr_PartAffected_Aggregate = {
  __typename?: "ncr_PartAffected_aggregate";
  aggregate?: Maybe<Ncr_PartAffected_Aggregate_Fields>;
  nodes: Array<Ncr_PartAffected>;
};

/** aggregate fields of "ncr.PartAffected" */
export type Ncr_PartAffected_Aggregate_Fields = {
  __typename?: "ncr_PartAffected_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_PartAffected_Max_Fields>;
  min?: Maybe<Ncr_PartAffected_Min_Fields>;
};


/** aggregate fields of "ncr.PartAffected" */
export type Ncr_PartAffected_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_PartAffected_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.PartAffected" */
export type Ncr_PartAffected_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_PartAffected_Max_Order_By>;
  min?: Maybe<Ncr_PartAffected_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.PartAffected" */
export type Ncr_PartAffected_Arr_Rel_Insert_Input = {
  data: Array<Ncr_PartAffected_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_PartAffected_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.PartAffected". All fields are combined with a logical 'AND'. */
export type Ncr_PartAffected_Bool_Exp = {
  Defect?: Maybe<Ncr_Defects_Bool_Exp>;
  DefectId?: Maybe<Uuid_Comparison_Exp>;
  ProductId?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_PartAffected_Bool_Exp>>;
  _not?: Maybe<Ncr_PartAffected_Bool_Exp>;
  _or?: Maybe<Array<Ncr_PartAffected_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.PartAffected" */
export enum Ncr_PartAffected_Constraint {
  /** unique or primary key constraint */
  PartAffectedPkey = "PartAffected_pkey"
}

/** input type for inserting data into table "ncr.PartAffected" */
export type Ncr_PartAffected_Insert_Input = {
  Defect?: Maybe<Ncr_Defects_Obj_Rel_Insert_Input>;
  DefectId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ncr_PartAffected_Max_Fields = {
  __typename?: "ncr_PartAffected_max_fields";
  DefectId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "ncr.PartAffected" */
export type Ncr_PartAffected_Max_Order_By = {
  DefectId?: Maybe<Order_By>;
  ProductId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_PartAffected_Min_Fields = {
  __typename?: "ncr_PartAffected_min_fields";
  DefectId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "ncr.PartAffected" */
export type Ncr_PartAffected_Min_Order_By = {
  DefectId?: Maybe<Order_By>;
  ProductId?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.PartAffected" */
export type Ncr_PartAffected_Mutation_Response = {
  __typename?: "ncr_PartAffected_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_PartAffected>;
};

/** on conflict condition type for table "ncr.PartAffected" */
export type Ncr_PartAffected_On_Conflict = {
  constraint: Ncr_PartAffected_Constraint;
  update_columns?: Array<Ncr_PartAffected_Update_Column>;
  where?: Maybe<Ncr_PartAffected_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.PartAffected". */
export type Ncr_PartAffected_Order_By = {
  Defect?: Maybe<Ncr_Defects_Order_By>;
  DefectId?: Maybe<Order_By>;
  ProductId?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_PartAffected */
export type Ncr_PartAffected_Pk_Columns_Input = {
  DefectId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};

/** select columns of table "ncr.PartAffected" */
export enum Ncr_PartAffected_Select_Column {
  /** column name */
  DefectId = "DefectId",
  /** column name */
  ProductId = "ProductId"
}

/** input type for updating data in table "ncr.PartAffected" */
export type Ncr_PartAffected_Set_Input = {
  DefectId?: Maybe<Scalars["uuid"]>;
  ProductId?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.PartAffected" */
export enum Ncr_PartAffected_Update_Column {
  /** column name */
  DefectId = "DefectId",
  /** column name */
  ProductId = "ProductId"
}

/** columns and relationships of "ncr.RootCauses" */
export type Ncr_RootCauses = {
  __typename?: "ncr_RootCauses";
  /** An array relationship */
  Defects: Array<Ncr_Defects>;
  /** An aggregate relationship */
  Defects_aggregate: Ncr_Defects_Aggregate;
  Description: Scalars["String"];
  Id: Scalars["uuid"];
};


/** columns and relationships of "ncr.RootCauses" */
export type Ncr_RootCausesDefectsArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.RootCauses" */
export type Ncr_RootCausesDefects_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};

/** aggregated selection of "ncr.RootCauses" */
export type Ncr_RootCauses_Aggregate = {
  __typename?: "ncr_RootCauses_aggregate";
  aggregate?: Maybe<Ncr_RootCauses_Aggregate_Fields>;
  nodes: Array<Ncr_RootCauses>;
};

/** aggregate fields of "ncr.RootCauses" */
export type Ncr_RootCauses_Aggregate_Fields = {
  __typename?: "ncr_RootCauses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_RootCauses_Max_Fields>;
  min?: Maybe<Ncr_RootCauses_Min_Fields>;
};


/** aggregate fields of "ncr.RootCauses" */
export type Ncr_RootCauses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_RootCauses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "ncr.RootCauses". All fields are combined with a logical 'AND'. */
export type Ncr_RootCauses_Bool_Exp = {
  Defects?: Maybe<Ncr_Defects_Bool_Exp>;
  Description?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_RootCauses_Bool_Exp>>;
  _not?: Maybe<Ncr_RootCauses_Bool_Exp>;
  _or?: Maybe<Array<Ncr_RootCauses_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.RootCauses" */
export enum Ncr_RootCauses_Constraint {
  /** unique or primary key constraint */
  RootCausePkey = "RootCause_pkey"
}

/** input type for inserting data into table "ncr.RootCauses" */
export type Ncr_RootCauses_Insert_Input = {
  Defects?: Maybe<Ncr_Defects_Arr_Rel_Insert_Input>;
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Ncr_RootCauses_Max_Fields = {
  __typename?: "ncr_RootCauses_max_fields";
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
};

/** aggregate min on columns */
export type Ncr_RootCauses_Min_Fields = {
  __typename?: "ncr_RootCauses_min_fields";
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
};

/** response of any mutation on the table "ncr.RootCauses" */
export type Ncr_RootCauses_Mutation_Response = {
  __typename?: "ncr_RootCauses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_RootCauses>;
};

/** input type for inserting object relation for remote table "ncr.RootCauses" */
export type Ncr_RootCauses_Obj_Rel_Insert_Input = {
  data: Ncr_RootCauses_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_RootCauses_On_Conflict>;
};

/** on conflict condition type for table "ncr.RootCauses" */
export type Ncr_RootCauses_On_Conflict = {
  constraint: Ncr_RootCauses_Constraint;
  update_columns?: Array<Ncr_RootCauses_Update_Column>;
  where?: Maybe<Ncr_RootCauses_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.RootCauses". */
export type Ncr_RootCauses_Order_By = {
  Defects_aggregate?: Maybe<Ncr_Defects_Aggregate_Order_By>;
  Description?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_RootCauses */
export type Ncr_RootCauses_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.RootCauses" */
export enum Ncr_RootCauses_Select_Column {
  /** column name */
  Description = "Description",
  /** column name */
  Id = "Id"
}

/** input type for updating data in table "ncr.RootCauses" */
export type Ncr_RootCauses_Set_Input = {
  Description?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
};

/** update columns of table "ncr.RootCauses" */
export enum Ncr_RootCauses_Update_Column {
  /** column name */
  Description = "Description",
  /** column name */
  Id = "Id"
}

/** columns and relationships of "ncr.Statuses" */
export type Ncr_Statuses = {
  __typename?: "ncr_Statuses";
  Status: Scalars["String"];
};

/** aggregated selection of "ncr.Statuses" */
export type Ncr_Statuses_Aggregate = {
  __typename?: "ncr_Statuses_aggregate";
  aggregate?: Maybe<Ncr_Statuses_Aggregate_Fields>;
  nodes: Array<Ncr_Statuses>;
};

/** aggregate fields of "ncr.Statuses" */
export type Ncr_Statuses_Aggregate_Fields = {
  __typename?: "ncr_Statuses_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Statuses_Max_Fields>;
  min?: Maybe<Ncr_Statuses_Min_Fields>;
};


/** aggregate fields of "ncr.Statuses" */
export type Ncr_Statuses_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Statuses_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "ncr.Statuses". All fields are combined with a logical 'AND'. */
export type Ncr_Statuses_Bool_Exp = {
  Status?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Statuses_Bool_Exp>>;
  _not?: Maybe<Ncr_Statuses_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Statuses_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Statuses" */
export enum Ncr_Statuses_Constraint {
  /** unique or primary key constraint */
  StatusesStatusKey = "Statuses_Status_key",
  /** unique or primary key constraint */
  NcrStatusPkey = "ncrStatus_pkey"
}

export enum Ncr_Statuses_Enum {
  New = "New"
}

/** Boolean expression to compare columns of type "ncr_Statuses_enum". All fields are combined with logical 'AND'. */
export type Ncr_Statuses_Enum_Comparison_Exp = {
  _eq?: Maybe<Ncr_Statuses_Enum>;
  _in?: Maybe<Array<Ncr_Statuses_Enum>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Ncr_Statuses_Enum>;
  _nin?: Maybe<Array<Ncr_Statuses_Enum>>;
};

/** input type for inserting data into table "ncr.Statuses" */
export type Ncr_Statuses_Insert_Input = {
  Status?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ncr_Statuses_Max_Fields = {
  __typename?: "ncr_Statuses_max_fields";
  Status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Ncr_Statuses_Min_Fields = {
  __typename?: "ncr_Statuses_min_fields";
  Status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ncr.Statuses" */
export type Ncr_Statuses_Mutation_Response = {
  __typename?: "ncr_Statuses_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Statuses>;
};

/** input type for inserting object relation for remote table "ncr.Statuses" */
export type Ncr_Statuses_Obj_Rel_Insert_Input = {
  data: Ncr_Statuses_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Statuses_On_Conflict>;
};

/** on conflict condition type for table "ncr.Statuses" */
export type Ncr_Statuses_On_Conflict = {
  constraint: Ncr_Statuses_Constraint;
  update_columns?: Array<Ncr_Statuses_Update_Column>;
  where?: Maybe<Ncr_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Statuses". */
export type Ncr_Statuses_Order_By = {
  Status?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_Statuses */
export type Ncr_Statuses_Pk_Columns_Input = {
  Status: Scalars["String"];
};

/** select columns of table "ncr.Statuses" */
export enum Ncr_Statuses_Select_Column {
  /** column name */
  Status = "Status"
}

/** input type for updating data in table "ncr.Statuses" */
export type Ncr_Statuses_Set_Input = {
  Status?: Maybe<Scalars["String"]>;
};

/** update columns of table "ncr.Statuses" */
export enum Ncr_Statuses_Update_Column {
  /** column name */
  Status = "Status"
}

/** columns and relationships of "ncr.ThirdParties" */
export type Ncr_ThirdParties = {
  __typename?: "ncr_ThirdParties";
  /** An object relationship */
  Address: Ncr_Addresses;
  AddressId: Scalars["uuid"];
  /** An array relationship */
  CompanyAffecteds: Array<Ncr_CompanyAffected>;
  /** An aggregate relationship */
  CompanyAffecteds_aggregate: Ncr_CompanyAffected_Aggregate;
  CompanyName: Scalars["String"];
  /** An array relationship */
  CustomerToInforms: Array<Ncr_CustomerToInform>;
  /** An aggregate relationship */
  CustomerToInforms_aggregate: Ncr_CustomerToInform_Aggregate;
  Email?: Maybe<Scalars["String"]>;
  Id: Scalars["uuid"];
  PhoneNumber?: Maybe<Scalars["String"]>;
  Retail: Scalars["Boolean"];
};


/** columns and relationships of "ncr.ThirdParties" */
export type Ncr_ThirdPartiesCompanyAffectedsArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


/** columns and relationships of "ncr.ThirdParties" */
export type Ncr_ThirdPartiesCompanyAffecteds_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


/** columns and relationships of "ncr.ThirdParties" */
export type Ncr_ThirdPartiesCustomerToInformsArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};


/** columns and relationships of "ncr.ThirdParties" */
export type Ncr_ThirdPartiesCustomerToInforms_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};

/** aggregated selection of "ncr.ThirdParties" */
export type Ncr_ThirdParties_Aggregate = {
  __typename?: "ncr_ThirdParties_aggregate";
  aggregate?: Maybe<Ncr_ThirdParties_Aggregate_Fields>;
  nodes: Array<Ncr_ThirdParties>;
};

/** aggregate fields of "ncr.ThirdParties" */
export type Ncr_ThirdParties_Aggregate_Fields = {
  __typename?: "ncr_ThirdParties_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_ThirdParties_Max_Fields>;
  min?: Maybe<Ncr_ThirdParties_Min_Fields>;
};


/** aggregate fields of "ncr.ThirdParties" */
export type Ncr_ThirdParties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_ThirdParties_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Ncr_ThirdParties_Max_Order_By>;
  min?: Maybe<Ncr_ThirdParties_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Arr_Rel_Insert_Input = {
  data: Array<Ncr_ThirdParties_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_ThirdParties_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ncr.ThirdParties". All fields are combined with a logical 'AND'. */
export type Ncr_ThirdParties_Bool_Exp = {
  Address?: Maybe<Ncr_Addresses_Bool_Exp>;
  AddressId?: Maybe<Uuid_Comparison_Exp>;
  CompanyAffecteds?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
  CompanyName?: Maybe<String_Comparison_Exp>;
  CustomerToInforms?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
  Email?: Maybe<String_Comparison_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  PhoneNumber?: Maybe<String_Comparison_Exp>;
  Retail?: Maybe<Boolean_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_ThirdParties_Bool_Exp>>;
  _not?: Maybe<Ncr_ThirdParties_Bool_Exp>;
  _or?: Maybe<Array<Ncr_ThirdParties_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.ThirdParties" */
export enum Ncr_ThirdParties_Constraint {
  /** unique or primary key constraint */
  ThirdPartyPkey = "ThirdParty_pkey"
}

/** input type for inserting data into table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Insert_Input = {
  Address?: Maybe<Ncr_Addresses_Obj_Rel_Insert_Input>;
  AddressId?: Maybe<Scalars["uuid"]>;
  CompanyAffecteds?: Maybe<Ncr_CompanyAffected_Arr_Rel_Insert_Input>;
  CompanyName?: Maybe<Scalars["String"]>;
  CustomerToInforms?: Maybe<Ncr_CustomerToInform_Arr_Rel_Insert_Input>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PhoneNumber?: Maybe<Scalars["String"]>;
  Retail?: Maybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type Ncr_ThirdParties_Max_Fields = {
  __typename?: "ncr_ThirdParties_max_fields";
  AddressId?: Maybe<Scalars["uuid"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PhoneNumber?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Max_Order_By = {
  AddressId?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  PhoneNumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Ncr_ThirdParties_Min_Fields = {
  __typename?: "ncr_ThirdParties_min_fields";
  AddressId?: Maybe<Scalars["uuid"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PhoneNumber?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Min_Order_By = {
  AddressId?: Maybe<Order_By>;
  CompanyName?: Maybe<Order_By>;
  Email?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  PhoneNumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Mutation_Response = {
  __typename?: "ncr_ThirdParties_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_ThirdParties>;
};

/** input type for inserting object relation for remote table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Obj_Rel_Insert_Input = {
  data: Ncr_ThirdParties_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_ThirdParties_On_Conflict>;
};

/** on conflict condition type for table "ncr.ThirdParties" */
export type Ncr_ThirdParties_On_Conflict = {
  constraint: Ncr_ThirdParties_Constraint;
  update_columns?: Array<Ncr_ThirdParties_Update_Column>;
  where?: Maybe<Ncr_ThirdParties_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.ThirdParties". */
export type Ncr_ThirdParties_Order_By = {
  Address?: Maybe<Ncr_Addresses_Order_By>;
  AddressId?: Maybe<Order_By>;
  CompanyAffecteds_aggregate?: Maybe<Ncr_CompanyAffected_Aggregate_Order_By>;
  CompanyName?: Maybe<Order_By>;
  CustomerToInforms_aggregate?: Maybe<Ncr_CustomerToInform_Aggregate_Order_By>;
  Email?: Maybe<Order_By>;
  Id?: Maybe<Order_By>;
  PhoneNumber?: Maybe<Order_By>;
  Retail?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_ThirdParties */
export type Ncr_ThirdParties_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.ThirdParties" */
export enum Ncr_ThirdParties_Select_Column {
  /** column name */
  AddressId = "AddressId",
  /** column name */
  CompanyName = "CompanyName",
  /** column name */
  Email = "Email",
  /** column name */
  Id = "Id",
  /** column name */
  PhoneNumber = "PhoneNumber",
  /** column name */
  Retail = "Retail"
}

/** input type for updating data in table "ncr.ThirdParties" */
export type Ncr_ThirdParties_Set_Input = {
  AddressId?: Maybe<Scalars["uuid"]>;
  CompanyName?: Maybe<Scalars["String"]>;
  Email?: Maybe<Scalars["String"]>;
  Id?: Maybe<Scalars["uuid"]>;
  PhoneNumber?: Maybe<Scalars["String"]>;
  Retail?: Maybe<Scalars["Boolean"]>;
};

/** update columns of table "ncr.ThirdParties" */
export enum Ncr_ThirdParties_Update_Column {
  /** column name */
  AddressId = "AddressId",
  /** column name */
  CompanyName = "CompanyName",
  /** column name */
  Email = "Email",
  /** column name */
  Id = "Id",
  /** column name */
  PhoneNumber = "PhoneNumber",
  /** column name */
  Retail = "Retail"
}

/** columns and relationships of "ncr.Units" */
export type Ncr_Units = {
  __typename?: "ncr_Units";
  /** An array relationship */
  Features: Array<Ncr_Features>;
  /** An aggregate relationship */
  Features_aggregate: Ncr_Features_Aggregate;
  Id: Scalars["Int"];
  Unit: Scalars["String"];
};


/** columns and relationships of "ncr.Units" */
export type Ncr_UnitsFeaturesArgs = {
  distinct_on?: Maybe<Array<Ncr_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Features_Order_By>>;
  where?: Maybe<Ncr_Features_Bool_Exp>;
};


/** columns and relationships of "ncr.Units" */
export type Ncr_UnitsFeatures_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Features_Order_By>>;
  where?: Maybe<Ncr_Features_Bool_Exp>;
};

/** aggregated selection of "ncr.Units" */
export type Ncr_Units_Aggregate = {
  __typename?: "ncr_Units_aggregate";
  aggregate?: Maybe<Ncr_Units_Aggregate_Fields>;
  nodes: Array<Ncr_Units>;
};

/** aggregate fields of "ncr.Units" */
export type Ncr_Units_Aggregate_Fields = {
  __typename?: "ncr_Units_aggregate_fields";
  avg?: Maybe<Ncr_Units_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Ncr_Units_Max_Fields>;
  min?: Maybe<Ncr_Units_Min_Fields>;
  stddev?: Maybe<Ncr_Units_Stddev_Fields>;
  stddev_pop?: Maybe<Ncr_Units_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ncr_Units_Stddev_Samp_Fields>;
  sum?: Maybe<Ncr_Units_Sum_Fields>;
  var_pop?: Maybe<Ncr_Units_Var_Pop_Fields>;
  var_samp?: Maybe<Ncr_Units_Var_Samp_Fields>;
  variance?: Maybe<Ncr_Units_Variance_Fields>;
};


/** aggregate fields of "ncr.Units" */
export type Ncr_Units_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Units_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Ncr_Units_Avg_Fields = {
  __typename?: "ncr_Units_avg_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "ncr.Units". All fields are combined with a logical 'AND'. */
export type Ncr_Units_Bool_Exp = {
  Features?: Maybe<Ncr_Features_Bool_Exp>;
  Id?: Maybe<Int_Comparison_Exp>;
  Unit?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Units_Bool_Exp>>;
  _not?: Maybe<Ncr_Units_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Units_Bool_Exp>>;
};

/** unique or primary key constraints on table "ncr.Units" */
export enum Ncr_Units_Constraint {
  /** unique or primary key constraint */
  UnitUnitKey = "Unit_Unit_key",
  /** unique or primary key constraint */
  UnitPkey = "Unit_pkey"
}

/** input type for incrementing numeric columns in table "ncr.Units" */
export type Ncr_Units_Inc_Input = {
  Id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "ncr.Units" */
export type Ncr_Units_Insert_Input = {
  Features?: Maybe<Ncr_Features_Arr_Rel_Insert_Input>;
  Id?: Maybe<Scalars["Int"]>;
  Unit?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Ncr_Units_Max_Fields = {
  __typename?: "ncr_Units_max_fields";
  Id?: Maybe<Scalars["Int"]>;
  Unit?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Ncr_Units_Min_Fields = {
  __typename?: "ncr_Units_min_fields";
  Id?: Maybe<Scalars["Int"]>;
  Unit?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ncr.Units" */
export type Ncr_Units_Mutation_Response = {
  __typename?: "ncr_Units_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Units>;
};

/** input type for inserting object relation for remote table "ncr.Units" */
export type Ncr_Units_Obj_Rel_Insert_Input = {
  data: Ncr_Units_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Units_On_Conflict>;
};

/** on conflict condition type for table "ncr.Units" */
export type Ncr_Units_On_Conflict = {
  constraint: Ncr_Units_Constraint;
  update_columns?: Array<Ncr_Units_Update_Column>;
  where?: Maybe<Ncr_Units_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Units". */
export type Ncr_Units_Order_By = {
  Features_aggregate?: Maybe<Ncr_Features_Aggregate_Order_By>;
  Id?: Maybe<Order_By>;
  Unit?: Maybe<Order_By>;
};

/** primary key columns input for table: ncr_Units */
export type Ncr_Units_Pk_Columns_Input = {
  Id: Scalars["Int"];
};

/** select columns of table "ncr.Units" */
export enum Ncr_Units_Select_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Unit = "Unit"
}

/** input type for updating data in table "ncr.Units" */
export type Ncr_Units_Set_Input = {
  Id?: Maybe<Scalars["Int"]>;
  Unit?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Ncr_Units_Stddev_Fields = {
  __typename?: "ncr_Units_stddev_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Ncr_Units_Stddev_Pop_Fields = {
  __typename?: "ncr_Units_stddev_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Ncr_Units_Stddev_Samp_Fields = {
  __typename?: "ncr_Units_stddev_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate sum on columns */
export type Ncr_Units_Sum_Fields = {
  __typename?: "ncr_Units_sum_fields";
  Id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "ncr.Units" */
export enum Ncr_Units_Update_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Unit = "Unit"
}

/** aggregate var_pop on columns */
export type Ncr_Units_Var_Pop_Fields = {
  __typename?: "ncr_Units_var_pop_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Ncr_Units_Var_Samp_Fields = {
  __typename?: "ncr_Units_var_samp_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Ncr_Units_Variance_Fields = {
  __typename?: "ncr_Units_variance_fields";
  Id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "ncr.Users" */
export type Ncr_Users = {
  __typename?: "ncr_Users";
  /** An array relationship */
  CorrectiveActions: Array<Ncr_CorrectiveActions>;
  /** An aggregate relationship */
  CorrectiveActions_aggregate: Ncr_CorrectiveActions_Aggregate;
  /** An array relationship */
  Defect_TeamMembers: Array<Ncr_Defect_TeamMember>;
  /** An aggregate relationship */
  Defect_TeamMembers_aggregate: Ncr_Defect_TeamMember_Aggregate;
  /** An array relationship */
  Defects: Array<Ncr_Defects>;
  /** An aggregate relationship */
  Defects_aggregate: Ncr_Defects_Aggregate;
  Id: Scalars["uuid"];
  Login: Scalars["String"];
  Name: Scalars["String"];
  Surname: Scalars["String"];
  /** An array relationship */
  defectsByLeader: Array<Ncr_Defects>;
  /** An aggregate relationship */
  defectsByLeader_aggregate: Ncr_Defects_Aggregate;
  /** An array relationship */
  defectsByReporter: Array<Ncr_Defects>;
  /** An aggregate relationship */
  defectsByReporter_aggregate: Ncr_Defects_Aggregate;
  /** An array relationship */
  defectsByResponsibleforfirstcorrection: Array<Ncr_Defects>;
  /** An aggregate relationship */
  defectsByResponsibleforfirstcorrection_aggregate: Ncr_Defects_Aggregate;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersCorrectiveActionsArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersCorrectiveActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefect_TeamMembersArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefect_TeamMembers_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefectsArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefects_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefectsByLeaderArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefectsByLeader_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefectsByReporterArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefectsByReporter_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefectsByResponsibleforfirstcorrectionArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


/** columns and relationships of "ncr.Users" */
export type Ncr_UsersDefectsByResponsibleforfirstcorrection_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};

/** aggregated selection of "ncr.Users" */
export type Ncr_Users_Aggregate = {
  __typename?: "ncr_Users_aggregate";
  aggregate?: Maybe<Ncr_Users_Aggregate_Fields>;
  nodes: Array<Ncr_Users>;
};

/** aggregate fields of "ncr.Users" */
export type Ncr_Users_Aggregate_Fields = {
  __typename?: "ncr_Users_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Ncr_Users_Max_Fields>;
  min?: Maybe<Ncr_Users_Min_Fields>;
};


/** aggregate fields of "ncr.Users" */
export type Ncr_Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Ncr_Users_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "ncr.Users". All fields are combined with a logical 'AND'. */
export type Ncr_Users_Bool_Exp = {
  CorrectiveActions?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
  Defect_TeamMembers?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
  Defects?: Maybe<Ncr_Defects_Bool_Exp>;
  Id?: Maybe<Uuid_Comparison_Exp>;
  Login?: Maybe<String_Comparison_Exp>;
  Name?: Maybe<String_Comparison_Exp>;
  Surname?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Ncr_Users_Bool_Exp>>;
  _not?: Maybe<Ncr_Users_Bool_Exp>;
  _or?: Maybe<Array<Ncr_Users_Bool_Exp>>;
  defectsByLeader?: Maybe<Ncr_Defects_Bool_Exp>;
  defectsByReporter?: Maybe<Ncr_Defects_Bool_Exp>;
  defectsByResponsibleforfirstcorrection?: Maybe<Ncr_Defects_Bool_Exp>;
};

/** unique or primary key constraints on table "ncr.Users" */
export enum Ncr_Users_Constraint {
  /** unique or primary key constraint */
  UserLoginKey = "User_Login_key",
  /** unique or primary key constraint */
  UserPkey = "User_pkey"
}

/** input type for inserting data into table "ncr.Users" */
export type Ncr_Users_Insert_Input = {
  CorrectiveActions?: Maybe<Ncr_CorrectiveActions_Arr_Rel_Insert_Input>;
  Defect_TeamMembers?: Maybe<Ncr_Defect_TeamMember_Arr_Rel_Insert_Input>;
  Defects?: Maybe<Ncr_Defects_Arr_Rel_Insert_Input>;
  Id?: Maybe<Scalars["uuid"]>;
  Login?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
  defectsByLeader?: Maybe<Ncr_Defects_Arr_Rel_Insert_Input>;
  defectsByReporter?: Maybe<Ncr_Defects_Arr_Rel_Insert_Input>;
  defectsByResponsibleforfirstcorrection?: Maybe<Ncr_Defects_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ncr_Users_Max_Fields = {
  __typename?: "ncr_Users_max_fields";
  Id?: Maybe<Scalars["uuid"]>;
  Login?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Ncr_Users_Min_Fields = {
  __typename?: "ncr_Users_min_fields";
  Id?: Maybe<Scalars["uuid"]>;
  Login?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "ncr.Users" */
export type Ncr_Users_Mutation_Response = {
  __typename?: "ncr_Users_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Ncr_Users>;
};

/** input type for inserting object relation for remote table "ncr.Users" */
export type Ncr_Users_Obj_Rel_Insert_Input = {
  data: Ncr_Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Ncr_Users_On_Conflict>;
};

/** on conflict condition type for table "ncr.Users" */
export type Ncr_Users_On_Conflict = {
  constraint: Ncr_Users_Constraint;
  update_columns?: Array<Ncr_Users_Update_Column>;
  where?: Maybe<Ncr_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "ncr.Users". */
export type Ncr_Users_Order_By = {
  CorrectiveActions_aggregate?: Maybe<Ncr_CorrectiveActions_Aggregate_Order_By>;
  Defect_TeamMembers_aggregate?: Maybe<Ncr_Defect_TeamMember_Aggregate_Order_By>;
  Defects_aggregate?: Maybe<Ncr_Defects_Aggregate_Order_By>;
  Id?: Maybe<Order_By>;
  Login?: Maybe<Order_By>;
  Name?: Maybe<Order_By>;
  Surname?: Maybe<Order_By>;
  defectsByLeader_aggregate?: Maybe<Ncr_Defects_Aggregate_Order_By>;
  defectsByReporter_aggregate?: Maybe<Ncr_Defects_Aggregate_Order_By>;
  defectsByResponsibleforfirstcorrection_aggregate?: Maybe<Ncr_Defects_Aggregate_Order_By>;
};

/** primary key columns input for table: ncr_Users */
export type Ncr_Users_Pk_Columns_Input = {
  Id: Scalars["uuid"];
};

/** select columns of table "ncr.Users" */
export enum Ncr_Users_Select_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Login = "Login",
  /** column name */
  Name = "Name",
  /** column name */
  Surname = "Surname"
}

/** input type for updating data in table "ncr.Users" */
export type Ncr_Users_Set_Input = {
  Id?: Maybe<Scalars["uuid"]>;
  Login?: Maybe<Scalars["String"]>;
  Name?: Maybe<Scalars["String"]>;
  Surname?: Maybe<Scalars["String"]>;
};

/** update columns of table "ncr.Users" */
export enum Ncr_Users_Update_Column {
  /** column name */
  Id = "Id",
  /** column name */
  Login = "Login",
  /** column name */
  Name = "Name",
  /** column name */
  Surname = "Surname"
}

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last"
}

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "ncr.ActionStatuses" */
  ncr_ActionStatuses: Array<Ncr_ActionStatuses>;
  /** fetch aggregated fields from the table: "ncr.ActionStatuses" */
  ncr_ActionStatuses_aggregate: Ncr_ActionStatuses_Aggregate;
  /** fetch data from the table: "ncr.ActionStatuses" using primary key columns */
  ncr_ActionStatuses_by_pk?: Maybe<Ncr_ActionStatuses>;
  /** fetch data from the table: "ncr.Addresses" */
  ncr_Addresses: Array<Ncr_Addresses>;
  /** fetch aggregated fields from the table: "ncr.Addresses" */
  ncr_Addresses_aggregate: Ncr_Addresses_Aggregate;
  /** fetch data from the table: "ncr.Addresses" using primary key columns */
  ncr_Addresses_by_pk?: Maybe<Ncr_Addresses>;
  /** fetch data from the table: "ncr.Attachment_CorrectiveAction" */
  ncr_Attachment_CorrectiveAction: Array<Ncr_Attachment_CorrectiveAction>;
  /** fetch aggregated fields from the table: "ncr.Attachment_CorrectiveAction" */
  ncr_Attachment_CorrectiveAction_aggregate: Ncr_Attachment_CorrectiveAction_Aggregate;
  /** fetch data from the table: "ncr.Attachment_CorrectiveAction" using primary key columns */
  ncr_Attachment_CorrectiveAction_by_pk?: Maybe<Ncr_Attachment_CorrectiveAction>;
  /** fetch data from the table: "ncr.Attachment_Defect" */
  ncr_Attachment_Defect: Array<Ncr_Attachment_Defect>;
  /** fetch aggregated fields from the table: "ncr.Attachment_Defect" */
  ncr_Attachment_Defect_aggregate: Ncr_Attachment_Defect_Aggregate;
  /** fetch data from the table: "ncr.Attachment_Defect" using primary key columns */
  ncr_Attachment_Defect_by_pk?: Maybe<Ncr_Attachment_Defect>;
  /** fetch data from the table: "ncr.Attachment_Product" */
  ncr_Attachment_Product: Array<Ncr_Attachment_Product>;
  /** fetch aggregated fields from the table: "ncr.Attachment_Product" */
  ncr_Attachment_Product_aggregate: Ncr_Attachment_Product_Aggregate;
  /** fetch data from the table: "ncr.Attachment_Product" using primary key columns */
  ncr_Attachment_Product_by_pk?: Maybe<Ncr_Attachment_Product>;
  /** fetch data from the table: "ncr.Attachments" */
  ncr_Attachments: Array<Ncr_Attachments>;
  /** fetch aggregated fields from the table: "ncr.Attachments" */
  ncr_Attachments_aggregate: Ncr_Attachments_Aggregate;
  /** fetch data from the table: "ncr.Attachments" using primary key columns */
  ncr_Attachments_by_pk?: Maybe<Ncr_Attachments>;
  /** fetch data from the table: "ncr.CompanyAffected" */
  ncr_CompanyAffected: Array<Ncr_CompanyAffected>;
  /** fetch aggregated fields from the table: "ncr.CompanyAffected" */
  ncr_CompanyAffected_aggregate: Ncr_CompanyAffected_Aggregate;
  /** fetch data from the table: "ncr.CompanyAffected" using primary key columns */
  ncr_CompanyAffected_by_pk?: Maybe<Ncr_CompanyAffected>;
  /** fetch data from the table: "ncr.CorrectiveActions" */
  ncr_CorrectiveActions: Array<Ncr_CorrectiveActions>;
  /** fetch aggregated fields from the table: "ncr.CorrectiveActions" */
  ncr_CorrectiveActions_aggregate: Ncr_CorrectiveActions_Aggregate;
  /** fetch data from the table: "ncr.CorrectiveActions" using primary key columns */
  ncr_CorrectiveActions_by_pk?: Maybe<Ncr_CorrectiveActions>;
  /** fetch data from the table: "ncr.CustomerToInform" */
  ncr_CustomerToInform: Array<Ncr_CustomerToInform>;
  /** fetch aggregated fields from the table: "ncr.CustomerToInform" */
  ncr_CustomerToInform_aggregate: Ncr_CustomerToInform_Aggregate;
  /** fetch data from the table: "ncr.CustomerToInform" using primary key columns */
  ncr_CustomerToInform_by_pk?: Maybe<Ncr_CustomerToInform>;
  /** fetch data from the table: "ncr.Defect_TeamMember" */
  ncr_Defect_TeamMember: Array<Ncr_Defect_TeamMember>;
  /** fetch aggregated fields from the table: "ncr.Defect_TeamMember" */
  ncr_Defect_TeamMember_aggregate: Ncr_Defect_TeamMember_Aggregate;
  /** fetch data from the table: "ncr.Defect_TeamMember" using primary key columns */
  ncr_Defect_TeamMember_by_pk?: Maybe<Ncr_Defect_TeamMember>;
  /** fetch data from the table: "ncr.Defects" */
  ncr_Defects: Array<Ncr_Defects>;
  /** fetch aggregated fields from the table: "ncr.Defects" */
  ncr_Defects_aggregate: Ncr_Defects_Aggregate;
  /** fetch data from the table: "ncr.Defects" using primary key columns */
  ncr_Defects_by_pk?: Maybe<Ncr_Defects>;
  /** fetch data from the table: "ncr.Feature_Defect" */
  ncr_Feature_Defect: Array<Ncr_Feature_Defect>;
  /** fetch aggregated fields from the table: "ncr.Feature_Defect" */
  ncr_Feature_Defect_aggregate: Ncr_Feature_Defect_Aggregate;
  /** fetch data from the table: "ncr.Features" */
  ncr_Features: Array<Ncr_Features>;
  /** fetch aggregated fields from the table: "ncr.Features" */
  ncr_Features_aggregate: Ncr_Features_Aggregate;
  /** fetch data from the table: "ncr.Features" using primary key columns */
  ncr_Features_by_pk?: Maybe<Ncr_Features>;
  /** fetch data from the table: "ncr.PartAffected" */
  ncr_PartAffected: Array<Ncr_PartAffected>;
  /** fetch aggregated fields from the table: "ncr.PartAffected" */
  ncr_PartAffected_aggregate: Ncr_PartAffected_Aggregate;
  /** fetch data from the table: "ncr.PartAffected" using primary key columns */
  ncr_PartAffected_by_pk?: Maybe<Ncr_PartAffected>;
  /** fetch data from the table: "ncr.RootCauses" */
  ncr_RootCauses: Array<Ncr_RootCauses>;
  /** fetch aggregated fields from the table: "ncr.RootCauses" */
  ncr_RootCauses_aggregate: Ncr_RootCauses_Aggregate;
  /** fetch data from the table: "ncr.RootCauses" using primary key columns */
  ncr_RootCauses_by_pk?: Maybe<Ncr_RootCauses>;
  /** fetch data from the table: "ncr.Statuses" */
  ncr_Statuses: Array<Ncr_Statuses>;
  /** fetch aggregated fields from the table: "ncr.Statuses" */
  ncr_Statuses_aggregate: Ncr_Statuses_Aggregate;
  /** fetch data from the table: "ncr.Statuses" using primary key columns */
  ncr_Statuses_by_pk?: Maybe<Ncr_Statuses>;
  /** fetch data from the table: "ncr.ThirdParties" */
  ncr_ThirdParties: Array<Ncr_ThirdParties>;
  /** fetch aggregated fields from the table: "ncr.ThirdParties" */
  ncr_ThirdParties_aggregate: Ncr_ThirdParties_Aggregate;
  /** fetch data from the table: "ncr.ThirdParties" using primary key columns */
  ncr_ThirdParties_by_pk?: Maybe<Ncr_ThirdParties>;
  /** fetch data from the table: "ncr.Units" */
  ncr_Units: Array<Ncr_Units>;
  /** fetch aggregated fields from the table: "ncr.Units" */
  ncr_Units_aggregate: Ncr_Units_Aggregate;
  /** fetch data from the table: "ncr.Units" using primary key columns */
  ncr_Units_by_pk?: Maybe<Ncr_Units>;
  /** fetch data from the table: "ncr.Users" */
  ncr_Users: Array<Ncr_Users>;
  /** fetch aggregated fields from the table: "ncr.Users" */
  ncr_Users_aggregate: Ncr_Users_Aggregate;
  /** fetch data from the table: "ncr.Users" using primary key columns */
  ncr_Users_by_pk?: Maybe<Ncr_Users>;
};


export type Query_RootNcr_ActionStatusesArgs = {
  distinct_on?: Maybe<Array<Ncr_ActionStatuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ActionStatuses_Order_By>>;
  where?: Maybe<Ncr_ActionStatuses_Bool_Exp>;
};


export type Query_RootNcr_ActionStatuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_ActionStatuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ActionStatuses_Order_By>>;
  where?: Maybe<Ncr_ActionStatuses_Bool_Exp>;
};


export type Query_RootNcr_ActionStatuses_By_PkArgs = {
  Id: Scalars["Int"];
};


export type Query_RootNcr_AddressesArgs = {
  distinct_on?: Maybe<Array<Ncr_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Addresses_Order_By>>;
  where?: Maybe<Ncr_Addresses_Bool_Exp>;
};


export type Query_RootNcr_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Addresses_Order_By>>;
  where?: Maybe<Ncr_Addresses_Bool_Exp>;
};


export type Query_RootNcr_Addresses_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Query_RootNcr_Attachment_CorrectiveActionArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};


export type Query_RootNcr_Attachment_CorrectiveAction_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};


export type Query_RootNcr_Attachment_CorrectiveAction_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  CorrectiveActionId: Scalars["uuid"];
};


export type Query_RootNcr_Attachment_DefectArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


export type Query_RootNcr_Attachment_Defect_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


export type Query_RootNcr_Attachment_Defect_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


export type Query_RootNcr_Attachment_ProductArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Product_Order_By>>;
  where?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
};


export type Query_RootNcr_Attachment_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Product_Order_By>>;
  where?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
};


export type Query_RootNcr_Attachment_Product_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};


export type Query_RootNcr_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachments_Order_By>>;
  where?: Maybe<Ncr_Attachments_Bool_Exp>;
};


export type Query_RootNcr_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachments_Order_By>>;
  where?: Maybe<Ncr_Attachments_Bool_Exp>;
};


export type Query_RootNcr_Attachments_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Query_RootNcr_CompanyAffectedArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


export type Query_RootNcr_CompanyAffected_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


export type Query_RootNcr_CompanyAffected_By_PkArgs = {
  CompanyId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


export type Query_RootNcr_CorrectiveActionsArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


export type Query_RootNcr_CorrectiveActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


export type Query_RootNcr_CorrectiveActions_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Query_RootNcr_CustomerToInformArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};


export type Query_RootNcr_CustomerToInform_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};


export type Query_RootNcr_CustomerToInform_By_PkArgs = {
  CustomerId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


export type Query_RootNcr_Defect_TeamMemberArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


export type Query_RootNcr_Defect_TeamMember_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


export type Query_RootNcr_Defect_TeamMember_By_PkArgs = {
  DefectId: Scalars["uuid"];
  UserId: Scalars["uuid"];
};


export type Query_RootNcr_DefectsArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


export type Query_RootNcr_Defects_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


export type Query_RootNcr_Defects_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Query_RootNcr_Feature_DefectArgs = {
  distinct_on?: Maybe<Array<Ncr_Feature_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Feature_Defect_Order_By>>;
  where?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
};


export type Query_RootNcr_Feature_Defect_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Feature_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Feature_Defect_Order_By>>;
  where?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
};


export type Query_RootNcr_FeaturesArgs = {
  distinct_on?: Maybe<Array<Ncr_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Features_Order_By>>;
  where?: Maybe<Ncr_Features_Bool_Exp>;
};


export type Query_RootNcr_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Features_Order_By>>;
  where?: Maybe<Ncr_Features_Bool_Exp>;
};


export type Query_RootNcr_Features_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Query_RootNcr_PartAffectedArgs = {
  distinct_on?: Maybe<Array<Ncr_PartAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_PartAffected_Order_By>>;
  where?: Maybe<Ncr_PartAffected_Bool_Exp>;
};


export type Query_RootNcr_PartAffected_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_PartAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_PartAffected_Order_By>>;
  where?: Maybe<Ncr_PartAffected_Bool_Exp>;
};


export type Query_RootNcr_PartAffected_By_PkArgs = {
  DefectId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};


export type Query_RootNcr_RootCausesArgs = {
  distinct_on?: Maybe<Array<Ncr_RootCauses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_RootCauses_Order_By>>;
  where?: Maybe<Ncr_RootCauses_Bool_Exp>;
};


export type Query_RootNcr_RootCauses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_RootCauses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_RootCauses_Order_By>>;
  where?: Maybe<Ncr_RootCauses_Bool_Exp>;
};


export type Query_RootNcr_RootCauses_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Query_RootNcr_StatusesArgs = {
  distinct_on?: Maybe<Array<Ncr_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Statuses_Order_By>>;
  where?: Maybe<Ncr_Statuses_Bool_Exp>;
};


export type Query_RootNcr_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Statuses_Order_By>>;
  where?: Maybe<Ncr_Statuses_Bool_Exp>;
};


export type Query_RootNcr_Statuses_By_PkArgs = {
  Status: Scalars["String"];
};


export type Query_RootNcr_ThirdPartiesArgs = {
  distinct_on?: Maybe<Array<Ncr_ThirdParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ThirdParties_Order_By>>;
  where?: Maybe<Ncr_ThirdParties_Bool_Exp>;
};


export type Query_RootNcr_ThirdParties_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_ThirdParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ThirdParties_Order_By>>;
  where?: Maybe<Ncr_ThirdParties_Bool_Exp>;
};


export type Query_RootNcr_ThirdParties_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Query_RootNcr_UnitsArgs = {
  distinct_on?: Maybe<Array<Ncr_Units_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Units_Order_By>>;
  where?: Maybe<Ncr_Units_Bool_Exp>;
};


export type Query_RootNcr_Units_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Units_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Units_Order_By>>;
  where?: Maybe<Ncr_Units_Bool_Exp>;
};


export type Query_RootNcr_Units_By_PkArgs = {
  Id: Scalars["Int"];
};


export type Query_RootNcr_UsersArgs = {
  distinct_on?: Maybe<Array<Ncr_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Users_Order_By>>;
  where?: Maybe<Ncr_Users_Bool_Exp>;
};


export type Query_RootNcr_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Users_Order_By>>;
  where?: Maybe<Ncr_Users_Bool_Exp>;
};


export type Query_RootNcr_Users_By_PkArgs = {
  Id: Scalars["uuid"];
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "ncr.ActionStatuses" */
  ncr_ActionStatuses: Array<Ncr_ActionStatuses>;
  /** fetch aggregated fields from the table: "ncr.ActionStatuses" */
  ncr_ActionStatuses_aggregate: Ncr_ActionStatuses_Aggregate;
  /** fetch data from the table: "ncr.ActionStatuses" using primary key columns */
  ncr_ActionStatuses_by_pk?: Maybe<Ncr_ActionStatuses>;
  /** fetch data from the table: "ncr.Addresses" */
  ncr_Addresses: Array<Ncr_Addresses>;
  /** fetch aggregated fields from the table: "ncr.Addresses" */
  ncr_Addresses_aggregate: Ncr_Addresses_Aggregate;
  /** fetch data from the table: "ncr.Addresses" using primary key columns */
  ncr_Addresses_by_pk?: Maybe<Ncr_Addresses>;
  /** fetch data from the table: "ncr.Attachment_CorrectiveAction" */
  ncr_Attachment_CorrectiveAction: Array<Ncr_Attachment_CorrectiveAction>;
  /** fetch aggregated fields from the table: "ncr.Attachment_CorrectiveAction" */
  ncr_Attachment_CorrectiveAction_aggregate: Ncr_Attachment_CorrectiveAction_Aggregate;
  /** fetch data from the table: "ncr.Attachment_CorrectiveAction" using primary key columns */
  ncr_Attachment_CorrectiveAction_by_pk?: Maybe<Ncr_Attachment_CorrectiveAction>;
  /** fetch data from the table: "ncr.Attachment_Defect" */
  ncr_Attachment_Defect: Array<Ncr_Attachment_Defect>;
  /** fetch aggregated fields from the table: "ncr.Attachment_Defect" */
  ncr_Attachment_Defect_aggregate: Ncr_Attachment_Defect_Aggregate;
  /** fetch data from the table: "ncr.Attachment_Defect" using primary key columns */
  ncr_Attachment_Defect_by_pk?: Maybe<Ncr_Attachment_Defect>;
  /** fetch data from the table: "ncr.Attachment_Product" */
  ncr_Attachment_Product: Array<Ncr_Attachment_Product>;
  /** fetch aggregated fields from the table: "ncr.Attachment_Product" */
  ncr_Attachment_Product_aggregate: Ncr_Attachment_Product_Aggregate;
  /** fetch data from the table: "ncr.Attachment_Product" using primary key columns */
  ncr_Attachment_Product_by_pk?: Maybe<Ncr_Attachment_Product>;
  /** fetch data from the table: "ncr.Attachments" */
  ncr_Attachments: Array<Ncr_Attachments>;
  /** fetch aggregated fields from the table: "ncr.Attachments" */
  ncr_Attachments_aggregate: Ncr_Attachments_Aggregate;
  /** fetch data from the table: "ncr.Attachments" using primary key columns */
  ncr_Attachments_by_pk?: Maybe<Ncr_Attachments>;
  /** fetch data from the table: "ncr.CompanyAffected" */
  ncr_CompanyAffected: Array<Ncr_CompanyAffected>;
  /** fetch aggregated fields from the table: "ncr.CompanyAffected" */
  ncr_CompanyAffected_aggregate: Ncr_CompanyAffected_Aggregate;
  /** fetch data from the table: "ncr.CompanyAffected" using primary key columns */
  ncr_CompanyAffected_by_pk?: Maybe<Ncr_CompanyAffected>;
  /** fetch data from the table: "ncr.CorrectiveActions" */
  ncr_CorrectiveActions: Array<Ncr_CorrectiveActions>;
  /** fetch aggregated fields from the table: "ncr.CorrectiveActions" */
  ncr_CorrectiveActions_aggregate: Ncr_CorrectiveActions_Aggregate;
  /** fetch data from the table: "ncr.CorrectiveActions" using primary key columns */
  ncr_CorrectiveActions_by_pk?: Maybe<Ncr_CorrectiveActions>;
  /** fetch data from the table: "ncr.CustomerToInform" */
  ncr_CustomerToInform: Array<Ncr_CustomerToInform>;
  /** fetch aggregated fields from the table: "ncr.CustomerToInform" */
  ncr_CustomerToInform_aggregate: Ncr_CustomerToInform_Aggregate;
  /** fetch data from the table: "ncr.CustomerToInform" using primary key columns */
  ncr_CustomerToInform_by_pk?: Maybe<Ncr_CustomerToInform>;
  /** fetch data from the table: "ncr.Defect_TeamMember" */
  ncr_Defect_TeamMember: Array<Ncr_Defect_TeamMember>;
  /** fetch aggregated fields from the table: "ncr.Defect_TeamMember" */
  ncr_Defect_TeamMember_aggregate: Ncr_Defect_TeamMember_Aggregate;
  /** fetch data from the table: "ncr.Defect_TeamMember" using primary key columns */
  ncr_Defect_TeamMember_by_pk?: Maybe<Ncr_Defect_TeamMember>;
  /** fetch data from the table: "ncr.Defects" */
  ncr_Defects: Array<Ncr_Defects>;
  /** fetch aggregated fields from the table: "ncr.Defects" */
  ncr_Defects_aggregate: Ncr_Defects_Aggregate;
  /** fetch data from the table: "ncr.Defects" using primary key columns */
  ncr_Defects_by_pk?: Maybe<Ncr_Defects>;
  /** fetch data from the table: "ncr.Feature_Defect" */
  ncr_Feature_Defect: Array<Ncr_Feature_Defect>;
  /** fetch aggregated fields from the table: "ncr.Feature_Defect" */
  ncr_Feature_Defect_aggregate: Ncr_Feature_Defect_Aggregate;
  /** fetch data from the table: "ncr.Features" */
  ncr_Features: Array<Ncr_Features>;
  /** fetch aggregated fields from the table: "ncr.Features" */
  ncr_Features_aggregate: Ncr_Features_Aggregate;
  /** fetch data from the table: "ncr.Features" using primary key columns */
  ncr_Features_by_pk?: Maybe<Ncr_Features>;
  /** fetch data from the table: "ncr.PartAffected" */
  ncr_PartAffected: Array<Ncr_PartAffected>;
  /** fetch aggregated fields from the table: "ncr.PartAffected" */
  ncr_PartAffected_aggregate: Ncr_PartAffected_Aggregate;
  /** fetch data from the table: "ncr.PartAffected" using primary key columns */
  ncr_PartAffected_by_pk?: Maybe<Ncr_PartAffected>;
  /** fetch data from the table: "ncr.RootCauses" */
  ncr_RootCauses: Array<Ncr_RootCauses>;
  /** fetch aggregated fields from the table: "ncr.RootCauses" */
  ncr_RootCauses_aggregate: Ncr_RootCauses_Aggregate;
  /** fetch data from the table: "ncr.RootCauses" using primary key columns */
  ncr_RootCauses_by_pk?: Maybe<Ncr_RootCauses>;
  /** fetch data from the table: "ncr.Statuses" */
  ncr_Statuses: Array<Ncr_Statuses>;
  /** fetch aggregated fields from the table: "ncr.Statuses" */
  ncr_Statuses_aggregate: Ncr_Statuses_Aggregate;
  /** fetch data from the table: "ncr.Statuses" using primary key columns */
  ncr_Statuses_by_pk?: Maybe<Ncr_Statuses>;
  /** fetch data from the table: "ncr.ThirdParties" */
  ncr_ThirdParties: Array<Ncr_ThirdParties>;
  /** fetch aggregated fields from the table: "ncr.ThirdParties" */
  ncr_ThirdParties_aggregate: Ncr_ThirdParties_Aggregate;
  /** fetch data from the table: "ncr.ThirdParties" using primary key columns */
  ncr_ThirdParties_by_pk?: Maybe<Ncr_ThirdParties>;
  /** fetch data from the table: "ncr.Units" */
  ncr_Units: Array<Ncr_Units>;
  /** fetch aggregated fields from the table: "ncr.Units" */
  ncr_Units_aggregate: Ncr_Units_Aggregate;
  /** fetch data from the table: "ncr.Units" using primary key columns */
  ncr_Units_by_pk?: Maybe<Ncr_Units>;
  /** fetch data from the table: "ncr.Users" */
  ncr_Users: Array<Ncr_Users>;
  /** fetch aggregated fields from the table: "ncr.Users" */
  ncr_Users_aggregate: Ncr_Users_Aggregate;
  /** fetch data from the table: "ncr.Users" using primary key columns */
  ncr_Users_by_pk?: Maybe<Ncr_Users>;
};


export type Subscription_RootNcr_ActionStatusesArgs = {
  distinct_on?: Maybe<Array<Ncr_ActionStatuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ActionStatuses_Order_By>>;
  where?: Maybe<Ncr_ActionStatuses_Bool_Exp>;
};


export type Subscription_RootNcr_ActionStatuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_ActionStatuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ActionStatuses_Order_By>>;
  where?: Maybe<Ncr_ActionStatuses_Bool_Exp>;
};


export type Subscription_RootNcr_ActionStatuses_By_PkArgs = {
  Id: Scalars["Int"];
};


export type Subscription_RootNcr_AddressesArgs = {
  distinct_on?: Maybe<Array<Ncr_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Addresses_Order_By>>;
  where?: Maybe<Ncr_Addresses_Bool_Exp>;
};


export type Subscription_RootNcr_Addresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Addresses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Addresses_Order_By>>;
  where?: Maybe<Ncr_Addresses_Bool_Exp>;
};


export type Subscription_RootNcr_Addresses_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Subscription_RootNcr_Attachment_CorrectiveActionArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};


export type Subscription_RootNcr_Attachment_CorrectiveAction_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_CorrectiveAction_Order_By>>;
  where?: Maybe<Ncr_Attachment_CorrectiveAction_Bool_Exp>;
};


export type Subscription_RootNcr_Attachment_CorrectiveAction_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  CorrectiveActionId: Scalars["uuid"];
};


export type Subscription_RootNcr_Attachment_DefectArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


export type Subscription_RootNcr_Attachment_Defect_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Defect_Order_By>>;
  where?: Maybe<Ncr_Attachment_Defect_Bool_Exp>;
};


export type Subscription_RootNcr_Attachment_Defect_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


export type Subscription_RootNcr_Attachment_ProductArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Product_Order_By>>;
  where?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
};


export type Subscription_RootNcr_Attachment_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachment_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachment_Product_Order_By>>;
  where?: Maybe<Ncr_Attachment_Product_Bool_Exp>;
};


export type Subscription_RootNcr_Attachment_Product_By_PkArgs = {
  AttachmentId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};


export type Subscription_RootNcr_AttachmentsArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachments_Order_By>>;
  where?: Maybe<Ncr_Attachments_Bool_Exp>;
};


export type Subscription_RootNcr_Attachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Attachments_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Attachments_Order_By>>;
  where?: Maybe<Ncr_Attachments_Bool_Exp>;
};


export type Subscription_RootNcr_Attachments_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Subscription_RootNcr_CompanyAffectedArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


export type Subscription_RootNcr_CompanyAffected_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CompanyAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CompanyAffected_Order_By>>;
  where?: Maybe<Ncr_CompanyAffected_Bool_Exp>;
};


export type Subscription_RootNcr_CompanyAffected_By_PkArgs = {
  CompanyId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


export type Subscription_RootNcr_CorrectiveActionsArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


export type Subscription_RootNcr_CorrectiveActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CorrectiveActions_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CorrectiveActions_Order_By>>;
  where?: Maybe<Ncr_CorrectiveActions_Bool_Exp>;
};


export type Subscription_RootNcr_CorrectiveActions_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Subscription_RootNcr_CustomerToInformArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};


export type Subscription_RootNcr_CustomerToInform_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_CustomerToInform_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_CustomerToInform_Order_By>>;
  where?: Maybe<Ncr_CustomerToInform_Bool_Exp>;
};


export type Subscription_RootNcr_CustomerToInform_By_PkArgs = {
  CustomerId: Scalars["uuid"];
  DefectId: Scalars["uuid"];
};


export type Subscription_RootNcr_Defect_TeamMemberArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


export type Subscription_RootNcr_Defect_TeamMember_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defect_TeamMember_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defect_TeamMember_Order_By>>;
  where?: Maybe<Ncr_Defect_TeamMember_Bool_Exp>;
};


export type Subscription_RootNcr_Defect_TeamMember_By_PkArgs = {
  DefectId: Scalars["uuid"];
  UserId: Scalars["uuid"];
};


export type Subscription_RootNcr_DefectsArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


export type Subscription_RootNcr_Defects_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Defects_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Defects_Order_By>>;
  where?: Maybe<Ncr_Defects_Bool_Exp>;
};


export type Subscription_RootNcr_Defects_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Subscription_RootNcr_Feature_DefectArgs = {
  distinct_on?: Maybe<Array<Ncr_Feature_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Feature_Defect_Order_By>>;
  where?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
};


export type Subscription_RootNcr_Feature_Defect_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Feature_Defect_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Feature_Defect_Order_By>>;
  where?: Maybe<Ncr_Feature_Defect_Bool_Exp>;
};


export type Subscription_RootNcr_FeaturesArgs = {
  distinct_on?: Maybe<Array<Ncr_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Features_Order_By>>;
  where?: Maybe<Ncr_Features_Bool_Exp>;
};


export type Subscription_RootNcr_Features_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Features_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Features_Order_By>>;
  where?: Maybe<Ncr_Features_Bool_Exp>;
};


export type Subscription_RootNcr_Features_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Subscription_RootNcr_PartAffectedArgs = {
  distinct_on?: Maybe<Array<Ncr_PartAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_PartAffected_Order_By>>;
  where?: Maybe<Ncr_PartAffected_Bool_Exp>;
};


export type Subscription_RootNcr_PartAffected_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_PartAffected_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_PartAffected_Order_By>>;
  where?: Maybe<Ncr_PartAffected_Bool_Exp>;
};


export type Subscription_RootNcr_PartAffected_By_PkArgs = {
  DefectId: Scalars["uuid"];
  ProductId: Scalars["uuid"];
};


export type Subscription_RootNcr_RootCausesArgs = {
  distinct_on?: Maybe<Array<Ncr_RootCauses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_RootCauses_Order_By>>;
  where?: Maybe<Ncr_RootCauses_Bool_Exp>;
};


export type Subscription_RootNcr_RootCauses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_RootCauses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_RootCauses_Order_By>>;
  where?: Maybe<Ncr_RootCauses_Bool_Exp>;
};


export type Subscription_RootNcr_RootCauses_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Subscription_RootNcr_StatusesArgs = {
  distinct_on?: Maybe<Array<Ncr_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Statuses_Order_By>>;
  where?: Maybe<Ncr_Statuses_Bool_Exp>;
};


export type Subscription_RootNcr_Statuses_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Statuses_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Statuses_Order_By>>;
  where?: Maybe<Ncr_Statuses_Bool_Exp>;
};


export type Subscription_RootNcr_Statuses_By_PkArgs = {
  Status: Scalars["String"];
};


export type Subscription_RootNcr_ThirdPartiesArgs = {
  distinct_on?: Maybe<Array<Ncr_ThirdParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ThirdParties_Order_By>>;
  where?: Maybe<Ncr_ThirdParties_Bool_Exp>;
};


export type Subscription_RootNcr_ThirdParties_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_ThirdParties_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_ThirdParties_Order_By>>;
  where?: Maybe<Ncr_ThirdParties_Bool_Exp>;
};


export type Subscription_RootNcr_ThirdParties_By_PkArgs = {
  Id: Scalars["uuid"];
};


export type Subscription_RootNcr_UnitsArgs = {
  distinct_on?: Maybe<Array<Ncr_Units_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Units_Order_By>>;
  where?: Maybe<Ncr_Units_Bool_Exp>;
};


export type Subscription_RootNcr_Units_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Units_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Units_Order_By>>;
  where?: Maybe<Ncr_Units_Bool_Exp>;
};


export type Subscription_RootNcr_Units_By_PkArgs = {
  Id: Scalars["Int"];
};


export type Subscription_RootNcr_UsersArgs = {
  distinct_on?: Maybe<Array<Ncr_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Users_Order_By>>;
  where?: Maybe<Ncr_Users_Bool_Exp>;
};


export type Subscription_RootNcr_Users_AggregateArgs = {
  distinct_on?: Maybe<Array<Ncr_Users_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Ncr_Users_Order_By>>;
  where?: Maybe<Ncr_Users_Bool_Exp>;
};


export type Subscription_RootNcr_Users_By_PkArgs = {
  Id: Scalars["uuid"];
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timetz"]>;
  _gt?: Maybe<Scalars["timetz"]>;
  _gte?: Maybe<Scalars["timetz"]>;
  _in?: Maybe<Array<Scalars["timetz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timetz"]>;
  _lte?: Maybe<Scalars["timetz"]>;
  _neq?: Maybe<Scalars["timetz"]>;
  _nin?: Maybe<Array<Scalars["timetz"]>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

export type UpdateDefectByIdMutationVariables = Exact<{
  id: Scalars["uuid"];
  batchNo?: Maybe<Scalars["String"]>;
  manufacturingNo?: Maybe<Scalars["String"]>;
  defectDescription?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  otherInfo?: Maybe<Scalars["String"]>;
  productName?: Maybe<Scalars["String"]>;
  qtyDelivered?: Scalars["Int"];
  qtyRejected?: Scalars["Int"];
}>;


export type UpdateDefectByIdMutation = { __typename?: "mutation_root", update_ncr_Defects_by_pk?: Maybe<{ __typename?: "ncr_Defects", Id: any, BatchNumber?: Maybe<string>, DefectDescription?: Maybe<string>, ManufacturingOrderNo?: Maybe<string>, QuantityDelivered?: Maybe<number>, QuantityRejected: number, ProductName?: Maybe<string>, ProductModel?: Maybe<string>, OtherInfo?: Maybe<string> }> };

export type GetDefectByIdQueryVariables = Exact<{
  id: Scalars["uuid"];
}>;


export type GetDefectByIdQuery = { __typename?: "query_root", ncr_Defects: Array<{ __typename?: "ncr_Defects", Id: any, DefectDescription?: Maybe<string>, BatchNumber?: Maybe<string>, QuantityRejected: number, QuantityDelivered?: Maybe<number>, OtherInfo?: Maybe<string>, ManufacturingOrderNo?: Maybe<string>, ProductName?: Maybe<string>, ProductModel?: Maybe<string>, Attachment_Defects: Array<{ __typename?: "ncr_Attachment_Defect", Attachment: { __typename?: "ncr_Attachments", Id: any, Path: string, Filename: string } }> }> };

export type GetDefectsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDefectsQuery = { __typename?: "query_root", ncr_Defects: Array<{ __typename?: "ncr_Defects", Id: any, ManufacturingOrderNo?: Maybe<string>, ProductName?: Maybe<string>, ProductModel?: Maybe<string> }> };

export type InsertDefectMutationVariables = Exact<{
  batchNo?: Maybe<Scalars["String"]>;
  manufacturingNo?: Maybe<Scalars["String"]>;
  defectDescription?: Maybe<Scalars["String"]>;
  model?: Maybe<Scalars["String"]>;
  otherInfo?: Maybe<Scalars["String"]>;
  productName?: Maybe<Scalars["String"]>;
  qtyDelivered?: Scalars["Int"];
  qtyRejected?: Scalars["Int"];
}>;


export type InsertDefectMutation = { __typename?: "mutation_root", insert_ncr_Defects_one?: Maybe<{ __typename?: "ncr_Defects", Id: any }> };

export type GetRootCausesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRootCausesQuery = { __typename?: "query_root", ncr_RootCauses: Array<{ __typename?: "ncr_RootCauses", Id: any, Description: string }> };

export type GetStatusesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatusesQuery = { __typename?: "query_root", ncr_Statuses: Array<{ __typename?: "ncr_Statuses", Status: string }> };


export const UpdateDefectByIdDocument = gql`
    mutation UpdateDefectById($id: uuid!, $batchNo: String = "", $manufacturingNo: String = "", $defectDescription: String = "", $model: String = "", $otherInfo: String = "", $productName: String = "", $qtyDelivered: Int! = 1, $qtyRejected: Int! = 1) {
  update_ncr_Defects_by_pk(
    pk_columns: {Id: $id}
    _set: {BatchNumber: $batchNo, DefectDescription: $defectDescription, ManufacturingOrderNo: $manufacturingNo, OtherInfo: $otherInfo, ProductName: $productName, ProductModel: $model, QuantityDelivered: $qtyDelivered, QuantityRejected: $qtyRejected}
  ) {
    Id
    BatchNumber
    DefectDescription
    ManufacturingOrderNo
    QuantityDelivered
    QuantityRejected
    ProductName
    ProductModel
    OtherInfo
  }
}
    `
export type UpdateDefectByIdMutationFn = Apollo.MutationFunction<UpdateDefectByIdMutation, UpdateDefectByIdMutationVariables>;

/**
 * __useUpdateDefectByIdMutation__
 *
 * To run a mutation, you first call `useUpdateDefectByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefectByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefectByIdMutation, { data, loading, error }] = useUpdateDefectByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      batchNo: // value for 'batchNo'
 *      manufacturingNo: // value for 'manufacturingNo'
 *      defectDescription: // value for 'defectDescription'
 *      model: // value for 'model'
 *      otherInfo: // value for 'otherInfo'
 *      productName: // value for 'productName'
 *      qtyDelivered: // value for 'qtyDelivered'
 *      qtyRejected: // value for 'qtyRejected'
 *   },
 * });
 */
export function useUpdateDefectByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDefectByIdMutation, UpdateDefectByIdMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<UpdateDefectByIdMutation, UpdateDefectByIdMutationVariables>(UpdateDefectByIdDocument, options)
}
export type UpdateDefectByIdMutationHookResult = ReturnType<typeof useUpdateDefectByIdMutation>;
export type UpdateDefectByIdMutationResult = Apollo.MutationResult<UpdateDefectByIdMutation>;
export type UpdateDefectByIdMutationOptions = Apollo.BaseMutationOptions<UpdateDefectByIdMutation, UpdateDefectByIdMutationVariables>;
export const GetDefectByIdDocument = gql`
    query GetDefectById($id: uuid!) {
  ncr_Defects(where: {Id: {_eq: $id}}) {
    Id
    DefectDescription
    BatchNumber
    QuantityRejected
    QuantityDelivered
    OtherInfo
    ManufacturingOrderNo
    ProductName
    ProductModel
    Attachment_Defects {
      Attachment {
        Id
        Path
        Filename
      }
    }
  }
}
    `

/**
 * __useGetDefectByIdQuery__
 *
 * To run a query within a React component, call `useGetDefectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDefectByIdQuery(baseOptions: Apollo.QueryHookOptions<GetDefectByIdQuery, GetDefectByIdQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetDefectByIdQuery, GetDefectByIdQueryVariables>(GetDefectByIdDocument, options)
}
export function useGetDefectByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefectByIdQuery, GetDefectByIdQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetDefectByIdQuery, GetDefectByIdQueryVariables>(GetDefectByIdDocument, options)
}
export type GetDefectByIdQueryHookResult = ReturnType<typeof useGetDefectByIdQuery>;
export type GetDefectByIdLazyQueryHookResult = ReturnType<typeof useGetDefectByIdLazyQuery>;
export type GetDefectByIdQueryResult = Apollo.QueryResult<GetDefectByIdQuery, GetDefectByIdQueryVariables>;
export const GetDefectsDocument = gql`
    query GetDefects {
  ncr_Defects {
    Id
    ManufacturingOrderNo
    ProductName
    ProductModel
  }
}
    `

/**
 * __useGetDefectsQuery__
 *
 * To run a query within a React component, call `useGetDefectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDefectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDefectsQuery(baseOptions?: Apollo.QueryHookOptions<GetDefectsQuery, GetDefectsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetDefectsQuery, GetDefectsQueryVariables>(GetDefectsDocument, options)
}
export function useGetDefectsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDefectsQuery, GetDefectsQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetDefectsQuery, GetDefectsQueryVariables>(GetDefectsDocument, options)
}
export type GetDefectsQueryHookResult = ReturnType<typeof useGetDefectsQuery>;
export type GetDefectsLazyQueryHookResult = ReturnType<typeof useGetDefectsLazyQuery>;
export type GetDefectsQueryResult = Apollo.QueryResult<GetDefectsQuery, GetDefectsQueryVariables>;
export const InsertDefectDocument = gql`
    mutation InsertDefect($batchNo: String = "", $manufacturingNo: String = "", $defectDescription: String = "", $model: String = "", $otherInfo: String = "", $productName: String = "", $qtyDelivered: Int! = 1, $qtyRejected: Int! = 1) {
  insert_ncr_Defects_one(
    object: {BatchNumber: $batchNo, ManufacturingOrderNo: $manufacturingNo, DefectDescription: $defectDescription, OtherInfo: $otherInfo, ProductModel: $model, ProductName: $productName, QuantityDelivered: $qtyDelivered, QuantityRejected: $qtyRejected}
  ) {
    Id
  }
}
    `
export type InsertDefectMutationFn = Apollo.MutationFunction<InsertDefectMutation, InsertDefectMutationVariables>;

/**
 * __useInsertDefectMutation__
 *
 * To run a mutation, you first call `useInsertDefectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDefectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDefectMutation, { data, loading, error }] = useInsertDefectMutation({
 *   variables: {
 *      batchNo: // value for 'batchNo'
 *      manufacturingNo: // value for 'manufacturingNo'
 *      defectDescription: // value for 'defectDescription'
 *      model: // value for 'model'
 *      otherInfo: // value for 'otherInfo'
 *      productName: // value for 'productName'
 *      qtyDelivered: // value for 'qtyDelivered'
 *      qtyRejected: // value for 'qtyRejected'
 *   },
 * });
 */
export function useInsertDefectMutation(baseOptions?: Apollo.MutationHookOptions<InsertDefectMutation, InsertDefectMutationVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useMutation<InsertDefectMutation, InsertDefectMutationVariables>(InsertDefectDocument, options)
}
export type InsertDefectMutationHookResult = ReturnType<typeof useInsertDefectMutation>;
export type InsertDefectMutationResult = Apollo.MutationResult<InsertDefectMutation>;
export type InsertDefectMutationOptions = Apollo.BaseMutationOptions<InsertDefectMutation, InsertDefectMutationVariables>;
export const GetRootCausesDocument = gql`
    query getRootCauses {
  ncr_RootCauses {
    Id
    Description
  }
}
    `

/**
 * __useGetRootCausesQuery__
 *
 * To run a query within a React component, call `useGetRootCausesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRootCausesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRootCausesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRootCausesQuery(baseOptions?: Apollo.QueryHookOptions<GetRootCausesQuery, GetRootCausesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetRootCausesQuery, GetRootCausesQueryVariables>(GetRootCausesDocument, options)
}
export function useGetRootCausesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRootCausesQuery, GetRootCausesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetRootCausesQuery, GetRootCausesQueryVariables>(GetRootCausesDocument, options)
}
export type GetRootCausesQueryHookResult = ReturnType<typeof useGetRootCausesQuery>;
export type GetRootCausesLazyQueryHookResult = ReturnType<typeof useGetRootCausesLazyQuery>;
export type GetRootCausesQueryResult = Apollo.QueryResult<GetRootCausesQuery, GetRootCausesQueryVariables>;
export const GetStatusesDocument = gql`
    query GetStatuses {
  ncr_Statuses {
    Status
  }
}
    `

/**
 * __useGetStatusesQuery__
 *
 * To run a query within a React component, call `useGetStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusesQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusesQuery, GetStatusesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useQuery<GetStatusesQuery, GetStatusesQueryVariables>(GetStatusesDocument, options)
}
export function useGetStatusesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusesQuery, GetStatusesQueryVariables>) {
    const options = {...defaultOptions, ...baseOptions}
    return Apollo.useLazyQuery<GetStatusesQuery, GetStatusesQueryVariables>(GetStatusesDocument, options)
}
export type GetStatusesQueryHookResult = ReturnType<typeof useGetStatusesQuery>;
export type GetStatusesLazyQueryHookResult = ReturnType<typeof useGetStatusesLazyQuery>;
export type GetStatusesQueryResult = Apollo.QueryResult<GetStatusesQuery, GetStatusesQueryVariables>;
