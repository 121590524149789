const configuration = {
    client_id: "ncr_front",
    redirect_uri: "http://localhost:3000/authentication/callback",
    // redirect_uri: 'http://localhost:3000/',
    response_type: "token id_token",
    post_logout_redirect_uri: "http://localhost:3000/signout",
    scope: "openid profile ncr_api",
    authority: "https://localhost:5001",
    silent_redirect_uri: "http://localhost:3000/authentication/silent_callback",
    automaticSilentRenew: true,
    loadUserInfo: true,
}

export default configuration
