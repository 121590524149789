import { useState, ChangeEvent } from "react"

interface FormHookResult<T> {
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
  handleSubmit: () => void;
  validationErrors: { [key: string]: string };
  isSubmitted: boolean;
  formData: T;
  setFormData: (newData: T) => void;
}

interface ErrorMessages {
  [key: string]: string;
}

export default function useForm<T>(
    form: T,
    validate: () => ErrorMessages,
    callback: (formData: unknown) => unknown,
): FormHookResult<T> {
    const [formData, setFormData] = useState(form)
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [validationErrors, setValidationErrors] = useState<ErrorMessages>({})

    const handleSubmit = (): void => {
        console.log("submit")
        if (!submitted) setSubmitted(true)
        const errors = validate()
        setValidationErrors(errors)

        if (Object.keys(errors).length === 0) callback(formData)
    }

    const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    return {
        formData,
        setFormData,
        handleChange,
        handleSubmit,
        validationErrors,
        isSubmitted: submitted,
    }
}
