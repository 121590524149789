import { useState, ChangeEvent } from "react"
import { InsertDefectMutationVariables } from "apollo/generated/graphql"
import Attachment from "types/Attachment"
import useForm from "hooks/form"
import { useApolloClient } from "@apollo/client"
import insertDefect from "apollo/queries/defects/insertDefect"

interface InsertDefectFormHook {
  formData: InsertDefectMutationVariables & { attachments: Attachment[] };
  validationErrors: { [key: string]: string };
  backendErrors: string[];
  isSubmitted: boolean;
  handleSubmit: () => void;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleAttachmentsChange: (files: Attachment[]) => void;
}

export function useInsertDefectForm(
    initialValues: InsertDefectMutationVariables & {
    attachments: Attachment[];
  },
    qtyRejectedRequired = true,
): InsertDefectFormHook {
    const client = useApolloClient()
    const [backendErrors, setBackendErrors] = useState<string[]>([])

    const validateForm = () => {
        const errors: { [key: string]: string } = {}

        if (formData.productName === "") errors.productName = "Product name is required"
        if (Number(formData.qtyDelivered) <= 0)
            errors.qtyDelivered = "Quantity delivered should be bigger than 0"

        // HINT: quality rejected is not required on internal ncr as it is always equal to delivered
        if (qtyRejectedRequired) {
            if (Number(formData.qtyRejected) <= 0)
                errors.qtyRejected = "Quantity rejected should be bigger than 0"
            if (Number(formData.qtyRejected) > Number(formData.qtyDelivered)) {
                errors.qtyDelivered =
          "Quantity delivered should be bigger than or equal to quantity rejected"
                errors.qtyRejected =
          "Quantity rejected should be smaller than or equal to quantity delivered"
            }
        }
        console.warn(errors)
        return errors
    }

    const callBackend = async (): Promise<void> => {
        try {
            console.log("mutation start")
            const result = await client.mutate({ mutation: insertDefect, variables: formData })
            if (result.errors) {
                const messages = result.errors.map((error) => error.message)
                console.error("errors after mutation: ", messages)
                setBackendErrors(messages)
            }
        } catch (err: any) {
            console.log("mutation error in catch", err)
            setBackendErrors([err?.message])
        }
    }

    const { validationErrors, handleChange, handleSubmit, isSubmitted, formData, setFormData } =
    useForm(initialValues, validateForm, callBackend)

    const handleAttachmentsChange = (files: Attachment[]) => {
        setFormData({ ...formData, attachments: files })
    }

    return {
        formData,
        validationErrors,
        backendErrors,
        isSubmitted,
        handleChange,
        handleSubmit,
        handleAttachmentsChange,
    }
}
