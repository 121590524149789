export enum Links {
  Home = "/",
  Login = "/login",
  Register = "/register",
  ResetPassword = "/reset-password",
  NcrCreate = "/ncr/create",
  NcrInternalCreate = "/ncr/internal/create",
  NcrEdit = "/ncr/edit",
  NcrView = "/ncr/view",
  NcrList = "/ncr",
  NcrSearch = "/ncr/search",
  ProductEdit = "/products/edit",
  ProductView = "/products/view",
  ProductList = "/products",
  ProductCreate = "/products/create",
}
