// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { BrowserRouter, Routes, Route } from "react-router-dom"
import { MenuLayout } from "components/layouts/menuLayout"
import PlainLayout from "components/layouts/plainLayout"
import { Home } from "pages/home"
import { NcrListPage } from "pages/ncr/list"
import UpdateNcrForm from "pages/ncr/update"
import NcrCreate from "pages/ncr/create"
import NcrInternalCreate from "pages/ncr/internal/create"

/**
 * Application root. `RouterConfig` renders a content of the page which changes depending on URL.
 */
function App(): JSX.Element {
    return (
        <div className='App'>
            <BrowserRouter>
                <Routes>
                    <Route
                        path='/'
                        element={
                            <MenuLayout>
                                <Home />
                            </MenuLayout>
                        }
                    />
                    <Route
                        path='ncr'
                        element={
                            <MenuLayout>
                                <NcrListPage />
                            </MenuLayout>
                        }
                    />
                    <Route
                        path='ncr/create'
                        element={
                            <PlainLayout>
                                <NcrCreate />
                            </PlainLayout>
                        }
                    />
                    <Route
                        path='ncr/edit/:id'
                        element={
                            <PlainLayout>
                                <UpdateNcrForm />
                            </PlainLayout>
                        }
                    />
                    <Route
                        path='ncr/internal/create'
                        element={
                            <PlainLayout>
                                <NcrInternalCreate />
                            </PlainLayout>
                        }
                    />
                </Routes>
            </BrowserRouter>
        </div>
    )
}

export default App
