import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { Links } from "router/links"
interface Props {
  to: Links | string;
  color?: "blue" | "white" | "";
}

export default function NcrLink({
    to,
    children,
    color = "",
}: React.PropsWithChildren<Props>): JSX.Element {
    const [colorClass, setColorClass] = useState("")

    useEffect(() => {
        let className = ""
        switch (color) {
        case "white":
            className = "text-white hover:text-blue-500"
            break
        case "blue":
            className = "text-blue-500 hover:text-blue-300"
            break
        default:
            break
        }

        setColorClass(className)
    }, [])

    return (
        <Link className='h-12' to={to.toString()}>
            <span className={`${colorClass}`}>{children}</span>
        </Link>
    )
}
