import { useTranslation } from "react-i18next"
import { useReactOidc } from "@axa-fr/react-oidc-context"
import { Form, Button, Grid, Message } from "semantic-ui-react"

import { ContentWrapper } from "components/containers/contentWrapper/contentWrapper"
import { useInsertDefectForm } from "components/containers/form/ncr/insert-defect-hook"
import NcrFileUpload from "components/controls/ncrFileUpload"

const InternalNcrForm = (): JSX.Element => {
    const { formData, validationErrors, handleChange, handleSubmit, handleAttachmentsChange } =
    useInsertDefectForm(
        {
            manufacturingNo: "",
            productName: "",
            model: "",
            batchNo: "",
            defectDescription: "",
            otherInfo: "",
            qtyDelivered: 0,
            qtyRejected: 0,
            attachments: [],
        },
        false,
    )
    const { t } = useTranslation(["buttons", "common", "ncr"])
    const { oidcUser } = useReactOidc()

    return (
        <ContentWrapper header='Reklamacja'>
            <Grid>
                <Grid.Column width={4}>
                    <Form>
                        <Form.Input
                            label='Klient'
                            disabled
                            value='test'
                            input={<span>{oidcUser?.profile.company || "Not provided"}</span>}
                        />
                        <img alt='Client Logo' width={128} height={128} />
                    </Form>
                </Grid.Column>
                <Grid.Column width={12}>
                    <h5>Prosimy o wypełnienie informacji na temat reklamacji</h5>
                    <Form error onSubmit={handleSubmit} widths={"equal"} noValidate>
                        <Form.Group>
                            <Form.Input
                                name='manufacturingNo'
                                label={t("Nr zlecenia produkcyjnego")}
                                onChange={handleChange}
                                value={formData.manufacturingNo}
                            />
                            {/* TODO: detail no: <Form.Input
                name='detailNo'
                label={t("Nr detalu")}
                onChange={handleChange}
                value={formData.detailNo}
              /> */}
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                name='qtyRejected'
                                type='number'
                                label={t("Ilość wadliwa")}
                                onChange={handleChange}
                                value={formData.qtyRejected}
                                required
                            />

                            {/* TODO: defectType: <Form.Input name='defectType' label={t("Rodzaj wady")} onChange={handleChange} /> */}
                        </Form.Group>
                        <Form.Group>
                            <Form.Input
                                name='manufacturingNo'
                                label={t("Stanowisko wykrycia wady")}
                                onChange={handleChange}
                            />
                            <Form.Input
                                name='detailNo'
                                label={t("Wymiar niezgodny/cecha")}
                                onChange={handleChange}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Input name='isRepairable' label={t("Możliwość naprawy")} />
                        </Form.Group>
                        <Form.TextArea name='defectDescription' label='Opis wady (300 znaków)'></Form.TextArea>
                        <NcrFileUpload files={formData.attachments} onChange={handleAttachmentsChange} />
                        {validationErrors &&
              Object.keys(validationErrors).map((key, index) => (
                  <Message key={index} error>
                      {validationErrors[key]}
                  </Message>
              ))}
                        <Button type='submit' className='ui success'>
                            {t("save")}
                        </Button>
                    </Form>
                </Grid.Column>
            </Grid>
        </ContentWrapper>
    )
}

export default InternalNcrForm
