import { useTranslation } from "react-i18next"
import { useReactOidc } from "@axa-fr/react-oidc-context"
import { Form, Button, Message } from "semantic-ui-react"

import { ContentWrapper } from "components/containers/contentWrapper/contentWrapper"
import NcrFileUpload from "components/controls/ncrFileUpload"
import { useInsertDefectForm } from "components/containers/form/ncr/insert-defect-hook"

const ForeignNcrForm = (): JSX.Element => {
    const { t } = useTranslation(["buttons", "common", "ncr"])
    const { oidcUser } = useReactOidc()

    const {
        formData,
        validationErrors,
        isSubmitted,
        handleChange,
        handleSubmit,
        handleAttachmentsChange,
    } = useInsertDefectForm({
        productName: "",
        model: "",
        batchNo: "",
        defectDescription: "",
        otherInfo: "",
        qtyDelivered: 1,
        qtyRejected: 1,
        attachments: [],
    })

    return (
        <ContentWrapper header='Reklamacja'>
            <div className='flex flex-row'>
                <Form>
                    <Form.Input
                        label='Klient'
                        disabled
                        value='test'
                        input={<span>{oidcUser?.profile.company || "Not provided"}</span>}
                    />
                    <img alt='Client Logo' width={128} height={128} />
                </Form>
                <div>
                    <div className='mb-8'>Prosimy o wypełnienie informacji na temat reklamacji</div>
                    <Form
                        error={isSubmitted && Object.keys(validationErrors).length > 0}
                        onSubmit={handleSubmit}
                        widths={"equal"}
                        className='grid sm:grid-cols-1 md:grid-cols-2 w-full'
                        noValidate>
                        <Form.Input
                            name='productName'
                            label={t("Nazwa wyrobu")}
                            onChange={handleChange}
                            required
                            error={validationErrors.productName}
                            className='w-full sm:w-1/2'
                        />
                        <Form.Input
                            className='w-full sm:w-1/2'
                            name='modelName'
                            label={t("Model wyrobu")}
                            onChange={handleChange}
                        />
                        <Form.Input
                            className='w-full sm:w-1/2'
                            name='batchNo'
                            label={t("Nr serii produkcyjnej")}
                            onChange={handleChange}
                        />
                        <Form.Input
                            name='qtyDelivered'
                            label={t("Ilość w dostawie")}
                            onChange={handleChange}
                            error={validationErrors.qtyDelivered}
                            required
                            value={formData.qtyDelivered}
                            className='w-full sm:w-1/2'
                        />
                        <Form.Input
                            type={"number"}
                            name='qtyRejected'
                            error={validationErrors.qtyRejected}
                            label={t("Ilość wadliwa")}
                            onChange={handleChange}
                            required
                            className='w-full sm:w-1/2'
                            value={formData.qtyRejected}
                        />
                        <Form.Input
                            name='defectType'
                            label={t("Rodzaj wady")}
                            onChange={handleChange}
                            className='w-full sm:w-1/2'
                        />
                        <Form.TextArea
                            className='w-full'
                            name='defectDescription'
                            label='Opis wady (300 znaków)'></Form.TextArea>
                        <Form.TextArea
                            className='w-full'
                            label='Inne informacje / Oczekiwania (300 znaków)'></Form.TextArea>
                        <div className='w-full'>
                            <NcrFileUpload files={formData.attachments} onChange={handleAttachmentsChange} />
                        </div>
                        {validationErrors &&
              Object.keys(validationErrors).map((key, index) => (
                  <Message key={index} error>
                      {validationErrors[key]}
                  </Message>
              ))}
                        <Button type='submit' className='ui success'>
                            {t("save")}
                        </Button>
                    </Form>
                </div>
            </div>
        </ContentWrapper>
    )
}

export default ForeignNcrForm
