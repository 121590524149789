import { gql } from "@apollo/client"

export default gql`
  mutation InsertDefect(
    $batchNo: String = ""
    $manufacturingNo: String = ""
    $defectDescription: String = ""
    $model: String = ""
    $otherInfo: String = ""
    $productName: String = ""
    $qtyDelivered: Int! = 1
    $qtyRejected: Int! = 1
  ) {
    insert_ncr_Defects_one(
      object: {
        BatchNumber: $batchNo
        ManufacturingOrderNo: $manufacturingNo
        DefectDescription: $defectDescription
        OtherInfo: $otherInfo
        ProductModel: $model
        ProductName: $productName
        QuantityDelivered: $qtyDelivered
        QuantityRejected: $qtyRejected
      }
    ) {
      Id
    }
  }
`
