import { Link } from "react-router-dom"
import { Button, Table } from "semantic-ui-react"
//import { useReactOidc, withOidcSecure } from "@axa-fr/react-oidc-context";
import { ContentWrapper } from "../../components/containers/contentWrapper/contentWrapper"
import { Links } from "../../router/links"
import { useGetDefectsQuery } from "apollo/generated/graphql"

function NcrListPageComponent(): JSX.Element {
    const { data, loading } = useGetDefectsQuery()
    const TableContent = () => (
        <>
            {data &&
        data.ncr_Defects.map((row, index) => (
            <Table.Row className='hover:bg-gray-100' key={index}>
                <Table.Cell>{row.Id}</Table.Cell>
                <Table.Cell>{row.ProductName}</Table.Cell>
                <Table.Cell>{row.ProductModel}</Table.Cell>
                <Table.Cell>
                    <Link to={`${Links.NcrEdit}/${row.Id}`}>
                        <Button>View</Button>
                    </Link>
                </Table.Cell>
            </Table.Row>
        ))}
        </>
    )

    return (
        <ContentWrapper header='Non-Conformity Report'>
            {loading && <>Loading...</>}
            <div className='border-b border-gray-200 shadow'>
                <Table celled>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Model</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        <TableContent />
                    </Table.Body>
                </Table>
            </div>
        </ContentWrapper>
    )
}

export const NcrListPage = NcrListPageComponent
//export const NcrListPage = withOidcSecure(NcrListPageComponent);
