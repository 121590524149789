import useBreadcrumbs from "use-react-router-breadcrumbs"
import NcrLink from "./link"

const routes = [
    {
        breadcrumb: "Home",
        path: "/",
    },
    {
        breadcrumb: "Non-Conformity Reports",
        path: "/ncr",
    },
    {
        breadcrumb: "Create",
        path: "/internal/create",
    },
    {
        breadcrumb: "Create",
        path: "/ncr/create",
    },
    {
        breadcrumb: "Update",
        path: "/ncr/edit/:id",
    },
    {
        breadcrumb: "Update",
        path: "/ncr/edit/:id",
    },

    {
        breadcrumb: "Products",
        path: "/products",
    },
    {
        breadcrumb: "Create",
        path: "/create",
    },
    {
        breadcrumb: "Product",
        path: "/edit/:id",
    },
]

const excludePaths = ["/ncr/edit"]

const NcrBreadcrumbs = (): JSX.Element => {
    const breadcrumbs = useBreadcrumbs(routes, { excludePaths })

    return (
        <div className='flex flex-row'>
            {breadcrumbs.map(({ match, breadcrumb }, i) => (
                <div key={i}>
                    <NcrLink key={i} color='blue' to={match.pathname}>
                        {breadcrumb}
                    </NcrLink>
                    {
                        // avoid last slash
                        i < breadcrumbs.length - 1 && <span className='mx-1'>/</span>
                    }
                </div>
            ))}
        </div>
    )
}

export default NcrBreadcrumbs
