import NcrBreadcrumb from "../../controls/ncrBreadcrumb"
import "./contentWrapper.scoped.scss"

interface IProps {
  header: string;
}

/**
 * Wrapper for all pages that produces breadcrumb and white background with nice padding.
 * @param header - Page header
 * @param children - Children element
 */
export function ContentWrapper({ header, children }: React.PropsWithChildren<IProps>): JSX.Element {
    return (
        <div>
            <div className='p-8'>
                <NcrBreadcrumb />
                <h2>{header || "test"}</h2>
                {children}
            </div>
        </div>
    )
}
