import NcrLink from "components/controls/link"
import { Links } from "../../../router/links"

const SidebarLink = ({ to, text }: { to: Links; text: string }) => (
    <li className='my-px hover:bg-black'>
        <div className='px-10'>
            <NcrLink to={to} color='white'>
                {text}
            </NcrLink>
        </div>
    </li>
)

export function MenuSider(): JSX.Element {
    return (
        <div className='sidebar w-64 md:shadow transform -translate-x-full md:translate-x-0 transition-transform duration-150 ease-in bg-gray-900 text-white'>
            <ul className='flex flex-col w-full text-lg'>
                <SidebarLink to={Links.Home} text='Home' />
                <SidebarLink to={Links.NcrList} text='List' />
                <SidebarLink to={Links.NcrCreate} text='Create (external)' />
                <SidebarLink to={Links.NcrInternalCreate} text='Create (internal)' />
                <SidebarLink to={Links.NcrSearch} text='Search' />
                <SidebarLink to={Links.ProductList} text='Product list' />
                <SidebarLink to={Links.ProductCreate} text='Create product' />
            </ul>
        </div>
    )
}
