import React from "react"

import configuration from "../oidc-config"
import { AuthenticationProvider, InMemoryWebStorage, oidcLog } from "@axa-fr/react-oidc-context"

// custom oidc state components
import AuthenticationRedirect from "../pages/auth/authenticationRedirect"
import onConnect from "../pages/auth/onConnect"
import NotAuthenticated from "../pages/auth/notAuthenticated"
import NotAuthorized from "../pages/auth/notAuthorized"
import SessionLost from "../pages/auth/sessionLost"

const NcrAuthProvider = ({ children }: React.PropsWithChildren<unknown>): JSX.Element => (
    <AuthenticationProvider
        configuration={configuration}
        loggerLevel={oidcLog.DEBUG}
        isEnabled={true}
        UserStore={InMemoryWebStorage}
        authenticating={AuthenticationRedirect}
        callbackComponentOverride={onConnect}
        notAuthenticated={NotAuthenticated}
        notAuthorized={NotAuthorized}
        sessionLostComponent={SessionLost}>
        {children}
    </AuthenticationProvider>
)

export default NcrAuthProvider
