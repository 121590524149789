import { MenuSider } from "./sider"
import "./menuLayout.scoped.scss"

export function MenuLayout(props: React.PropsWithChildren<unknown>): JSX.Element {
    return (
        <div className='flex h-screen'>
            <MenuSider />
            <div className='main flex flex-col flex-grow -ml-64 md:ml-0 transition-all duration-150 ease-in'>
                {props.children}
            </div>
        </div>
    )
}
