import { Button, Header, Icon, Message, Segment, List } from "semantic-ui-react"
import React, { ChangeEvent, useState } from "react"
import { SemanticICONS } from "semantic-ui-react/dist/commonjs/generic"
import Attachment from "../../types/Attachment"
import "./ncrFileUpload.scss"
interface FileUploadProps {
  onChange: (files: Attachment[]) => void;
  files: Attachment[];
  icon?: SemanticICONS;
  maxFileSizeMb?: number;
  maxFiles?: number;
  noFilesText?: string;
}

const NcrFileUpload = ({
    onChange,
    files,
    icon = "file pdf outline",
    maxFiles = 5,
    maxFileSizeMb = 5,
    noFilesText = "Nie dodano żadnych załączników",
}: FileUploadProps): JSX.Element => {
    const [showWarnMessage, setShowWarnMessage] = useState(false)

    function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
        if (
            files.length + 1 > maxFiles ||
      !e?.target?.files?.[0]?.size ||
      e.target.files?.[0].size > maxFileSizeMb * 1024 * 1024
        ) {
            setShowWarnMessage(true)
            return
        }

        if (e.target && e.target.files)
            onChange([
                ...files,
        {
            url: "",
            name: e.target.files[0]?.name,
            file: e.target.files[0],
        } as Attachment,
            ])
    }

    function handleRemoveFile(i: number) {
        setShowWarnMessage(false)
        onChange(files.filter((_f, j) => i !== j))
    }

    function toMegabyte(size: number) {
        const mbs = (size / 1024 / 1024).toPrecision(2)

        return `${mbs} MB`
    }

    const FileListItem = ({ att, index }: { att: Attachment; index: number }) => (
        <List.Item key={index}>
            <List.Content floated={"right"}>
                <span className='pointer' onClick={() => handleRemoveFile(index)}>
                    <Icon name={"close"} />
                </span>
            </List.Content>
            <List.Content>
                <List.Header>
                    <a
                        href={att.path || (att && att.file && URL.createObjectURL(att.file))}
                        target='_blank'
                        rel='noopener noreferrer'>
                        {att.name || att.file?.name}
                    </a>
                </List.Header>
                {att?.file?.size && (
                    <List.Description>Rozmiar: {toMegabyte(att.file.size)}</List.Description>
                )}
            </List.Content>
        </List.Item>
    )

    return (
        <>
            <Segment placeholder>
                <Header icon>
                    <Icon name={icon} />
                    {!files || (files.length === 0 && noFilesText)}
                </Header>
                <List divided verticalAlign='middle'>
                    {files && files.map((x, i) => <FileListItem att={x} key={i} index={i} />)}
                </List>
                <input
                    type='file'
                    id='file'
                    maxLength={5}
                    className='error'
                    hidden
                    onChange={handleFileUpload}
                />
                <Button
                    as='label'
                    type='button'
                    primary={true}
                    htmlFor='file'
                    onClick={() => setShowWarnMessage(false)}>
          Dodaj załącznik
                </Button>
            </Segment>
            <Message
                error
                style={{ display: showWarnMessage ? "block" : "none" }}
                header='There were some errors while adding a file!'
                list={[
                    `Maximum number of files is ${maxFiles}.`,
                    `Maximum size of each file is ${maxFileSizeMb} MB`,
                ]}
            />
        </>
    )
}

export default NcrFileUpload
