import { useEffect, ChangeEvent, useState, useCallback } from "react"
import {
    GetDefectByIdDocument,
    GetDefectByIdQuery,
    UpdateDefectByIdMutation,
    UpdateDefectByIdMutationVariables,
} from "apollo/generated/graphql"
//import Attachment from "types/Attachment"
import { useApolloClient } from "@apollo/client"
import UPDATE_DEFECT_BY_ID_MUTATION from "apollo/mutations/updateDefectById"
import { GraphQLError } from "graphql"
import Attachment from "types/Attachment"

interface UpdateDefectFormHook {
  formData: UpdateDefectByIdMutationVariables & { attachments: Attachment[] };
  isSubmitted: boolean;
  backendErrors: GraphQLError[];
  validationErrors: { [key: string]: string } | undefined;
  handleSubmit: () => void;
  handleChange: (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => void;
  //handleAttachmentsChange: (files: Attachment[]) => void
}

const useUpdateDefectForm = (id: string): UpdateDefectFormHook => {
    const [formData, setFormData] = useState<
    UpdateDefectByIdMutationVariables & { attachments: Attachment[] }
  >({
      id: "",
      manufacturingNo: "",
      productName: "",
      model: "",
      batchNo: "",
      defectDescription: "",
      otherInfo: "",
      qtyDelivered: 1,
      qtyRejected: 1,
      attachments: [],
  })
    const [backendErrors, setErrors] = useState<GraphQLError[]>([])
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>()
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const client = useApolloClient()

    const validateForm = () => {
        const fieldNames = Object.keys(formData) as Array<keyof UpdateDefectByIdMutationVariables>

        let messages = {}
        for (const fieldName of fieldNames) {
            const msg = validateField(fieldName)
            if (msg) messages = { ...messages, [fieldName]: msg }
        }

        return messages
    }

    const validateField = (
        fieldName: keyof UpdateDefectByIdMutationVariables,
    ): string | undefined => {
        switch (fieldName) {
        case "qtyRejected":
            if (Number(formData.qtyRejected) <= 0)
                return "qty rejected should be bigger than 0"
            break
        case "qtyDelivered":
            if (Number(formData.qtyDelivered) <= 0)
                return "qty delivered should be bigger than 0"
            if (Number(formData.qtyRejected) > Number(formData.qtyDelivered))
                return "qty delivered should be bigger than or equal to quantity rejected"
            break
        default:
            return
        }
    }

    const handleChange = useCallback(
        (e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
            setFormData({ ...formData, [e.target.name]: e.target.value })
        },
        [formData],
    )

    const handleSubmit = async () => {
        setIsSubmitted(true)
        const errors = validateForm()

        if (errors && Object.keys(errors).length > 0) {
            setValidationErrors(errors)
            console.log(errors)
            return
        }
        const result = await client.mutate<UpdateDefectByIdMutation>({
            mutation: UPDATE_DEFECT_BY_ID_MUTATION,
            variables: formData,
        })
        setErrors(result.errors as never)
    }

    useEffect(() => {
        if (!id || id.length === 0) return
        const fetchDefect = async () => {
            const result = await client.query<GetDefectByIdQuery>({
                query: GetDefectByIdDocument,
                variables: { id },
            })

            setErrors(result.errors as never)

            const newData: UpdateDefectByIdMutationVariables = {
                id: result.data.ncr_Defects?.[0].Id,
                manufacturingNo: result.data.ncr_Defects?.[0].ManufacturingOrderNo || "",
                productName: result.data.ncr_Defects?.[0].ProductName || "",
                model: result.data.ncr_Defects?.[0].ProductModel || "",
                batchNo: result.data.ncr_Defects?.[0].BatchNumber || "",
                qtyDelivered: result.data.ncr_Defects?.[0]?.QuantityDelivered || 0,
                qtyRejected: result.data.ncr_Defects?.[0]?.QuantityRejected || 0,
                defectDescription: result.data.ncr_Defects?.[0].DefectDescription || "",
                otherInfo: result.data.ncr_Defects?.[0].OtherInfo || "",
            }

            setFormData({...newData, attachments: []})
        }
        fetchDefect()
    }, [client, id])

    //const handleAttachmentsChange = (files: Attachment[]) => {
    //setFormData({ ...formData, attachments: files })
    //    setFormData({ ...formData })
    //}

    return {
        formData,
        isSubmitted,
        handleChange,
        handleSubmit,
        backendErrors,
        validationErrors,
    //handleAttachmentsChange,
    }
}

export default useUpdateDefectForm
